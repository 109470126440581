import React, {useState} from 'react'
import dropdownIcon from '../../assets/user_data_dropdown_icon.svg'
import './style/custom_date_select.css'
import useOutsideClick from "../../Constants/useOutsideClick";

export default function CustomDateSelect({val, itemsList, setData, setVal, placeholder, popup, errorMessage}) {
    let ref = React.useRef();

    const [activePopup, setActivePopup] = useState(false)
    useOutsideClick(ref, () => {
        if (activePopup) {
            setActivePopup(false)
        }
    });

    return (
        <div className={'custom_date_select_wrapper'}>
            <div onClick={() => setActivePopup(!activePopup)} className={'custom_date_select_wrapper_label'}>
                <span>{val || placeholder}</span>
                <img src={dropdownIcon} alt="dropdown icon"/>
            </div>
            {itemsList && activePopup ?
                <div ref={ref} className={'custom_date_select_wrapper_options'}>
                    {itemsList.map((item, index) => (
                        <div key={index}>
                            <span onClick={() => {
                                setData(prevState => ({
                                    ...prevState,
                                    birthday: {
                                        ...prevState.birthday,
                                        [setVal]: {
                                            ...prevState.birthday[setVal],
                                            value: item,
                                            errorPopup: false,
                                        }
                                    }
                                }))
                                setActivePopup(false)
                            }}>{item}</span>
                        </div>
                    ))}
                </div>
                : null}
            {popup ?
                <div className={'custom_date_select_wrapper_error'}>
                    <span>{errorMessage}</span>
                </div>
                : null}
        </div>
    )
}

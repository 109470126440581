import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import {Navigation} from '../Constants/Navigations'
import {MovaCat} from '../Constants/MovaCat'

class Routers extends React.Component {
    componentDidMount() {
        console.log(MovaCat)
    }

    render() {
        return (
            <div className={'wrapper_app'}>
                <Router>
                    <Header/>
                    {Navigation.headerNavigations.map(route => (
                        <Route path={route.pathname}
                               exact
                               component={route.main}
                               key={route.pathname}/>
                    ))}
                    <Footer/>
                </Router>
            </div>
        )
    }
}


export default Routers;

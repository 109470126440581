import React, {useState, useRef, useEffect} from 'react';
import {Link, Route} from "react-router-dom";
import Layout from "../adminComponents/Layout"
import API from "../adminAPI";

import "../adminComponents/style/categories.scss"

import EditIcon from "../assets/g-icon-edit.svg"
import DeleteIcon from "../assets/g-icon-delete.svg"
import AddIcon from "../assets/g-icon-add.svg"
import IconArrowDown from "../assets/g-icon-arrow_down.svg"
import CloseIcon from "../assets/g-modal-close.svg";
import AddIconBlack from "../assets/g-icon-add-black.svg";

const CategoriesPage = ({match}) => {

    const [requestList, setRequestList] = useState(false);
    const [categoriesData, setCategoriesData] = useState([]);

    const [addMode, setAddMode] = useState(true);
    const [editMode, setEditMode] = useState(false);

    const InitialItem = {
        id: null,
        name: "",
        path: "",
    };

    const [tags, setTags] = useState([{
        name: "",
        id: Math.random()
    }])

    const [subcategoryItem, setSubcategoryItem] = useState(InitialItem);
    const [categoryId, setCategoryId] = useState(null);

    const ref = useRef();

    const handleRequestListHeader = id => {
        setRequestList(false);
        setCategoryId(id);
    }

    const handleRequestList = () => {
        setRequestList(!requestList);
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setRequestList(false);
        }
    };

    useEffect(() => {
        API.get(`/admin/categories`)
            .then(res => {
                setCategoriesData(res.data.categories);
                setCategoryId(match.params.id);
            })

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
        // eslint-disable-next-line
    }, []);


    const HandleTitle = ({match, data}) => {
        const {
            params: {categoryId}
        } = match;

        const category = data.find(({id}) => id === +categoryId);

        if (category) {
            return (
                <span className="categories__title g-title">{category.name}</span>
            );
        }
        else {
            return (
                <h2 className="categories__title g-title">zxc</h2>
            );
        }
    };

    const UserPage = ({match, data}) => {
        const {
            params: {categoryId}
        } = match;

        const category = data.find(({id}) => id === +categoryId);

        if (category) {
            return (
                <ul className="categories-info-list">
                    {category.subcategories.map(subcategory => {
                        return (
                            <li className="categories-info-list__item-no-photo" key={subcategory.id}>
                                <p className="categories-info-list__text">{subcategory.name}</p>


                                <button className="g-button-edit" onClick={() => handleEdit(subcategory.id)}>
                                    <img className="g-button-edit__icon" src={EditIcon} alt="Edit"/>
                                </button>
                                <button className="g-button-delete" onClick={() => handleDelete(subcategory.id)}>
                                    <img className="g-button-delete__icon" src={DeleteIcon} alt="Delete"/>
                                </button>
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            return (
                <ul className="categories-info-list"/>
            )
        }

    };

    const handleInputChange = e => {
        const {name, value} = e.target;
        setSubcategoryItem({...subcategoryItem, [name]: value});
    };

    const handleEdit = id => {

        API.get(`/admin/subcategory?subcategory_id=${id}`)
            .then(res => {
                setSubcategoryItem({
                    id: res.data.id,
                    name: res.data.name,
                })
                setTags(res.data.tags)
            })

        setAddMode(false);
        setEditMode(true);
    }

    const handleDelete = id => {
        API.delete(`/admin/subcategory?subcategory_id=${id}`)
            .then(res => {
                API.get(`/admin/categories`)
                    .then(res => {
                        setSubcategoryItem(InitialItem);
                        setCategoriesData(res.data.categories);
                    })

                if (editMode) {
                    setEditMode(false);
                }
            })
    }

    const handleCancel = () => {
        setAddMode(true);
        setEditMode(false);
        setSubcategoryItem(InitialItem);
    }

    const submitAdd = e => {
        e.preventDefault();
        if (!categoryId || !subcategoryItem.name) return;
        let tagsArr = []
        tags.forEach(item => {
            tagsArr.push(item.name)
        })
        API.post(`/admin/subcategory`, {category_id: categoryId, name: subcategoryItem.name, tags: tagsArr})
            .then(res => {
                API.get(`/admin/categories`)
                    .then(res => {

                        setTags([{
                            name: "",
                            id: Math.random()
                        }])
                        setSubcategoryItem(InitialItem);
                        setCategoriesData(res.data.categories);
                    })
            })
    }

    const submitEdit = e => {
        e.preventDefault();
        let tagsArr = []
        tags.forEach(item => {
            tagsArr.push(item.name)
        })
        setTimeout(() => {
            API.put(`/admin/subcategory?subcategory_id=${subcategoryItem.id}&name=${subcategoryItem.name}&tags=${tagsArr}`)
                .then(() => {
                    API.get(`/admin/categories`)
                        .then(res => {
                            setTags([{
                                name: "",
                                id: Math.random()
                            }])
                            setSubcategoryItem(InitialItem);
                            setCategoriesData(res.data.categories);
                        })

                    setEditMode(false);
                    setAddMode(true);
                })
        })
    }

    const changeFilterInputs = (e, key, value) => {
        tags[key][value] = e.target.value;
        setTags([...tags])
    };

    return (
        <Layout>
            <section className="categories">
                <div className="container">
                    <div className="categories-info">

                        <div ref={ref} className={`g-select${requestList ? ` g-select__open` : ''}`}>
                            <div className="g-select-header" onClick={handleRequestList}>
                                <Route path="/admin/categories/:categoryId"
                                       render={props => <HandleTitle data={categoriesData} {...props} />}/>

                                <img className="g-select-header__arrow" src={IconArrowDown} alt="Категория"/>
                            </div>

                            {requestList ? <ul className="g-select-list">
                                {categoriesData.map((category) => {
                                    return (
                                        <li className="g-select-list__item" key={category.id}>
                                            <Link className="g-select-list__link"
                                                  to={`/admin/categories/${category.id}`} onClick={() => {
                                                handleRequestListHeader(category.id);
                                                handleCancel()
                                            }}>{category.name}</Link>
                                        </li>
                                    );
                                })}
                            </ul> : null}
                        </div>

                        <div className="categories-info__head">
                            <p className="categories-info__text">Название подкатегории</p>
                        </div>

                        <Route path="/admin/categories/:categoryId"
                               render={props => <UserPage data={categoriesData} {...props} />}/>
                    </div>

                    <div className="categories-add">
                        <div style={{display: "flex", alignItems: "center"}}>

                            <h3 className="categories-add__title">{editMode ? `Редактировать` : `Добавить`} подкатегорию</h3>
                            <button onClick={() => {
                                setTags([...tags, {
                                        name: "", id: Math.random()
                                    }]
                                )
                            }} className="g-add" style={{
                                height: "max-content",
                                marginLeft: "auto",
                                backgroundColor: "white",
                                border: '1px black solid',
                                color: "black",
                                borderRadius: "5px"
                            }}>
                                <img className="g-add__icon" src={AddIconBlack} alt="Додати"/>
                                Добавить параметр
                            </button>
                        </div>
                        <form className="categories-add__form"
                              onSubmit={addMode ? submitAdd : editMode ? submitEdit : null}>

                            <div className="categories-add__block">
                                <p className="categories-add__text">Название подкатегории</p>

                                <input className="g-input" type="text" placeholder="Название" name="name"
                                       value={subcategoryItem.name} onChange={handleInputChange}/>

                                {tags.length ?
                                    <>
                                        <table className="orders-table">
                                            <thead className="orders-table__head">
                                            <tr className="orders-table__tr">
                                                <th style={{
                                                    width: "40%",
                                                    textAlign: "start",
                                                    paddingLeft: "0 !important"
                                                }} className="orders-table__thv">Типы товаров
                                                </th>
                                                {/*<th style={{width: "20%", textAlign: "start"}} className="orders-table__th">Иконка</th>*/}
                                                {/*<th style={{width: "40%", textAlign: "start"}} className="orders-table__th">Добавленная стоимость, €</th>*/}
                                            </tr>
                                            </thead>
                                            <tbody className="orders-table__body">

                                            {tags.map((i, key) => {
                                                return (
                                                    <tr className="orders-table__tr" key={key}>
                                                        <td style={{paddingLeft: "0 !important", paddingBottom: "0 "}}
                                                            className="orders-table__tdv">
                                                            <input className={"g-input"} type="text" onChange={(e) => {
                                                                changeFilterInputs(e, key, "name")
                                                            }} placeholder={"Параметр"} value={i.name}/>
                                                        </td>
                                                        <div onClick={() => {
                                                            tags.forEach((element, index) => {
                                                                if (element.id === i.id) {
                                                                    tags.splice(index, 1);

                                                                }

                                                            });
                                                            setTags([...tags]);

                                                        }}
                                                             style={{
                                                                 backgroundColor: "rgba(51, 51, 58, 0.2)",
                                                                 display: "grid",
                                                                 marginTop: "14px",
                                                                 width: "fit-content"
                                                             }}>
                                                            <img src={CloseIcon}
                                                                 style={{padding: "12px", display: "grid"}} alt=""/>
                                                        </div>
                                                    </tr>
                                                )
                                            })
                                            }

                                            </tbody>


                                        </table>
                                    </>
                                    : null
                                }

                                <div className="categories-add_flex">
                                    {editMode ?
                                        <button className="g-add g-add_margin" type="button" onClick={handleCancel}>
                                            Отменить
                                        </button> : null}

                                    <button className="g-add" type="submit">
                                        <img className="g-add__icon" src={AddIcon} alt="Додати"/>
                                        {editMode ? "Сохранить" : "Добавить"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default CategoriesPage

import React, { useState } from 'react';
import './styles/b2bClientsRequest.css';
import Input from './Input';
import CustomPopup from './CustomPopup';
import API from '../API';

export default function B2BClientsRequest() {
    const [contactRequestPopup, changeContactRequestPopup] = useState(false);
    const [formInputsEmail, changeFormInputsEmail] = useState({
        value: '',
        error: false
    });

    const resetState = (stateChanger, bool) => {
        stateChanger({
            value: bool ? false : '',
            error: false
        });
    };

    const validateEmail = email => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (formInputsEmail.value && validateEmail(formInputsEmail.value)) {
            resetState(changeFormInputsEmail);
            API.post('b2b_clients', { email: formInputsEmail.value })
                .then(() => {})
                .catch(er => console.log(er));
            changeContactRequestPopup(true);
        } else {
            if (!formInputsEmail.value || !validateEmail(formInputsEmail.value)) {
                changeFormInputsEmail({ ...formInputsEmail, error: true });
            }
        }
    };

    const handleChange = (target, input_name) => {
        if (input_name === 'email') {
            changeFormInputsEmail({ ...formInputsEmail, value: target.value, error: false });
        }
    };

    const everyInput = () => {
        return (
            <div className={'wrapper_every_input'}>
                <Input
                    type={'text'}
                    value={formInputsEmail.value}
                    placeholder={'Your email'}
                    className={formInputsEmail.error ? 'input_error' : null}
                    handleChange={({ target }) => handleChange(target, 'email')}
                />
            </div>
        );
    };

    return (
        <div className={'wrapper_b2b_clients_request'}>
            {contactRequestPopup ? <CustomPopup handleClosePopup={changeContactRequestPopup} /> : null}
            <form onSubmit={handleSubmit}>
                {everyInput()}
                <div className={'wrapper_submit_request'}>
                    <button type={'submit'}>Send request</button>
                </div>
            </form>
        </div>
    );
}
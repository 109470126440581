import React from "react"
import { NavLink } from 'react-router-dom'
import API from "../adminAPI";
import forceLogout from "../adminAPI";

import Logo from "../assets/vinteriors__logo.svg"

import ItemsIcon from "../assets/sidenav-icon-items.svg"
import LangIcon from "../assets/sidenav-icon-variations.svg"
import CategIcon from "../assets/sidenav-icon-categories.svg"
import OrdersIcon from "../assets/sidenav-icon-orders.svg"
import SalesIcon from "../assets/sidenav-icon-sales.svg"
import UsersIcon from "../assets/sidenav-icon-users.svg"
import RequestsIcon from "../assets/sidenav-icon-requests.svg"
import BrandsIcon from "../assets/sidenav-icon-brands.svg"
import PromocodesIcon from "../assets/sidenav-icon-promocodes.svg"
import ReviewsIcon from "../assets/comment-question-outline.svg"
import UploadIcon from "../assets/sidenav-icon-upload.svg"

import ExitIcon from "../assets/sidenav-icon-exit.svg"
import helper from "../helper";

const Sidenav = () => {
    const navObj = [
        {
            id: 1,
            name: "Products",
            link: "/admin/goods/all",
            slug: "/admin/goods",
            icon: ItemsIcon
        },
        {
            id: 2,
            name: "Filters",
            link: "/admin/variations/1",
            slug: "/admin/variations",
            icon: LangIcon
        },
        {
            id: 3,
            name: "Categories",
            link: "/admin/categories/1",
            slug: "/admin/categories",
            icon: CategIcon
        },
        {
            id: 4,
            name: "Users",
            link: "/admin/users",
            slug: "/admin/users",
            icon: UsersIcon
        },
        {
            id: 5,
            name: "Prices and discounts",
            link: "/admin/sales",
            slug: "/admin/sales",
            icon: SalesIcon
        },
        {
            id: 6,
            name: "Applications",
            link: "/admin/requests",
            slug: "/admin/requests",
            icon: RequestsIcon
        },
        {
            id: 7,
            name: "Orders",
            link: "/admin/orders",
            slug: "/admin/orders",
            icon: OrdersIcon
        },
        {
            id: 8,
            name: "Brands",
            link: "/admin/brands",
            slug: "/admin/brands",
            icon: BrandsIcon
        },
        {
            id: 9,
            name: "Promo codes",
            link: "/admin/promocodes",
            slug: "/admin/promocodes",
            icon: PromocodesIcon
        },
        {
            id: 10,
            name: "Reviews",
            link: "/admin/reviews",
            slug: "/admin/reviews",
            icon: ReviewsIcon
        },
        {
            id: 11,
            name: "Upload Data",
            link: "/admin/upload_data",
            slug: "/admin/upload_data",
            icon: UploadIcon
        },
    ];

    const submitLogOut = () => {
        API.post("/admin/logout")
        .then(() => {
            localStorage.removeItem(helper.ADMIN_TOKEN);
            window.location.pathname = "/admin";
            forceLogout()
        });
    };

    return(
    <section className="sidenav">
        <div className="sidenav__wrapper">
            <div className="sidenav-logo">
                <img className="sidenav-logo__source" src={Logo} alt="Vinteriors store"/>
            </div>

            <ul className="sidenav-list">
                {navObj.map(item =>
                    <li className="sidenav-list__item" key={item.id}>
                        <NavLink className="sidenav-list__link" activeClassName="active" to={item.link}
                        isActive={(match, location) => location.pathname.startsWith(item.slug)}
                        >
                            <img className="sidenav-list__icon" src={item.icon} alt={item.name}/>
                            {item.name}
                        </NavLink>
                    </li>
                )}
            </ul>
        </div>

        <div style={{display: "flex", alignItems: "center", fontWeight: "600", cursor: "pointer"}} onClick={submitLogOut}>
            <img className="sidenav-exit__icon" src={ExitIcon} alt="Goods"/>
            Выйти
        </div>
    </section>
)};

export default Sidenav;

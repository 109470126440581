import React, {useEffect, useState} from 'react';
import PageTitle from "../Components/PageTitle";
import PreviewSectionCategory from "../Components/PreviewSectionCategory";
import './styles/about_page.css';

import about_image_second from '../assets/about-image-second.png'
import about_image_map from '../assets/about-map.png'

import about_image_consult from '../assets/about-icon-consult.svg'
import about_image_design from '../assets/about-icon-design.svg'
import about_image_furniture from '../assets/about-icon-furniture.svg'
import about_image_individual from '../assets/about-icon-individual.svg'
import about_image_light from '../assets/about-icon-light.svg'
import about_image_polite from '../assets/about-icon-polite.svg'
import about_image_measure from '../assets/about-icon-measure.svg'
import about_image_textile from '../assets/about-icon-textile.svg'
import {ScrollToTop} from "../Constants/ScrollToTop";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'
//test

const aboutServices = [
    {
        image: about_image_consult,
        imageAlt: 'departure of the consultant',
        text: 'visit of a consultant with catalogs to the object at a time convenient for the customer',
    },
    {
        image: about_image_furniture,
        imageAlt: 'selection of furniture',
        text: 'selection of furniture for the existing design project',
    },
    {
        image: about_image_design,
        imageAlt: 'design development',
        text: 'project design development',
    },
    {
        image: about_image_measure,
        imageAlt: 'departure of the measurer',
        text: 'departure of the measurer at a convenient time for the customer',
    },
    {
        image: about_image_individual,
        imageAlt: 'individual order',
        text: 'possibility of individual order',
    },
    {
        image: about_image_light,
        imageAlt: 'lighting systems',
        text: 'selection and installation of lighting systems',
    },
    {
        image: about_image_textile,
        imageAlt: 'textile decoration',
        text: 'textile interior decoration',
    },
    {
        image: about_image_polite,
        imageAlt: 'adequate and prompt work with complaints',
        text: 'adequate and prompt work with complaints',
    },
];

export default function AboutCompany() {
    const [url, setUrl] = useState(null)

    useEffect(() => {
       ScrollToTop();
       setUrl(window.location.href)
    },[]);

    let aboutBanner = () => {
        return (
            <div className="banner-image">
                <div className="about-banner-info">
                    <h2>Vinteriors</h2>
                    <p>online store with the best author's design projects, premium furniture and home accessories</p>
                </div>
            </div>
        )
    };

    let serviceRange = () => {
        return (
            <div className="about-services">
                <h3>We provide a full range of related services,</h3>
                <p>which are indispensable when creating a modern interior</p>
            <div className="services-range">
                {aboutServices.map((item, index) => {
                    return (
                        <div className="services-range-item" key={index}>
                            <img src={item.image} alt={item.imageAlt} />
                            <p>{item.text}</p>
                        </div>
                    )
                })}
            </div>
            </div>
        )
    };

    return (
        <div className={'wrapper_main_page about_company_page'}>
            <Helmet>
                <meta property="og:title" content={'About company — Vinteriors store'}/>
                <title>About company — Vinteriors store</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="og:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:title" content={'About company — Vinteriors store'}/>
                <meta property="twitter:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <PageTitle title={'About company'}/>

            {aboutBanner()}

            {/*<PreviewSectionCategory wrapper_class_theme={'light'}*/}
            {/*                        className={'about-info'}*/}
            {/*                        categoryName={'We offer the widest range of furnishings,'}*/}
            {/*                        categoryDesc={'as well as options for ready-made interior solutions with the selection of designer furniture, lighting and textiles not only from Italy, but also from Germany, USA, Switzerland and France. At the same time, we offer our clients, designers and architects only high-quality premium'}*/}
            {/*                        image={about_image_first}*/}
            {/*                        link={'/brands'} />*/}

            <PreviewSectionCategory wrapper_class_theme={'light'}
                                    className={'about-info about-info-reverse'}
                                    categoryName={'We have reasonable and competitive prices'}
                                    categoryDesc={'Choose from thousands of products and furniture solutions. Browse the products chosen by architects and decorators and see how they used them in projects.'}
                                    image={about_image_second}
                                    link={'/'}
                                    hideButton />

            <PreviewSectionCategory wrapper_class_theme={'light'}
                                    className={'about-map'}
                                    categoryName={'We ship worldwide!  '}
                                    categoryDesc={'We endeavour to fulfil all orders and may be able to deliver to your destination even if it’s not listed above. Please contact us at request@vinteriors.store to discuss shipping enquiries'}
                                    image={about_image_map}
                                    link={'/terms/delivery'}
                                    linkText={'Learn more'}/>
            {serviceRange()}
        </div>
    )

}

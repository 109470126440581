import React from 'react';
import './style/user_delivery_address.css'
import DeliveryAddressModal from '../../Components/DeliveryAddressModal'

export default function UserDeliveryAddress() {

    return (
        <div className={'wrapper_user_profile_address'}>
            <DeliveryAddressModal/>
        </div>
    )
}
import React, {useState, useEffect} from 'react';
import API from "../adminAPI";
import BookImage from "./BookImage"
import BookSelect from "./BookSelect"
import BookSelectSearch from "./BookSelectSearch"
import BookSelectMultipleSearch from "./BookSelectMultipleSearch"

import "../adminComponents/style/goods.scss"
import DeleteIcon from "../assets/g-icon-delete_gray.svg"
import AddIconLarge from "../assets/g-icon-add_large.svg";
import EditIconRed from "../assets/book-item-edit.svg";
import DeleteIconRed from "../assets/book-item-delete.svg";
import CloseIcon from "../assets/g-modal-close.svg";
import AddIconBlack from "../assets/g-icon-add-black.svg";
import AddIcon from "../assets/g-icon-add.svg";
import IconArrowDown from "../assets/g-icon-arrow_down.svg";

const BookItem = props => {
    const [paramsArr, setParamsArr] = useState([{
        name: "",
        id: Math.random(),
        params: [{name: "", icon: null, price: null, id: 1}]
    }]);

    const [filtersArr, setFiltersArr] = useState([{
        name: "",
        id: Math.random(),
        params: [{param: "", price: null, id: 1}]
    }]);


    const [brandList, setBrandList] = useState({brands: []})

    const InitialItem = {
        images: [],
        name: "",
        brand: {id: null, name: ''},
        types: [],
        filters: {
            id: null,
            name: ""
        },
        price: null,
        discount_price: null,
        description: "",
        properties: {
            id: null,
            name: ""
        },
    };

    const staticValues = {
        authorList: [],
    }

    const [bookItem, setBookItem] = useState(InitialItem);

    const [typesStatic, setTypesStatic] = useState({types: []});

    const [typesList, setTypesList] = useState({types: []});

    const [bookItemStatic, setBookItemStatic] = useState(staticValues);

    const [templateImage, setTemplateImage] = useState(true);

    const [loading, setLoading] = useState(false);

    const [paramList, setParamList] = useState();


    useEffect(() => {
        if (props.editMode) {
            handleEdit();
            API.get("/admin/product", {params: {product_id: props.id}})
                .then(res => {
                    API.get(`/admin/subcategory`, {params: {subcategory_id: res.data.subcategory_id}})
                        .then(res => {
                            setTypesStatic(prevState => ({...prevState, types: res.data.tags}))
                        });
                    API.get(`/admin/filter`, {params: {category_id: res.data.category_id}})
                        .then(res => {
                            let list = {params: []}
                            res.data.filters.forEach(filter => {
                                filter.params.forEach(param => {
                                    param.filter_id = filter.id
                                    list.params.push(param)
                                })
                            })
                            setParamList(list);
                            setLoading(true)
                        })
                });
        } else {
            API.get(`/admin/subcategory`, {params: {subcategory_id: props.subId}})
                .then(res => {
                    setTypesStatic(prevState => ({...prevState, types: res.data.tags}))
                })
            API.get(`/admin/filter`, {params: {category_id: props.categoryId}})
                .then(res => {
                    let list = {params: []}
                    res.data.filters.forEach(filter => {
                        filter.params.forEach(param => {
                            param.filter_id = filter.id
                            list.params.push(param)
                        })
                    })
                    setParamList(list);

                })
            API.get(`/admin/brand`)
                .then(res => {
                    setBrandList(prevState => ({...prevState, brands: res.data.brands}))
                })
        }
// eslint-disable-next-line
    }, []);

    const handleInputChange = e => {
        const {name, value} = e.target;
        setBookItem(prevState => ({...prevState, [name]: value}));
    }

    const handlePriceChange = e => {
        const {name, value} = e.target;
        setBookItem(prevState => ({...prevState, [name]: value}));
    }

    const changeImageFunction = (id, name) => {
        setBookItem(prevState => ({
            ...prevState, images: [...prevState.images, {
                id: id,
                name: name
            }]
        }));
    }

    const editImageFunction = (id, name) => {
        const editedState = bookItem.images.map(item => item.id === id ? {...item, name: name} : item);

        setBookItem(prevState => ({
            ...prevState, images: editedState
        }))
    }

    const deleteImageFunction = id => {
        const imageArr = bookItem.images.filter(item => item.id !== id);

        setBookItem(prevState => ({
            ...prevState, images: imageArr
        }))
    }

    const changeImageTemplate = status => {
        setTemplateImage(status);
    }

    const setCoversIfNull = () => {
        if (bookItem.images === null) {
            setBookItem(prevState => ({
                ...prevState, images: []
            }));
        }
    }

    const handleSearchTerm = (subject, term) => {
        switch (subject) {
            case "author":
                if (term) {
                    API.get(`/admin/${subject}`, {params: {search_admin: term}})
                        .then(res => {
                            setBookItemStatic(prevState => ({...prevState, authorList: res.data.Authors}))
                        });
                } else {
                    setBookItemStatic(prevState => ({...prevState, authorList: []}))
                }
                break;
            case "brand":
                if (term) {
                    API.get(`api/admin/brand?name=${term}`)
                        .then(res => {

                        })
                }
                break;
            case "type":
                if (term) {
                    API.get(`/admin/subcategory`, {params: {subcategory_id: props.subId}})
                        .then(res => {
                            setTypesStatic(prevState => ({...prevState, types: res.data.tags}))
                        });
                } else {
                    setTypesStatic(prevState => ({...prevState, types: []}))
                }
                break;
            default:
                break;
        }
    }

    const handleRemoveItem = (subject, id, name) => {
        switch (subject) {
            case "author":
                setBookItem(prevState => ({...prevState, author: bookItem.author.filter(item => item.id !== id)}));
                break;
            case "type":
                setTypesList(prevState => ({...prevState, types: typesList.types.filter(item => item.id !== id)}));
                break;
            default:
                break;
        }
    }

    const changeFunctionSelect = (subject, value, key, index) => {
        let valueArr = value.split("/");
        switch (subject) {
            case "author":
                setBookItem(prevState => ({
                    ...prevState, author: [...prevState.author, {
                        id: +valueArr[0],
                        name: valueArr[1]
                    }]
                }))
                break;
            case "Фильтр":
                filtersArr.forEach(filter => {
                    if (filter.id === value) {
                        filter.name = subject.target.value
                    }
                })
                setFiltersArr([...filtersArr])
                break;
            case "Значение":
                filtersArr.forEach(filter => {
                    if (filter.id === value) {
                        filter.param = subject.target.value
                    }
                })
                setFiltersArr([...filtersArr])
                break;
            case "type":
                // setTypesList([...typesList, {id: +valueArr[0], name: valueArr[1]}])
                setTypesList(prevState => ({
                    ...prevState, types: [...prevState.types, {
                        id: +valueArr[0],
                        name: valueArr[1]
                    }]
                }))
                break;
            case "brand":
                setBookItem(prevState => ({
                    ...prevState, brand: {
                        id: +valueArr[0],
                        name: valueArr[1]
                    }
                }));
                break
            case "filterName":
                filtersArr[key].name = valueArr[1];
                filtersArr[key].id = +valueArr[0]
                filtersArr[key].params.forEach(param => {
                    param.name = "";
                })
                setFiltersArr([...filtersArr])
                break
            case "filterParam":
                filtersArr[key].params[index].name = valueArr[1];
                filtersArr[key].params[index].id = valueArr[0];
                setFiltersArr([...filtersArr])
                break
            default:
                break;
        }
    }

    const removeSelectedOption = (subject, id) => {
        switch (subject) {
            case "author":
                setBookItemStatic(prevState => ({
                    ...prevState,
                    authorList: bookItemStatic.authorList.filter(item => item.id !== id)
                }));
                break;
            case "type":
                setTypesStatic(prevState => ({
                    ...prevState,
                    types: typesStatic.types.filter(item => item.id !== id)
                }));
                break;
            default:
                break;
        }
    }

    const submitAdd = (e, type) => {
        e.preventDefault();
        if (!bookItem.name || !bookItem.price) return;
        let tagsArr = []
        typesList.types.forEach(tag => {
            tagsArr.push(tag.id)
        })
        setTimeout(() => {

            API.post(`/admin/product`, {
                product_id: props.id ? props.id : null,
                name: bookItem.name ? bookItem.name : null,
                subcategory_id: props.subId ? props.subId : null,
                price: bookItem.price ? +bookItem.price : null,
                discount_price: bookItem.discount_price ? bookItem.discount_price : null,
                brand_id: bookItem.brand.id ? bookItem.brand.id : null,
                product_properties: paramsArr ? paramsArr : null,
                tags: tagsArr ? tagsArr : null,
                filters: filtersArr ? filtersArr : null,
                description: bookItem.description ? bookItem.description : null,
                images: bookItem.images.length > 0 ? bookItem.images.filter(({name}) => name.match(reg)).map(({name}) => `${name}`).length > 0 ? bookItem.images.filter(({name}) => name.match(reg)).map(({name}) => `${name}`) : null : null
            })
                .then(res => {
                    props.changeFunction(false);
                    props.updateData();

                    if (type === "view") {
                        const url = `../../item/${res.data}`;
                        window.open(url, '_blank');
                    }
                })
        })
    }

    const handleEdit = () => {
        API.get("/admin/product", {params: {product_id: props.id}})
            .then(res => {
                setTypesList(prevState => ({...prevState, types: res.data.tags}))
                if (res.data.filters && res.data.filters.length > 0) {
                    filtersArr.pop();
                    setFiltersArr([...filtersArr]);
                    res.data.filters.forEach((filter) => {
                        filtersArr.push({name: filter.name, id: filter.id, params: filter.params})
                    })
                    setFiltersArr([...filtersArr]);
                }

                if (res.data.properties && res.data.properties.length > 0) {
                    paramsArr.pop();
                    res.data.properties.forEach((param) => {
                        paramsArr.push({name: param.name, id: param.id, params: param.params})
                    })
                    setParamsArr([...paramsArr]);
                }


                setTimeout(() => {
                    cleanPhotos(res.data)
                })
            });
    }

    const toDataUrl = (url, callback) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            const reader = new FileReader();
            reader.onloadend = () => {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    };

    const reg = /(data:image)/;

    const cleanPhotos = item => {
        let imgArr = item;
        if (imgArr.images && imgArr.images.length > 0) {
            // eslint-disable-next-line
            let b = imgArr.images.filter((image, index) => {
                if (image.name) {
                    return imgArr.images
                }
            });
            imgArr.images = b;
            setBookItem(imgArr)
        }
        setBookItem(imgArr)
        // setBookItem(imgArr)
    }


    const changePhotosToBase64 = () => {
        let ph = bookItem;
        ph.images.forEach((image) => {
            if (image.name && image.name.split("/")[0] === 'static') {
                toDataUrl(process.env.REACT_APP_IMAGE_API_URL + '/' + image.name, (myBase64) => {
                    image.name = myBase64
                })
            }
        })

        setTimeout(() => setBookItem(ph))
    }


    const changeIconsToBase64 = () => {
        let ph = paramsArr;
        ph.forEach((property) => {
            property.params.forEach(param => {
                if (param.icon && param.icon.split("/")[0] === 'static') {
                    toDataUrl(process.env.REACT_APP_IMAGE_API_URL + '/' + param.icon, (myBase64) => {
                        param.icon = myBase64
                    })
                }
            })
        })
        setParamsArr(ph)

    }

    const submitEdit = async (e, type) => {
        e.preventDefault();
        if (!bookItem.name || !props.id || !bookItem.price) return;
        changePhotosToBase64()
        changeIconsToBase64()
        let tagsArr = [];
        typesList.types.forEach(tag => {
            if (tag.id) {
                tagsArr.push(tag.id)
            } else {
                tagsArr.push(tag.tag_id)

            }
        })
        setTimeout(() => {
            API.put("/admin/product", {
                product_id: props.id ? props.id : null,
                name: bookItem.name ? bookItem.name : null,
                subcategory_id: bookItem.subcategory_id ? bookItem.subcategory_id : null,
                price: bookItem.price ? +bookItem.price : null,
                discount_price: bookItem.discount_price ? bookItem.discount_price : null,
                brand_id: bookItem.brand.id ? bookItem.brand.id : null,
                product_properties: paramsArr ? paramsArr : null,
                tags: tagsArr ? tagsArr : null,
                filters: filtersArr ? filtersArr : null,
                description: bookItem.description ? bookItem.description : null,
                images: bookItem.images.length > 0 ? bookItem.images.filter(({name}) => name.match(reg)).map(({name}) => `${name}`).length > 0 ? bookItem.images.filter(({name}) => name.match(reg)).map(({name}) => `${name}`) : null : null
            })
                .then(res => {
                    props.changeFunction(false);
                    props.updateData();

                    if (type === "view") {
                        const url = `https://thebooks.com.ua/item/${res.data}`;
                        window.open(url, '_blank');
                    }
                })

        }, 100)
    }

    const submitDelete = id => {
        API.delete("admin/product", {params: {id: id}})
            .then(res => {
                props.changeFunction(false);
                props.updateData();
            })
    }


    const setImage = (e, id, index) => {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            let fileBase64 = reader.result;
            paramsArr[id].params[index].icon = `${fileBase64}`;
            setParamsArr([...paramsArr])
        };
    };

    const changeFilterArrInputs = (e, key, value, index, params = null) => {
        if (!params) {
            filtersArr[key][value] = e.target.value;
            setFiltersArr([...filtersArr]);
        } else {
            filtersArr[key][value][index][params] = e.target.value;
            setFiltersArr([...filtersArr]);
        }
    };

    const changeFilterInputs = (e, key, value, index, params = null) => {
        if (!params) {
            paramsArr[key][value] = e.target.value;
            setParamsArr([...paramsArr]);
        } else {
            paramsArr[key][value][index][params] = e.target.value;
            setParamsArr([...paramsArr]);
        }
    };

    const submitAddProperty = e => {
        e.preventDefault();
    };


    return loading && (
        <div className="book">
            <h2 className="book__title">{props.editMode ? `Изменить` : `Добавить`} товар</h2>

            <h3 className="book__subtitle">Информация про товар</h3>

            <BookImage images={bookItem.images} templateImage={templateImage} changeImageTemplate={changeImageTemplate}
                       changeImageFunction={changeImageFunction} editImageFunction={editImageFunction}
                       deleteImageFunction={deleteImageFunction} addMode={props.addMode} editMode={props.editMode}
                       setCoversIfNull={setCoversIfNull}/>

            <div className="book__block">
                <div className="book-label">
                    <span className="book__span">Название</span>
                    <input className="book-label__input" type="text" name="name" placeholder="Название"
                           value={bookItem.name} onChange={handleInputChange}/>
                </div>

                <BookSelectSearch subject="brand" title={bookItem.brand ? bookItem.brand.name : ""}
                                  subjectData={brandList.brands} changeFunctionSelect={changeFunctionSelect}/>

                <label className="book-label">
                    <h3 className="book__span">Цена, €</h3>
                    <input className="book-label__input" type="number" value={bookItem.price} name="price"
                           placeholder="Цена" onChange={handlePriceChange}/>
                </label>

                <label className="book-label">
                    <h3 className="book__span">Цена со скидкой, €</h3>
                    <input className="book-label__input" type="number" value={bookItem.discount_price}
                           name="discount_price" placeholder="Цена со скидкой" onChange={handlePriceChange}/>
                </label>

                <BookSelectMultipleSearch subject="type" data={typesStatic.types} selectedData={typesList.types}
                                          changeFunctionSelect={changeFunctionSelect}
                                          handleSearchTerm={handleSearchTerm}
                                          removeSelectedOption={removeSelectedOption}
                                          handleRemoveItem={handleRemoveItem}/>

                <div style={{
                    gridColumn: "1/-1",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 43px",
                    gridGap: "24px"
                }}>
                    <h3 className="book__subtitle">Фильтры</h3>
                    <h3 style={{gridColumn: "3/4"}} className="book__subtitle">Добавленая стоимость, € </h3>
                </div>
                <div style={{gridColumn: "1/-1"}}>
                    {filtersArr && filtersArr.length > 0 && filtersArr.map((i, key) => {
                        return (
                            <div key={key} style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 2fr 43px",
                                gridGap: "24px",
                                marginBottom: "24px"
                            }}>

                                <div>
                                    <div style={{position: "relative"}}>
                                        <BookSelect subject="filterName" title={i.name ? i.name : ""}
                                                    categoryId={props.editMode ? bookItem.subcategory_id : props.categoryId} index={key}
                                                    changeFunctionSelect={changeFunctionSelect}/>

                                        <img className="book-select__arrow" style={{
                                            position: "absolute",
                                            right: "16px",
                                            top: "20px",
                                            cursor: "pointer"
                                        }} src={IconArrowDown} alt=""/>

                                    </div>
                                </div>

                                <div style={{display: 'grid', gridTemplateColumns: '1fr', gridGap: '16px'}}>
                                    {filtersArr[key] && filtersArr[key].params.map((param, index) => {
                                        return (
                                            <div style={{
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 1fr 43px',
                                                gridGap: '24px'
                                            }}>
                                                <div>
                                                    <div style={{position: "relative"}}>
                                                        <BookSelect subject="filterParam"
                                                                    title={filtersArr[key].params[index].name ? filtersArr[key].params[index].name : ""}
                                                                    subcatData={paramList} keys={key} keys1={index}
                                                                    categoryID={props.editMode ? bookItem.category_id : props.categoryId} filter_id={i.id}
                                                                    changeFunctionSelect={changeFunctionSelect}/>

                                                        <img className="book-select__arrow" style={{
                                                            position: "absolute",
                                                            right: "16px",
                                                            top: "20px",
                                                            cursor: "pointer"
                                                        }} src={IconArrowDown} alt=""/>

                                                    </div>
                                                </div>

                                                <div>
                                                    <div style={{position: "relative"}}>
                                                        <input style={{width: "100%"}} value={param.price}
                                                               onChange={(e) => {
                                                                   changeFilterArrInputs(e, key, "params", index, "price")
                                                               }} placeholder={'Цена'} className={"book-select__header"}
                                                               type=""/>
                                                    </div>
                                                </div>

                                                <div style={{
                                                    backgroundColor: "rgba(51, 51, 58, 0.2)",
                                                    display: "grid",
                                                    marginBottom: "auto",
                                                    cursor: 'pointer'
                                                }}>
                                                    <img onClick={filtersArr[key].params.length === 1 ? () => {
                                                            filtersArr.forEach((element, index) => {
                                                                if (element.id === i.id) {
                                                                    filtersArr.splice(index, 1);

                                                                }

                                                            });
                                                            setFiltersArr([...filtersArr]);
                                                        } :
                                                        () => {
                                                            filtersArr[key].params.forEach((params, index) => {
                                                                if (params.id === param.id) {
                                                                    filtersArr[key].params.splice(index, 1);
                                                                }

                                                            });
                                                            setFiltersArr([...filtersArr]);

                                                        }
                                                    } src={CloseIcon} style={{padding: "12px", display: "grid"}}
                                                         alt=""/>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>


                                <div onClick={() => {
                                    filtersArr.forEach((element, index) => {
                                        if (element.id === i.id) {
                                            filtersArr[index].params.push({param: '', price: null, id: Math.random()});
                                        }
                                    });
                                    setFiltersArr([...filtersArr]);
                                }}
                                     style={{
                                         backgroundColor: "white",
                                         display: "grid",
                                         marginBottom: "auto",
                                         border: '1px solid #000000',
                                         borderRadius: '2px',
                                         cursor: 'pointer'
                                     }}>
                                    <img src={CloseIcon}
                                         style={{padding: "12px", display: "grid", transform: 'rotate(45deg)'}} alt=""/>
                                </div>
                            </div>
                        )
                    })}
                    <button onClick={() => {
                        setFiltersArr([...filtersArr,
                                {name: "", id: Math.random(), params: [{param: "", price: null, id: 1}]}
                            ]
                        )
                    }} className="g-add" style={{
                        height: "max-content",
                        marginLeft: "auto",
                        backgroundColor: "white",
                        border: '1px black solid',
                        color: "black",
                        borderRadius: "5px",
                    }}>
                        <img className="g-add__icon" src={AddIconBlack} alt="Додати"/>
                        Добавить фильтр
                    </button>
                </div>
            </div>

            <div style={{
                borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                margin: "32px 0",
                padding: "24px 0 35px"
            }} className="categories-add">
                <div style={{display: "flex", alignItems: "center"}}>
                    <h3 className="categories-add__title">Добавить свойство товара</h3>
                    <button onClick={() => {
                        setParamsArr([...paramsArr, {
                                name: "", id: Math.random(), params: [{param: "", icon: null, price: null, id: 1}]
                            }]
                        )
                    }} className="g-add" style={{
                        height: "max-content",
                        marginLeft: "auto",
                        backgroundColor: "white",
                        border: '1px black solid',
                        color: "black",
                        borderRadius: "5px"
                    }}>
                        <img className="g-add__icon" src={AddIconBlack} alt="Додати"/>
                        Добавить свойство
                    </button>
                </div>
                <form style={{flexDirection: 'column'}} className="categories-add__form"
                      onSubmit={submitAddProperty}>
                    {paramsArr.length > 0 ?
                        <>
                            {paramsArr && paramsArr.length > 0 && paramsArr.map((i, key) => {
                                return (
                                    <div style={key > 0 ? {
                                        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                                        marginTop: '24px',
                                        paddingTop: '16px'
                                    } : null}>
                                        <p className="categories-add__text">Название</p>

                                        <input className="g-input" type="text" placeholder="Название" name="name"
                                               value={i.name} onChange={(e) => {
                                            changeFilterInputs(e, key, "name")
                                        }}/>


                                        <table className="orders-table">
                                            <thead className="orders-table__head">
                                            <tr className="orders-table__tr">
                                                <th style={{
                                                    width: "40%",
                                                    textAlign: "start",
                                                    paddingLeft: "0 !important"
                                                }} className="orders-table__thv">Параметры
                                                </th>
                                                <th style={{width: "20%", textAlign: "start"}}
                                                    className="orders-table__th">Иконка
                                                </th>
                                                <th style={{width: "40%", textAlign: "start"}}
                                                    className="orders-table__th">Добавленная стоимость, €
                                                </th>
                                            </tr>
                                            </thead>
                                            {paramsArr[key].params.map((param, index) => {

                                                return (
                                                    <tbody className="orders-table__body">
                                                    <tr className="orders-table__tr" key={key}>
                                                        <td style={{paddingLeft: "0 !important"}}
                                                            className="orders-table__tdv">
                                                            <input className={"g-input"} type="text" onChange={(e) => {
                                                                changeFilterInputs(e, key, "params", index, "param")
                                                            }} placeholder={"Параметр"} value={param.param}/>
                                                        </td>
                                                        <td className="orders-table__td">
                                                            <div style={{display: "flex"}}
                                                                 className="categories-add__block">
                                                                <label style={{
                                                                    width: "45px",
                                                                    height: "45px",
                                                                    marginBottom: "16px"
                                                                }} className="categories-add__label">
                                                                    {param.icon ? <img style={{cursor: "default"}}
                                                                                       className="categories-add__image"
                                                                                       src={props.editMode ? process.env.REACT_APP_IMAGE_API_URL + "/" + param.icon : param.icon}
                                                                                       alt="Upload"/> : null}
                                                                    {param.icon ? null :
                                                                        < input className="categories-add__file-new"
                                                                                type="file"
                                                                                onChange={e => setImage(e, key, index)}
                                                                                onClick={e => e.currentTarget.value = null}/>
                                                                    }
                                                                    <img style={{width: "20px"}} src={AddIconLarge}
                                                                         alt=""/>

                                                                </label>
                                                                <div style={{
                                                                    marginLeft: "8px",
                                                                    marginBottom: "14px",
                                                                    position: "relative"
                                                                }}>
                                                                    <img style={{height: "100%", width: "100%"}}
                                                                         src={EditIconRed} alt=""/>
                                                                    {i.icon ?
                                                                        < input className="categories-add__file-new"
                                                                                type="file"
                                                                                onChange={e => setImage(e, key)}
                                                                                onClick={e => e.currentTarget.value = null}/>
                                                                        : null}
                                                                </div>
                                                                <div style={{
                                                                    marginLeft: "8px",
                                                                    marginBottom: "14px",
                                                                    position: "relative"
                                                                }}>
                                                                    <img style={{height: "100%", width: "100%"}}
                                                                         src={DeleteIconRed} onClick={() => {
                                                                        paramsArr[key].params[index].icon = null;
                                                                        setParamsArr([...paramsArr])
                                                                    }} alt=""/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="orders-table__td">
                                                            <input className={"g-input"} type="text" onChange={(e) => {
                                                                changeFilterInputs(e, key, "params", index, "price")
                                                            }} placeholder={"0"} value={param.price}/>
                                                        </td>
                                                        <div onClick={() => {
                                                            if (paramsArr[key].params.length > 1 ) {
                                                                paramsArr[key].params.forEach((params, index) => {
                                                                    if (params.id === param.id) {
                                                                        paramsArr[key].params.splice(index, 1);
                                                                    }

                                                                });
                                                                setParamsArr([...paramsArr]);
                                                            }
                                                        }}
                                                             style={{
                                                                 backgroundColor: "rgba(51, 51, 58, 0.2)",
                                                                 display: "grid",
                                                                 marginTop: "14px"
                                                             }}>
                                                            <img src={CloseIcon}
                                                                 style={{padding: "12px", display: "grid"}} alt=""/>
                                                        </div>
                                                    </tr>
                                                    </tbody>
                                                )
                                            })}


                                        </table>

                                        <div className="categories-add_flex">
                                            <button onClick={() => {
                                                paramsArr.forEach((element1, index1) => {
                                                    if (element1.id === i.id) {
                                                        paramsArr[index1].params.push({
                                                            param: "",
                                                            icon: null,
                                                            price: null,
                                                            id: Math.random()
                                                        });
                                                    }
                                                });
                                                setParamsArr([...paramsArr]);
                                            }} className="g-add" type="submit">
                                                <img className="g-add__icon" src={AddIcon} alt="Додати"/>
                                                Добавить
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </>
                        : null
                    }
                </form>
            </div>

            <h3 className="book__subtitle">Описание</h3>

            <textarea className="book__textarea" name="description" value={bookItem.description} placeholder="Описание"
                      onChange={handleInputChange}></textarea>


            <div className={`book_flex${props.editMode ? " book_editMode" : props.addMode ? " book_addMode" : ""}`}>
                {props.editMode ? <button className="book__delete" onClick={() => submitDelete(props.id)}>
                    <img src={DeleteIcon} alt="Видалити"/>
                </button> : null}


                <button className="g-add g-add_margin" type="submit"
                        onClick={props.addMode ? submitAdd : props.editMode ? submitEdit : ""}>
                    Сохранить
                </button>

            </div>

        </div>
    );
};

export default BookItem;

import React, {useEffect, useState} from "react";
import "./styles/brands.css";
import EveryBrands from "../Components/EveryBrands"
import API from "../adminAPI";
import {Link} from "react-router-dom";
import {ScrollToTop} from "../Constants/ScrollToTop";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'

const BrandsPage = React.memo(() => {
    const [listBrands, setListBrands] = useState(null);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth)
    const [loading, setLoading] = useState(false)
    const [url, setUrl] = useState(false)

    useEffect(() => {
        ScrollToTop();
        window.addEventListener('resize', () => setInnerWidth(window.innerWidth))
    }, [])

    useEffect(() => {
        setUrl(window.location.href)
        API.get(`/all_brands`)
            .then(res => {
                if (res.data) {
                    setListBrands(res.data);
                    setLoading(true)
                }
            })
    }, [])

    return (
        <div className={'wrapper_main_page wrapper_brands_page'}>
            <Helmet>
                <meta property="og:title" content={'Brands — Vinteriors store'}/>
                <title>Brands — Vinteriors store</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="og:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:title" content={'Brands — Vinteriors store'}/>
                <meta property="twitter:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <div className={'wrapper_filters delete_mobili'}>
                <div className={'brands_page_title'}>Brands</div>
                <div className={'text_brands'}>
                    <span>{'More than 300 brands are represented on our site'}</span>
                </div>
                <div className={'text'}>
                    <span>{'Most popular'}</span>
                </div>
            </div>
            <EveryBrands/>
            <div className={'list_brands'}>
                {loading && listBrands ?
                    Object.entries(listBrands).map((item, index) => {
                        return (
                            <div key={index} className={'wrapper_content'}>
                                {innerWidth > 768 ?
                                    <div id={item[0]} className={'every_word_wrapper'}>
                                        <div className={'title_section'}>
                                            {item[0]}
                                        </div>
                                        <div className={'publishing_content_wrapper'}>
                                            {item[1].map((i, index) => {
                                                return (
                                                    <Link key={index} to={`/catalogue/brand=${i.id}`}>{i.name}</Link>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : <span className={'every_word_wrapper'}>
                                <span className={'title_section'}>
                                    {item[0]}
                                </span>
                                <span className={'publishing_content_wrapper'}>
                                    {item[1].map((i, index) => {
                                        return (
                                            <Link key={index} to={`/catalogue/brand=${i.id}`}>{i.name}</Link>
                                        )
                                    })}
                                </span>
                            </span>}
                            </div>
                        )
                    }) : null}
            </div>
        </div>
    )
})

export default BrandsPage

export const OPEN_SIGN_MODAL = 'OPEN_SIGN_MODAL';
export const AUTH_ACTIONS = 'AUTH_ACTIONS';
export const USER_NAME = 'USER_NAME';
export const SIGN_RELOCATE = 'SIGN_RELOCATE';
export const REMOVE_USER = 'REMOVE_USER';

export const openSignModal = bool => ({
    type: OPEN_SIGN_MODAL,
    payload: bool
});

export const signRelocate = val => ({
    type: SIGN_RELOCATE,
    payload: val
});

export const changeAuthActions = value => ({
    type: AUTH_ACTIONS,
    payload: value
});

export const putName = value => ({
    type: USER_NAME,
    payload: value
});

export const removeUser = value => ({
    type: REMOVE_USER,
    payload: value
})
import React, {useEffect, useState} from 'react';
import "./styles/conditions.css";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'
export default function PrivacyPolicy() {
    const [url, setUrl] = useState(null)

    useEffect(() => {
        setUrl(window.location.href)
    }, [])

    return (
        <div className={'main_page_conditions'}>
            <Helmet>
                <meta property="og:title" content={'Privacy policy — Vinteriors store.'}/>
                <title>Privacy policy — Vinteriors store.</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="og:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:title" content={'Privacy policy — Vinteriors store.'}/>
                <meta property="twitter:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>

            <span className={'playfair_display_400'}>
                <h2 className={'title'}>Privacy policy</h2>
                <p className={'content_other'}>{'1. PURPOSE OF THIS POLICY'}</p>
                <p className={'content'}>{'The purpose of the privacy policy is to inform you how Vinteriors.store (“we”, “us”) process your personal data. With this policy, we wish to make you aware of the information we collect and process and, if possible, for how long we store it. This privacy policy regulates the processing of personal data by Vinteriors.store in connection with trading, interaction, or other exchange of personal data with us.'}</p>
                <p className={'content_other'}>{'2. DESCRIPTION OF PROCESSING'}</p>
                <p className={'content'}>{'Vinteriors.store process your information for one or more specific purposes and in accordance with the data protection regulations. We process your information if you are a customer with us, a collaborator or partner with us, visit our website or our social media sites and apply for a job with us. The information will generally come directly from you, and we will only process your information for as long as it is necessary for the purpose for which it was collected.'}</p>
                <p className={'content'}>{'2.1.1. WEBSITE AND COOKIES'}</p>
                <p className={'content'}>{'In connection with the regular operation of our website, we collect personal data about you via cookies to improve the website\'s appearance and user experience, as well as to compile statistics. We only collect your personal data if you have given your consent hereto (GDPR art. 6(1)(a)). You can withdraw your consent at any time. Read more about this in our cookie policy.'}</p>
                <p className={'content'}>{'Our website also includes integrated plugins from social media platforms that may also collect data from you if you have given your consent hereto. In this connection, we have a joint data responsibility with every media platform. We use integrated plugins from Facebook, Instagram, YouTube, Pinterest or LinkedIn.'}</p>
                <p className={'content'}>{'2.1.2. SOCIAL MEDIA'}</p>
                <p className={'content'}>{'We use certain social media, including Facebook, Instagram, YouTube, Pinterest and LinkedIn to get in touch with our customers and potential customers, as well as promote our marketing initiatives. If you have been to these and “liked” or “followed” our fan page or have been in contact with us through these, we will process your personal data. We only process information about your name and e-mail as well as any information about your purchase, if it appears in the context.'}</p>
                <p className={'content'}>{'If you contact us via social media, we process your information based on our interest in being able to contact you and respond to your inquiries (GDPR art. 6(1)(f)). The information will come from you and the social media through which you contact us.'}</p>
                <p className={'content'}>{'We have a joint data responsibility with the social medias that we use, as we both use your data for our own purposes. You can read more about the social medias processing of your personal data in the following:'}</p>
                <p className={'content'}>{'• Facebook and Instagram’s processing of your personal data in Facebook’s Privacy Policy'}</p>
                <p className={'content'}>{'• LinkedIn´s processing of your personal data in LinkedIn’s Privacy Policy and LinkedIn’s joint data responsibility here'}</p>
                <p className={'content'}>{'• YouTube’s (Google’s) processing of your personal data in Google’s Privacy Policy'}</p>
                <p className={'content'}>{'• Pinterest´s processing of your personal data in Pinterest’s Privacy Policy and Pinterest’s joint data responsibility here.'}</p>
                <p className={'content'}>{'Information processed in connection with social media is deleted along with it. Posting on Facebook pages or in public groups, for example, are not deleted, as a post or comment on Facebook pages or in public groups is public areas. You can read about public areas here.'}</p>
                <p className={'content'}>{'2.1.3. MARKETING'}</p>
                <p className={'content'}>{'We only process your personal data for marketing purposes if you have given your consent in accordance with GDPR art. 6(1)(a) to e.g., receive newsletters about events, invitations, new products, and inspiration about interior design. The scope of our processing is stated in the specific consent.'}</p>
                <p className={'content'}>{'If you have consented to receiving marketing initiatives from us, we generally process data about name, e-mail and customer type, e.g. whether you are an architect, designer, dealer or private individual.'}</p>
                <p className={'content'}>{'You have the right to withdraw your consent at any time by unsubscribing via this e-mail: request@vinteriors.store'}</p>
                <p className={'content'}>{'We keep documentation of your consent for as long as you receive our marketing initiatives or participate in our marketing and for 2 years after you have withdrawn your consent, as any criminal liability expires after this period.'}</p>
                <p className={'content'}>{'2.1.4. CUSTOMER RELATIONSHIP'}</p>
                <p className={'content'}>{'In connection with the sale of goods, we process personal data about you or the contact person at our business customers to enter into and fulfil an agreement with you (GDPR Article 6(1)(b)). This data includes contact information such as name, company name, email, address, and telephone number as well as information about purchase history and payment information.'}</p>
                <p className={'content'}>{'We pass on data about our clients to employees and full-time consultants of our subsidiary, MENU North America Inc. In this connection, we have provided a legal transfer basis by using the EU Commission Standard Contractual Clauses (SCC’s).'}</p>
                <p className={'content'}>{'We delete the information on an ongoing basis, however at the latest 5 years after the end of the customer relationship.'}</p>
                <p className={'content_other'}>{'3. RECIPIENTS OF PERSONAL DATA'}</p>
                <p className={'content'}>{'We process your personal data with confidentiality and we generally do not disclose your information with third parties. However, we may disclose your personal data if you have given your consent hereto or if we have a legitimate interest in the disclosure.'}</p>
                <p className={'content'}>{'We may entrust your personal data to our system suppliers who process personal data on our behalf and according to our specific instructions in accordance with the data processing agreements we have entered into.'}</p>
                <p className={'content'}>{'Some of our data processors and the social media platforms that we have a joint data responsibility with may be located outside the EU in which a transfer to a third country occurs. In this case, we have made sure that a legal transfer basis has been prepared, including by the use of EU Commission Standard Contractual Clauses (SCC’s).'}</p>
                <p className={'content_other'}>{'4. YOUR RIGHTS'}</p>
                <p className={'content'}>{'When we collect information about you, you have several fundamental rights in the personal data regulations that you can use. Your rights include the right to request access to and rectification or erasure of your personal data, restriction and objection to our processing, and the right to receive your data in a structured, commonly used, and machine-readable format (data portability).'}</p>
                <p className={'content'}>{'The above-mentioned rights may be associated with conditions and restrictions. Whether you as a data subject can request for example getting your personal data deleted will in any case depend on a concrete assessment.'}</p>
                <p className={'content'}>{'If you have consented to our processing of your information, you have the right to revoke this consent at any time.'}</p>
                <p className={'content_other'}>{'5. OUR CONTACT INFORMATION'}</p>
                <p className={'content'}>{'The company responsible for processing your personal data is:'}</p>
                <p className={'content'}>{'Vinteriors.store'}</p>
                <p className={'content'}>{'Adress: Poland, Warsaw, ul. Ostrobramska 101'}</p>
                <p className={'content'}>{'E-mail: request@vinteriors.store'}</p>
                <p className={'content'}>{'If you have any questions regarding our processing of your personal data, please feel free to contact us at request@vinteriors.store '}</p>
                <p className={'content_other'}>{'6. REVISION'}</p>
                <p className={'content'}>{'We reserve the right to revise and modify these privacy policy guidelines on the processing of personal data. In case of significant changes, we will contact you via email or via a visible notification on our website.'}</p>
                <p className={'content_other'}>{'This privacy policy was last revised on 4 January 2023.'}</p>
                <p className={'content'}>{''}</p>
            </span>
        </div>
    )
}

import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import API from "../API"
import PageTitle from "../Components/PageTitle"
import EveryGoods from "../Components/EveryGoods"
import './styles/catalogue_list.css';
import Pagination from "react-js-pagination";
import vector from "../assets/vector.svg";
import ChangedConfirmedPopup from "../Components/ChangedConfirmedPopup";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'

const CatalogueList = React.memo(() => {
    const location = useLocation();
    const [goods, setGoods] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [changingConfirmed, setChangingConfirmed] = useState(false);
    const [successText, setSuccessText] = useState(null);
    const [url, setUrl] = useState(null);

    let changeContinuePopup = (text = "Changes saved successfully") => {
        setChangingConfirmed(state => !state)
        setSuccessText(text)
    }

    useEffect(() => {
        setUrl(window.location.href)
        let url = location.pathname.split('/').pop();
        if (url.includes('brand')) {
            let brandId = url.split('=').pop()
            API.get(`/products_by_brands?brand_id=${brandId}&page=${activePage}`)
                .then(res => {
                    setGoods(res.data)
                })
        } else {
            API.get(`/${url}?limit=50`)
                .then(res => {
                    setGoods(res.data)
                })
        }
    }, [activePage, location.pathname]);

    return (
        <div className={'wrapper_main_page'}>
            <Helmet>
                <meta property="og:title" content={'Catalog — Vinteriors store.'}/>
                <title>Catalog — Vinteriors store.</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description"
                      content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="og:description"
                      content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:title" content={'Catalog — Vinteriors store.'}/>
                <meta property="twitter:description"
                      content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
            <div className={'wrapper_catalogue_title'}>
                <PageTitle title={'Catalogue'}/>
                <div className={'wrapper_goods_length'}>
                            <span className={'playfair_display_400'}>
                                {goods?.total ? goods.total + ' goods' : ''}
                            </span>
                </div>
            </div>
            <div className={'wrapper_catalogue_goods'}>
                {goods?.products.map(item => {
                    return <EveryGoods item={item}
                                       changeContinuePopup={changeContinuePopup}
                                       key={item.product_id}/>
                })}
            </div>
            {goods?.total > 9 ?
                <div className={'wrapper_pagination'}>
                    <Pagination
                        activePage={Number(activePage)}
                        itemsCountPerPage={20}
                        firstPageText={activePage >= 3 && goods.pages > 3 ? '1' : null}
                        prevPageText={<img src={vector} className={'prev_page_pagination'} alt="vector"/>}
                        nextPageText={<img src={vector} className={'next_page_pagination'} alt="vector"/>}
                        totalItemsCount={goods.total}
                        lastPageText={activePage <= goods.pages - 2 && goods.pages > 3 ? goods.pages.toString() : null}
                        pageRangeDisplayed={3}
                        onChange={pageNumber => setActivePage(pageNumber)}/>
                </div>
                : null}
        </div>
    )
});

export default CatalogueList

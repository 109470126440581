import {AUTH_ACTIONS, OPEN_SIGN_MODAL, USER_NAME, SIGN_RELOCATE} from "./actions";
import helper from "../../helper";

const InitialState = {
    sign_modal: null,
    auth_action: null,
    sign_relocate: null,
    user_name: JSON.parse(localStorage.getItem(helper.USER_DATA))?.name ?? 'user name'
};

export const header = (state = InitialState, action) => {
    switch (action.type) {
        case OPEN_SIGN_MODAL:
            return {...state, sign_modal: action.payload};
        case AUTH_ACTIONS:
            return {...state, auth_action: action.payload};
        case SIGN_RELOCATE:
            return {...state, sign_relocate: action.payload};
        case USER_NAME:
            return {...state, user_name: action.payload};
        default:
            return state
    }
};

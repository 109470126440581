import React, {useState, useEffect} from 'react';
import API from "../adminAPI";

import Layout from "../adminComponents/Layout"

import closePopup from "../../src/assets/g-modal-close.svg"

import "../adminComponents/style/orders.scss"
import "../adminComponents/style/requests.css"

import "../adminComponents/style/requests.scss"

const OrdersPage = () => {

    const [requestsData, setRequestsData] = useState([]);
    const [options, setOptions] = useState({
        processed: false,
        per_page: 20
    });

    const [showCount, setShowCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0);
    const [leftCount, setLeftCount] = useState(0);

    const [showMessage, setShowMessage] = useState(null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        API.get(`/admin/applications?per_page=${20 + showCount}`)
            .then(res => {
                setTotalCount(res.data.total);
                setLeftCount(res.data.total - res.data.properties.length);
                setLoading(false)

                setRequestsData(res.data.properties)
            })
    }, [showCount]);

    const showMore = () => {
        if (totalCount / leftCount < 1) {
            setShowCount(totalCount)
        } else if (totalCount / leftCount > 1) {
            setShowCount(prevState => prevState + 20)
        }
    }

    const handleTabNew = () => {
        setOptions({...options, processed: false});

        API.get(`/admin/applications?per_page${20 + showCount}`)
            .then(res => {
                setRequestsData([]);
                setRequestsData(res.data.properties)
            })
    }

    const handleTabOld = () => {
        setOptions({...options, processed: true});

        API.get(`/admin/applications?processes=1&per_page=${20 + showCount}`)
            .then(res => {
                setRequestsData([]);
                setRequestsData(res.data.properties)
            })
    }

    const addToOld = (itemId) => {
        API.post(`/admin/applications?order_id=${itemId}`)
            .then(() => {
                API.get(`/admin/applications`)
                    .then(res => {
                        setRequestsData(res.data.properties)
                    })
            })
    }

    return (
        <Layout>
            <section className="g-sheets requests">
                <div className="g-sheets__wrapper container">
                    <div className="g-sheets-view">
                        <span className={'g-sheets-view__new'}>Заявки</span>
                        <button className={`g-sheets-view__new${options.processed ? '' : ' active'}`}
                                onClick={handleTabNew}>Новые
                        </button>
                        <button className={`g-sheets-view__old${options.processed ? ' active' : ''}`}
                                onClick={handleTabOld}>Архив
                        </button>
                    </div>

                    <table className="orders-table">
                        <thead className="g-sheets-table__head">
                        <tr>
                            <th className="g-sheets-table__th">Имя</th>
                            <th className="g-sheets-table__th">Дата</th>
                            <th className="g-sheets-table__th">Имейл</th>
                            <th className="g-sheets-table__th">Номер телефону</th>
                            <th className="g-sheets-table__th">Сообщение</th>
                        </tr>
                        </thead>

                        {requestsData.map((item, index) =>
                            <tbody className="g-sheets-table__tr" key={item.contact_id}>
                            <td className="g-sheets-table__td">{item.name}</td>
                            <td className="g-sheets-table__td">{item.date.split(" ")[0]} <span
                                className="g-sheets-table__span">{item.date.split(" ")[1]}</span></td>
                            <td className="g-sheets-table__td">{item.email}</td>
                            <td className="g-sheets-table__td">{item.phone}</td>
                            <td className="g-sheets-table__td">{item.message ? <button onClick={() => {
                                setShowMessage(item.id)
                            }} style={{
                                backgroundColor: "black",
                                color: "white",
                                border: "none",
                                padding: "4px 12px",
                                borderRadius: '4px'
                            }}>Читать</button> : null}</td>
                            {options.processed === false ? <td className="g-sheets-table__td">
                                <button className="g-btn__archive" onClick={() => addToOld(item.id)
                                }>в архив
                                </button>
                            </td> : null}
                            {showMessage === item.id ?
                                <div className={"show_message"}>
                                    <div className={"message"}>
                                        <div className={"message_header"}>
                                            <span>{item.name}</span>
                                            <img onClick={() => {
                                                setShowMessage(null)
                                            }} src={closePopup} alt=""/>
                                        </div>
                                        <div className={"message_text"}>
                                            <span>{item.message}</span>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            </tbody>
                        )}
                    </table>

                    {leftCount ?
                        <button className="g-add" onClick={!loading ? showMore : null}>Показать ещё</button> : null}
                </div>
            </section>
        </Layout>
    );
};

export default OrdersPage

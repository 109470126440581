import React, { useState } from 'react';
import Layout from "../adminComponents/Layout";
import API from "../adminAPI";
import helper from "../helper";

const UploadDataPage = () => {
    const InitialItem = {
        path: "",
        file: null
    };

    const [subcategoryItem, setSubcategoryItem] = useState(InitialItem);

    const setFile = e => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSubcategoryItem({ ...subcategoryItem, file: reader.result.split(',')[1], path: file.name });
            };
            reader.readAsDataURL(file);
        }
    };

    const submitAdd = e => {
        e.preventDefault();

        const payload = {
            file: subcategoryItem.file,
            name: subcategoryItem.path,
        };

        API.post(`/admin/upload_data`, payload)
            .then(() => {
                setSubcategoryItem(InitialItem);
                e.target.reset();
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    alert(`Error: ${error.response.data.error || 'Wrong file'}`);
                } else {
                    console.error('Error loading file:', error);
                }
            });
    };

    const handleDownloadClick = () => {
        window.open(
            API.defaults.baseURL + "/admin/upload_data?jwt=" + localStorage.getItem(helper.ADMIN_TOKEN),
            "_blank"
        );
    };

    return (
        <Layout>
            <section className="categories" style={{ minHeight: '100vh' }}>
                <div className="container">
                    <div className="categories-add">
                        <h3 className="categories-add__title">Upload Data</h3>
                        <form
                            className="categories-add__form"
                            onSubmit={submitAdd}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '20px'
                            }}
                        >
                            <div
                                className="categories-add__block"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '20px'
                                }}
                            >
                                <div
                                    className="empty-space"
                                    style={{
                                        width: '150px',
                                        height: '100px'
                                    }}
                                />

                                <label
                                    className="categories-add__label"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        position: 'relative',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <input
                                        className="categories-add__file"
                                        type="file"
                                        onChange={setFile}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            cursor: 'pointer'
                                        }}
                                        id="fileInput"
                                    />
                                    <div
                                        className="categories-add__file-preview"
                                        style={{
                                            width: '200px',
                                            height: '100px',
                                            border: `2px dashed ${subcategoryItem.file ? '#4CAF50' : '#ccc'}`,
                                            borderRadius: '4px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            padding: '10px',
                                            boxSizing: 'border-box',
                                            backgroundColor: subcategoryItem.file ? '#e8f5e9' : 'transparent',
                                            transition: 'all 0.3s ease'
                                        }}
                                    >
                                        {subcategoryItem.path ? (
                                            <p className="categories-add__file-name"
                                               style={{marginBottom: '10px', fontSize: '14px', color: '#333'}}>
                                                {subcategoryItem.path}
                                            </p>
                                        ) : (
                                            <p className="categories-add__text"
                                               style={{fontSize: '14px', color: '#666'}}>
                                                Click to select a file
                                            </p>
                                        )}
                                    </div>
                                </label>

                                <div
                                    className="empty-space"
                                    style={{
                                        width: '50px',
                                        height: '100px'
                                    }}
                                />

                                <div
                                    className="categories-add__buttons"
                                    style={{display: 'flex', flexDirection: 'column', gap: '10px'}}
                                >
                                    <button
                                        className="g-add"
                                        type="submit"
                                        style={{
                                            padding: '10px 20px',
                                            fontSize: '16px',
                                            border: 'none',
                                            backgroundColor: '#060606',
                                            color: 'white',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Upload
                                    </button>

                                    <button
                                        className="g-add"
                                        type="button"
                                        style={{
                                            padding: '10px 20px',
                                            fontSize: '16px',
                                            border: '1px solid #ddd',
                                            backgroundColor: 'white',
                                            color: '#060606',
                                            borderRadius: '4px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={handleDownloadClick}
                                    >
                                        Download template
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default UploadDataPage;

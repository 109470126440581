import {combineReducers} from "redux";
import {shop} from './brand/reducer';
import {delivery} from './delivery/reducer';
import {header} from './header/reducer';
import {cart_favorite} from './cart_and_favorite/reducer';

export default combineReducers({
    shop,
    delivery,
    cart_favorite,
    header
})

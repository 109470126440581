import React, { useState, useEffect } from 'react';
import API from "../adminAPI";

import Layout from "../adminComponents/Layout"

import "../adminComponents/style/orders.scss"

import "../adminComponents/style/requests.scss"

const SubscriptionsPage = () => {

    const [requestsData, setRequestsData] = useState([]);
    const [showCount, setShowCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [leftCount, setLeftCount] = useState(0);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        API.get(`/admin/mailing_users`, {params: {per_page: 20 + showCount}})
            .then(res => {
                setTotalCount(res.data.total);
                setLeftCount(res.data.total - res.data.emails.length);
                setLoading(false);

                setRequestsData(res.data.emails)
            })
    }, [showCount]);

    const showMore = () => {
        if(totalCount / leftCount < 1) {
            setShowCount(totalCount)
        } else if(totalCount / leftCount > 1) {
            setShowCount(prevState => prevState + 20)
        }
    };

    return (
        <Layout>
            <section className="g-sheets requests">
                <div className="g-sheets__wrapper container">
                    <table className="g-sheets-table" style={{width:"900px", display:"table"}}>
                        <thead className="g-sheets-table__head">
                        <tr className="g-sheets-table__tr">
                            <th className="g-sheets-table__th">E-mail</th>
                            <th className="g-sheets-table__th">Дата підписки</th>
                        </tr>
                        </thead>

                        <tbody className="g-sheets-table__body">
                        {requestsData.map((item, index) =>
                            <tr className="g-sheets-table__tr" key={index}>
                                <td className="g-sheets-table__td">{item.email}</td>
                                <td className="g-sheets-table__td">{item.date.split(" ")[0]} <span className="g-sheets-table__span">{item.date.split(" ")[1]}</span></td>
                            </tr>
                        )}
                        </tbody>
                    </table>

                    {leftCount ? <button className="g-add" onClick={!loading ? showMore : null}>Показать ещё</button> : null}
                </div>
            </section>
        </Layout>
    );
};

export default SubscriptionsPage

import React, {useState} from 'react';
import Slider from "react-slick";
import EveryGoods from "./EveryGoods";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import ChangedConfirmedPopup from "./ChangedConfirmedPopup";

export default function PreviewGoodsSlider({goods, settings, title}) {
    const [changingConfirmed, setChangingConfirmed] = useState(false);
    const [successText, setSuccessText] = useState(null);

    let changeContinuePopup = (text = "Changes saved successfully") => {
        setChangingConfirmed(state => !state)
        setSuccessText(text)
    }

    return (
        <>
            {title ?
                <div className={'wrapper_title'}>
                    <span>
                        {title}
                    </span>
                </div>
                : null}
            <div className={'preview_category_slider'}>
                <Slider {...settings}>
                    {goods.map(item => {
                        if(item.brand && item.name !== 'testtes') {
                        return (
                            <EveryGoods key={item.product_id}
                                        changeContinuePopup={changeContinuePopup}
                                        item={item}/>
                        )}
                        return null
                    })}
                </Slider>
            </div>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
        </>
    )
}

import React, {useState, useEffect} from "react";
import Layout from "../adminComponents/Layout"
import API from "../adminAPI";
import IconArrowDown from "../assets/g-icon-arrow_down.svg"

import "../adminComponents/style/prices_and_sales.css"
import "../adminComponents/style/sales.scss"

const SalesPage = () => {

    const [salesData, setSalesData] = useState([]);
    // eslint-disable-next-line
    const [currency, setCurrency] = useState({
        currency: false,
        id: null
    });
    const [caterotyOpen, setCategoryOpen] = useState({
        id: null
    });

    const [categoryArray, setCategoryArray] = useState([]);


    useEffect(() => {
        let data = [];
        API.get(`/admin/categories`)
            .then(res => {
                data = res.data.categories;
                data.forEach(item => {
                    item.inc = false;
                    item.currency = '%';
                    item.amount = null;
                    item.subcategories.forEach((sub) => {
                        sub.inc = false;
                        sub.currency = '%';
                        sub.amount = null;
                    })
                });
                setSalesData(data)
            });
    }, []);

    const handleSubmit = (e, type) => {
        API.post(`/admin/prices_and_sales`, {
            [type]: {
                "id": e.id,
                "change": e.inc ? e.amount ? Number(e.amount) : 0 : e.amount ? Number(-1 * e.amount) : 0,
                "currency": e.currency === "%" ? "percent" : "euro"
            }
        })
            .catch(e  => console.log(e))
    };

    let changeCategoryInput = (event, id, pre_category_id) => {
        let newArr = salesData;
        newArr.forEach(item => {
            if (item.id === id) {
                if (!pre_category_id) {
                    item.amount = event.target.value
                } else {
                    item.subcategories.forEach(sub => {
                        if (sub.id === pre_category_id){
                            sub.amount = event.target.value
                        }
                    })
                }
            }
        })
        setSalesData([...newArr])
    };

    return (
        <Layout>
            <section className="sales">
                <div className="sales__wrapper container">
                    <h2 className="g-title">Цены и скидки</h2>

                    <div className={"prices_wrapper"}>
                        {salesData.map(category => {
                            return (
                                <div key={category.id}>
                                    <div className={"prices_category_wrapper"}>
                                        <div onClick={() => {
                                            if (caterotyOpen.id === category.id) {
                                                setCategoryOpen({id: null})
                                            } else {
                                                setCategoryOpen({id: category.id})
                                            }
                                        }} className={"category_name"}>
                                            <img className="g-select-header__arrow" src={IconArrowDown}
                                                 alt="Категорія"/>
                                            <span>{category.name}</span>
                                        </div>
                                        <div className={"prices_properties"}>
                                            <div onClick={() => {
                                                category.inc = !category.inc;
                                                setCategoryArray([...categoryArray])

                                            }} className={"increment"}>
                                                <span>{category.inc ? "Увеличить" : "Уменьшить"}</span>
                                                <img className="g-select-header__arrow" src={IconArrowDown}
                                                     alt="Категорія"/>
                                                <span>на</span>
                                            </div>
                                            <input placeholder={"0"} value={category.amount || ''}
                                                   onChange={(e) => {
                                                       changeCategoryInput(e, category.id)
                                                   }}/>
                                            <div>
                                                <div onClick={() => {
                                                    if (category.currency === "%") {
                                                        category.currency = "€";
                                                        setCategoryArray([...categoryArray])
                                                    } else {
                                                        category.currency = "%";
                                                        setCategoryArray([...categoryArray])

                                                    }
                                                }} className={"currency_container"}>
                                                    <span>{category.currency}</span>
                                                    <img className="g-select-header__arrow" src={IconArrowDown}
                                                         alt="Категорія"/>
                                                </div>
                                            </div>
                                            <button onClick={() => handleSubmit(category, "category")}>Применить
                                            </button>
                                        </div>
                                    </div>
                                    {caterotyOpen.id === category.id ?
                                        category.subcategories.map(subcategory => {
                                            return (
                                                <div className={"subcategory_wrapper"} key={subcategory.id}>
                                                    <div className={"subcategory_name"}>
                                                        <span>{subcategory.name}</span>
                                                    </div>
                                                    <div className={"prices_properties"}>
                                                        <div onClick={() => {
                                                            subcategory.inc = !subcategory.inc;
                                                            setCategoryArray([...categoryArray])

                                                        }} className={"increment"}>
                                                            <span>{subcategory.inc ? "Увеличить" : "Уменьшить"}</span>
                                                            <img className="g-select-header__arrow" src={IconArrowDown}
                                                                 alt="Категорія"/>
                                                            <span>на</span>
                                                        </div>
                                                        <input placeholder={"0"}
                                                               value={subcategory.amount || ''}
                                                               onChange={(e) => {
                                                                   changeCategoryInput(e, category.id, subcategory.id)
                                                               }}/>
                                                        <div>
                                                            <div onClick={() => {
                                                                if (subcategory.currency === "%") {
                                                                    subcategory.currency = "€";
                                                                    setCategoryArray([...categoryArray])
                                                                } else {
                                                                    subcategory.currency = "%";
                                                                    setCategoryArray([...categoryArray])

                                                                }
                                                            }} className={"currency_container"}>
                                                                <span>{subcategory.currency}</span>
                                                                <img className="g-select-header__arrow"
                                                                     src={IconArrowDown} alt="Категорія"/>
                                                            </div>
                                                            <div
                                                                style={{display: currency.id === category.id ? "block" : "none"}}
                                                                className={"change_currency_dropdown"}>€
                                                            </div>
                                                        </div>
                                                        <button
                                                            onClick={() => handleSubmit(subcategory, "subcategory")}>Применить
                                                        </button>
                                                    </div>
                                                </div>)
                                        })
                                        : null}
                                </div>
                            )

                        })}
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default SalesPage

import React from 'react'
import './styles/checkbox.css';

export default function CheckBox({value, handleChange, name, text, error}) {
    return (
        <div className={'wrapper_check_box'}>
            <label htmlFor={name}
                   onClick={handleChange}
                   className={value ? 'checkbox_active' : 'checkbox_un_active'}
                   id={error ? 'text_error' : null}>
                <span/>
                <div>{text}</div>
            </label>
        </div>
    )
}

import React, {useEffect} from 'react';
import PageTitle from "../../Components/PageTitle";
import NavigationNavBar from '../../Components/NavigationNavBar'
import {Navigation} from "../../Constants/Navigations";
import './style/user_profile.css'
import {Switch, Route} from "react-router-dom";
import {ScrollToTop} from '../../Constants/ScrollToTop'

const UserProfile = React.memo(() => {

    useEffect(() => {
        ScrollToTop();
    }, []);

    return (
        <div className={'wrapper_main_page'}>
            <PageTitle title={'Personal cabinet'} logout/>
            <div className={'wrapper_user_profile'}>
                <NavigationNavBar routes={Navigation.profileNavigations}/>
                <SwitcherNavBar routes={Navigation.profileNavigations}/>
            </div>
        </div>
    )
});

function SwitcherNavBar({routes}) {
    return (
        <Switch>
            {routes.map((route, index) => (
                <Route path={route.pathname}
                       exact
                       component={route.main}
                       key={index}/>
            ))}
        </Switch>
    )
}

export default UserProfile;

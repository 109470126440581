import React, {useEffect, useState} from 'react'
import LeaveARequest from '../Components/LeaveARequest'
import PreviewCategorySectionSlider from '../Components/PreviewCategorySectionSlider'
import PreviewSectionCategory from '../Components/PreviewSectionCategory'
import {ScrollToTop} from "../Constants/ScrollToTop"
import preview_image_DELETE from '../assets/preview_image_DELETE.png'
import preview_image_DELETE2 from '../assets/preview_image_DELETE2.png'
import preview_image_DELETE3 from '../assets/preview_image_DELETE3.png'
import preview_image_DELETE4 from '../assets/preview_image_DELETE4.png'
import preview_image_DELETE5 from '../assets/preview_image_DELETE5.png'
import all_categories1 from '../assets/delux01.png'
import all_categories2 from '../assets/all_categories2.png'
import all_categories3 from '../assets/delux02.png'
import all_categories4 from '../assets/delux03.png'
import all_categories5 from '../assets/all_categories5.png'
import how_we_work1 from '../assets/how_we_work1.svg'
import how_we_work2 from '../assets/how_we_work2.svg'
import how_we_work3 from '../assets/how_we_work3.svg'
import logo from '../assets/Interiors.png'
import './styles/landing.css';
import API from "../API";
import {BrandsSlider} from "../Components/BrandsSlider";
import {Helmet} from "react-helmet";

let how_we_work_object = [
    {
        title: 'Interior design',
        image: how_we_work1,
        description: 'We are a design team. We develop design projects.'
    },
    {
        title: 'Purchase and delivery',
        image: how_we_work2,
        description: 'We select furniture, equipment and other decorative elements. We buy, we deliver.'
    },
    {
        title: 'Installation and debugging',
        image: how_we_work3,
        description: 'Our specialists collect all the furniture and arrange interior items according to the agreed design project'
    },
];

const settings = {
    speed: 400,
    slidesToShow: 8,
    infinite: false,
    centerMode: false,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1350,
            settings: {
                slidesToShow: 8,
            }
        }, {
            breakpoint: 900,
            settings: {
                slidesToShow: 6,
            }
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 3,
            }
        },{
            breakpoint: 450,
            centerMode: true,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
};

const Landing = React.memo(() => {
    const [newItems, setNewItems] = useState(null);
    const [popularItems, setPopularItems] = useState(null);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        ScrollToTop();
        setUrl(window.location.href)

        API.get('/new_products?limit=15')
            .then(res => {
                setNewItems(res.data.products)
            });

        API.get('/popular_products?limit=15')
            .then(res => {
                setPopularItems(res.data.products)
            })
    }, []);

    let howWeWork = () => {
        return (
            <div className={'wrapper_how_we_work'}>
                {how_we_work_object.map(item => {
                    return (
                        <div className={'every_block'} key={item.title}>
                            <div className={'wrapper_image'}>
                                <img src={item.image} alt={item.title}/>
                            </div>
                            <div className={'wrapper_title'}>
                                <span className={'playfair_display_400'}>{item.title}</span>
                            </div>
                            <div className={'wrapper_desc'}>
                                <span className={'open_sans_300'}>{item.description}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    let allCategory = () => {
        return (
            <div className={'wrapper_all_categories_landing'}>
                <div className={'title_preview_category_wrapper'}>
                    <span className={'playfair_display_400'}>
                        Categories
                    </span>
                    {/*<div className={'link_to_all_categories wrapper_submit_request'}>*/}
                    {/*    <Link to={'/brands'} className={'link'}>*/}
                    {/*        See all*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                </div>
                <div className={'wrapper_all_categories_block'}>
                    <PreviewSectionCategory wrapper_class_theme={'dark'}
                                            categoryName={'Furniture'}
                                            image={all_categories1}
                                            categoryDesc={'Over 10,000 categories'}
                                            link={'category_1'}/>
                    <PreviewSectionCategory wrapper_class_theme={'light'}
                                            categoryName={'Kitchen'}
                                            image={all_categories2}
                                            link={'/category_8'}
                                            categoryDesc={'Over 10,000 categories'}/>
                    <PreviewSectionCategory wrapper_class_theme={'dark'}
                                            categoryName={'Street furniture'}
                                            image={all_categories3}
                                            link={"category_2"}
                                            categoryDesc={'Over 10,000 categories'}/>
                    <PreviewSectionCategory wrapper_class_theme={'light'}
                                            categoryName={'Lighting'}
                                            image={all_categories5}
                                            link={"category_9"}
                                            categoryDesc={'Over 10,000 categories'}/>
                    <PreviewSectionCategory wrapper_class_theme={'dark'}
                                            categoryName={'Accessories'}
                                            image={all_categories4}
                                            link={"category_5"}
                                            categoryDesc={'Over 5,000 categories'}/>

                </div>
            </div>
        )
    };

    return (
        <div className={'wrapper_main_page'}>
            <Helmet>
                <meta property="og:title" content={'Vinteriors store - Your comfort matters.'}/>
                <title>Vinteriors store - Your comfort matters.</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="og:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:title" content={'Vinteriors store - Your comfort matters.'}/>
                <meta property="twitter:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <PreviewSectionCategory wrapper_class_theme={'dark'}
                                    className={'wrapper_landing_banner'}
                                    categoryName={'Create a dream interior'}
                                    image={preview_image_DELETE3}
                                    link={"/category_1"}
                                    categoryDesc={'Vinteriors presents the best author\'s design projects, premium furniture and home accessories.'}/>
            <div className={'wrapper_banners'}>
                <PreviewSectionCategory wrapper_class_theme={'dark'}
                                        categoryName={'Comfortable chairs'}
                                        image={preview_image_DELETE4}
                                        link={"/category_1"}
                                        categoryDesc={'Bestsellers and exclusive furniture to satisfy the most demanding'}/>
                <PreviewSectionCategory wrapper_class_theme={'light'}
                                        categoryName={'Luxury tables'}
                                        image={preview_image_DELETE5}
                                        link={"/category_8"}
                                        categoryDesc={'Our assortment includes more than 300 brands of furniture'}/>
            </div>
            <BrandsSlider settings={settings}/>
            {howWeWork()}
            <PreviewSectionCategory wrapper_class_theme={'dark'}
                                    categoryName={'Vinteriors'}
                                    image={preview_image_DELETE2}
                                    link={"/category_1"}
                                    categoryDesc={'Your home. Full construction'}/>
            {newItems ? <PreviewCategorySectionSlider title={'New items'}
                                                      goods={newItems}
                                                      catalogLink={'/catalogue/new_products'}/>
                : null}
            {allCategory()}
            <PreviewSectionCategory wrapper_class_theme={'light'}
                                    categoryName={'Furniture'}
                                    image={preview_image_DELETE}
                                    link={"/category_1"}
                                    categoryDesc={'Famous brands. To order.'}/>
            {popularItems ? <PreviewCategorySectionSlider title={'Best sellers'}
                                                      goods={popularItems}
                                                      catalogLink={'/catalogue/popular_products'}/> : null}
            <LeaveARequest/>
        </div>
    )
});

export default Landing

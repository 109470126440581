import React, {useState} from 'react'
import three_wise_monkeys_sculptures_set from '../assets/three_wise_monkeys_sculptures_set.png'
import './styles/leaveARequest.css';
import Input from "./Input";
import CheckBox from "./CheckBox";
import CustomPopup from "./CustomPopup";
import API from "../API";

export default function LeaveARequest() {
    const [contactRequestPopup, changeContactRequestPopup] = useState()
    const [formInputsName, changeFormInputsName] = useState({
        value: '',
        error: false
    });
    const [formInputsPhone, changeFormInputsPhone] = useState({
        value: '',
        error: false
    });
    const [agreeProcess, changeAgreeProcess] = useState({
        value: false,
        error: false
    });


    const resetState = (stateChanger, bool) => {
        stateChanger({
            value: bool ? false : '',
            error: false
        })
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (formInputsName.value && formInputsPhone.value.length >= 10 && agreeProcess.value) {
            resetState(changeFormInputsName);
            resetState(changeFormInputsPhone);
            resetState(changeAgreeProcess, true);
            API.post('question',{name: formInputsName.value, phone: formInputsPhone.value})
                .then(() => {})
                .catch(er => console.log(er))
            changeContactRequestPopup(true)
        } else {
            if (!formInputsName.value) {
                changeFormInputsName({...formInputsName, error: true})
            }
            if (formInputsPhone.value.length < 10) {
                changeFormInputsPhone({...formInputsPhone, error: true})
            }
            if (!agreeProcess.value) {
                changeAgreeProcess({...agreeProcess, error: true})
            }
        }
    };

    const handleChange = (target, input_name) => {
        let regExp = /^\+?(?:[\d]*)$/;
        if (input_name === 'phone_number' && !regExp.test(target.value)) {
            return
        } else if (input_name === 'phone_number') {
            changeFormInputsPhone({...formInputsPhone, value: target.value, error: false})
        }
        if (input_name === 'name') {
            changeFormInputsName({...formInputsName, value: target.value, error: false})
        }
    };

    const everyInput = () => {
        return (
            <div className={'wrapper_every_input'}>
                <Input type={"text"}
                       value={formInputsName.value}
                       placeholder={'Your name'}
                       className={formInputsName.error ? 'input_error' : null}
                       handleChange={({target}) => handleChange(target, 'name')}/>
                <Input type={"text"}
                       value={formInputsPhone.value}
                       placeholder={'Your phone number'}
                       className={formInputsPhone.error ? 'input_error' : null}
                       handleChange={({target}) => handleChange(target, 'phone_number')}/>
            </div>
        )
    };

    return (
        <div className={'wrapper_leave_a_request'}>
            {contactRequestPopup ? <CustomPopup handleClosePopup={changeContactRequestPopup}/> : null}
            <div className={'wrapper_title'}>
                    <span className={'playfair_display_400'}>
                        Still have questions?
                    </span>
            </div>
            <div className={'wrapper_description'}>
                    <span className={'open_sans_300'}>
                       Leave a request and we will call you back
                    </span>
            </div>
            <form onSubmit={handleSubmit}>
                {everyInput()}
                <div className={'wrapper_submit_request'}>
                    <button type={'submit'}>Send request</button>
                </div>
                <CheckBox text={'I agree to the processing of my data'}
                          handleChange={() => changeAgreeProcess(
                              {
                                  ...agreeProcess,
                                  value: !agreeProcess.value,
                                  error: false
                              }
                          )}
                          name={'agreeProcess'}
                          error={agreeProcess.error}
                          value={agreeProcess.value}/>
            </form>
            <div className={'wrapper_leave_a_request_img'}>
                <img src={three_wise_monkeys_sculptures_set} alt="three_wise_monkeys_sculptures_set"/>
            </div>
        </div>
    )
}

import React from 'react';
import {NavLink} from "react-router-dom";
import './styles/navigation_navbar.css'


export default function NavigationNavBar({routes}) {
    return (
        <div className={'wrapper_navigation_navbar'}>
            {routes.map(item => {
                return (
                    <OldSchoolMenuLink pathname={item.pathname}
                                       label={item.label}
                                       key={item.label}/>
                )
            })}
        </div>
    )
}

function OldSchoolMenuLink({label, pathname}) {
    return (
        <NavLink activeClassName={'nav_bar_active_link'}
                 key={pathname}
                 className={'playfair_display_400'}
                 to={pathname}>
            {label}
        </NavLink>
    );
}

import React, {useEffect, useState} from 'react';
import './styles/TextInput.css'

import showPass from '../assets/show_password.svg'

function TextInput(props) {
    const [disabled, setDisabled] = useState(false)
    const [required, setRequired] = useState(false)
    const [activePass, setActivePass] = useState(false)

    useEffect(() => {
        if (props.disabled !== undefined && props.disabled !== null) {
            setDisabled(props.disabled)
        }
        if (props.required !== undefined && props.required !== null) {
            setRequired(props.required)
        }
    }, [props.disabled, props.required])

    return (
        <div style={props.margin ? {margin: props.margin} : null} className={'input_component'}>
            {props.title ? <span style={disabled ? {color: '#8E90A6'} : null}>
                 {props.title} </span> : null}
            <label
                className={props.goods ? props.errorModal ? 'unique_goods_order_popup_content_error' : 'unique_goods_order_popup_content_label' : ''}>
                <input
                    style={props.showPass ? {padding: '12px 35px 12px 15px'} : props.style ? props.style : props.width ? {width: `${props.width}`} : null}
                    disabled={disabled}
                    required={required}
                    id={props.id ? props.id : null}
                    maxLength={props.maxLength ?? 80}
                    name={props.name ? props.name : ''}
                    placeholder={props.placeholder ? props.placeholder : null}
                    className={props.error ? 'error custom_input' : 'custom_input'}
                    type={activePass ? 'text' : props.type ? props.type : 'text'}
                    value={props.value ? props.value : ''}
                    onChange={props.onChange ? e => {
                        props.onChange(e)
                    } : null}
                />
                {props.showPass ?
                    <img src={showPass}
                         className={'show_pass_icon'}
                         alt={'show pass'}
                         onClick={() => {
                             setActivePass(!activePass)
                         }}
                    /> : null}
            </label>
            {props.error ? <span style={props.error ? {color: '#EB5757', margin: '0', textTransform: 'none'} : null}>
                {props.errTitle ? props.errTitle : ''}</span> : null}
        </div>
    );
}

export default TextInput;

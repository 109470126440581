import React from "react";
import {connect} from "react-redux";
import arper from '../assets/list_brands/arper.svg'
import bb_italia from '../assets/list_brands/bb_italia.svg'
import bonaldo from '../assets/list_brands/bonaldo.svg'
import cappellini from '../assets/list_brands/cappellini.svg'
import cassina from '../assets/list_brands/cassina.svg'
import davide_groppi from '../assets/list_brands/davide_groppi.svg'
import edra from '../assets/list_brands/edra.svg'
import fiam from '../assets/list_brands/fiam.svg'

import logo_arper from '../assets/logo_brands/logo_arper.svg'
import logo_bb_italia from '../assets/logo_brands/logo_bb_italia.svg'
import logo_bonaldo from '../assets/logo_brands/logo_bonaldo.svg'
import logo_cappellini from '../assets/logo_brands/logo_cappellini.svg'
import logo_cassina from '../assets/logo_brands/logo_cassina.svg'
import logo_davide_groppi from '../assets/logo_brands/logo_davide_groppi.svg'
import logo_edra from '../assets/logo_brands/logo_edra.svg'
import logo_fiam from '../assets/logo_brands/logo_fiam.svg'

function EveryBrands() {
    return (
        <div className={'wrapper_all_brands'}>
            <div className={'wrapper_every_brands'}>
                <div className={'wrapper_preview_section'}>
                    <div className={'wrapper_preview'}>
                        <div className={'wrapper_title'}>
                            <span className={'apper_title'}>
                                <img src={logo_arper} alt={"logo_arper"}/>
                            </span>
                        </div>
                        <div className={'wrapper_desc'}>
                            <span className={'apper_content'}>{"Monastier di Treviso / Италия"}</span>
                        </div>
                    </div>
                    <div className={'wrapper_preview_apper_brands_image'}
                         style={{position: "absolute", height: "247.03px", right: "0"}}>
                        <img src={arper} alt={"arper"}/>
                    </div>
                </div>
            </div>

            <div className={'wrapper_every_brands'}>
                <div className={'wrapper_preview_section'}>
                    <div className={'wrapper_preview'}>
                        <div className={'wrapper_title'}>
                            <span className={'bb_italia_title'}>
                                <img src={logo_bb_italia} alt={"logo_bb_italia"}/>
                            </span>
                        </div>
                        <div className={'wrapper_desc'}>
                            <span className={'bb_italia_content'}>{"Novedrate / Italy"}</span>
                        </div>
                    </div>
                    <div className={'wrapper_preview_bb_italia_brands_image'}
                         style={{position: "absolute", height: "247.03px", right: "0"}}>
                        <img src={bb_italia} alt={"bb_italia"}/>
                    </div>
                </div>
            </div>

            <div className={'wrapper_every_brands'}>
                <div className={'wrapper_preview_section'}>
                    <div className={'wrapper_preview'}>
                        <div className={'wrapper_title'}>
                            <span className={'bonaldo_title'}>
                                <img src={logo_bonaldo} alt={"logo_bonaldo"}/>
                            </span>
                        </div>
                        <div className={'wrapper_desc'}>
                            <span className={'bonaldo_content'}>{"Villanova di Camposampiero / Italy"}</span>
                        </div>
                    </div>
                    <div className={'wrapper_preview_bonaldo_brands_image'}
                         style={{position: "absolute", height: "247.03px", right: "0"}}>
                        <img src={bonaldo} alt={"bonaldo"}/>
                    </div>
                </div>
            </div>

            <div className={'wrapper_every_brands'}>
                <div className={'wrapper_preview_section'}>
                    <div className={'wrapper_preview'}>
                        <div className={'wrapper_title'}>
                            <span className={'cappellini_title'}>
                                <img src={logo_cappellini} alt={"logo_cappellini"}/>
                            </span>
                        </div>
                        <div className={'wrapper_desc'}>
                            <span className={'cappellini_content'}>{"Каруго / Italy"}</span>
                        </div>
                    </div>
                    <div className={'wrapper_preview_cappellini_brands_image'}
                         style={{position: "absolute", height: "247.03px", right: "0"}}>
                        <img src={cappellini} alt={"cappellini"}/>
                    </div>
                </div>
            </div>

            <div className={'wrapper_every_brands'}>
                <div className={'wrapper_preview_section'}>
                    <div className={'wrapper_preview'}>
                        <div className={'wrapper_title'}>
                            <span className={'cassina_title'}>
                                <img src={logo_cassina} alt={"logo_cassina"}/>
                            </span>
                        </div>
                        <div className={'wrapper_desc'}>
                            <span className={'cassina_content'}>{"Meda / Italy"}</span>
                        </div>
                    </div>
                    <div className={'wrapper_preview_cassina_brands_image'}
                         style={{position: "absolute", height: "247.03px", right: "0"}}>
                        <img src={cassina} alt={"cassina"}/>
                    </div>
                </div>
            </div>

            <div className={'wrapper_every_brands'}>
                <div className={'wrapper_preview_section'}>
                    <div className={'wrapper_preview'}>
                        <div className={'wrapper_title'}>
                            <span className={'davide_groppi_title'}>
                                <img src={logo_davide_groppi} alt={"logo_davide_groppi"}/>
                            </span>
                        </div>
                        <div className={'wrapper_desc'}>
                            <span className={'davide_groppi_content'}>{"Piacenza / Italy"}</span>
                        </div>
                    </div>
                    <div className={'wrapper_preview_davide_groppi_brands_image'}
                         style={{position: "absolute", height: "247.03px"}}>
                        <img src={davide_groppi} alt={"davide_groppi"}/>
                    </div>
                </div>
            </div>

            <div className={'wrapper_every_brands'}>
                <div className={'wrapper_preview_section'}>
                    <div className={'wrapper_preview'}>
                        <div className={'wrapper_title'}>
                            <span className={'edra_title'}>
                                <img src={logo_edra} alt={"logo_edra"}/>
                            </span>
                        </div>
                        <div className={'wrapper_desc'}>
                            <span className={'edra_content'}>{"Toskania / Italy"}</span>
                        </div>
                    </div>
                    <div className={'wrapper_preview_edra_brands_image'}
                         style={{position: "absolute", height: "247.03px", right: "0"}}>
                        <img src={edra} alt={"edra"}/>
                    </div>
                </div>
            </div>

            <div className={'wrapper_every_brands'}>
                <div className={'wrapper_preview_section'}>
                    <div className={'wrapper_preview'}>
                        <div className={'wrapper_title'}>
                            <span className={'fiam_title'}>
                                <img src={logo_fiam} alt={"logo_fiam"}/>
                            </span>
                        </div>
                        <div className={'wrapper_desc'}>
                            <span className={'fiam_content'}>{"Tavullia / Italy"}</span>
                        </div>
                    </div>
                    <div className={'wrapper_preview_fiam_brands_image'}
                         style={{position: "absolute", height: "247.03px", right: "0"}}>
                        <img src={fiam} alt={"fiam"}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(EveryBrands)
import React, {useEffect, useState} from 'react';
import "./styles/conditions.css";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'
export default function TermsAndConditions(props) {
    const [url, setUrl] = useState(null)

    useEffect(() => {
        setUrl(window.location.href)
    }, [])

    return (
        <div className={'main_page_conditions'}>
            <Helmet>
                <meta property="og:title" content={'Сonditions — Vinteriors store.'}/>
                <title>Сonditions — Vinteriors store.</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="og:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:title" content={'Сonditions — Vinteriors store.'}/>
                <meta property="twitter:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <div className={'playfair_display_400'}>
                <h2 className={'title'}>Terms and Conditions</h2>
                <p className={'content_other'}>{'General Terms and Conditions'}</p>
                <p className={'content'}>{'The terms and conditions set out below apply to purchases made at https://vinteriors.store. Before you make a purchase at https://vinteriors.store, we recommend that you read through our terms and conditions. The right of cancellation solely applies to private customers making purchases via https://vinteriors.store.'}</p>
                <p className={'content_other'}>{'Ordering'}</p>
                <p className={'content'}>{'You can place online orders around the clock. We process orders Monday to Friday between 8 a.m. and 4 p.m. Orders made outside this period will be processed the following working day. You are responsible for the accuracy of the customer information you enter as an element of a purchase.'}</p>
                <p className={'content'}>{'When you place an order, we will initially send you an order quotation via e-mail. Please note that this quotation does not constitute an order confirmation and that, at that point in time, we shall not be bound by your order. The final agreement between you and Vinteriors.store will not be entered into until Vinteriors.store forwards an order confirmation per e-mail to confirm that the order is binding. You will receive a delivery notification via e-mail when your order is dispatched from our warehouse.'}</p>
                <p className={'content_other'}>{'Payment and delivery'}</p>
                <p className={'content'}>{'All prices are stated in EURO. All prices are inclusive of local VAT. Due to the bulk nature of the items, shipping is calculated separately. Any redelivery owing to your failure to collect your parcel from a collection point or owing to your absence at the agreed delivery time and place will occur at your expense.'}</p>
                <p className={'content'}>{'Payment of purchases made via the web shop can be made by the following means:'}</p>
                <p className={'content_other'}>{'Bank transfer'}</p>
                <p className={'content'}>{'All the items presented on the website are made to order on the factory, and payment will be withdrawn at the initiation of the manufacturing process. Vinteriors.store does not operate with payment fees. The final invoice will be forwarded per e-mail immediately before delivery of the order.'}</p>
                <p className={'content_other'}>{'Delivery'}</p>
                <p className={'content'}>{'Products ordered at Vinteriors.store  will be delivered to the countries available in the online ordering form. Should you wish for delivery to a country that does not appear in the dropdown menu, please contact us for a possible solution. Depending on the order in question, delivery will take place by courier or shipping agent. This information will be provided on the delivery note you will receive when the order has been dispatched from our warehouse.'}</p>
                <p className={'content'}>{'We strive to deliver your order as soon as possible. On average, production at the factory takes from 2 to 3 months, with the exception of December and August (vacations and holidays). In the event of a production delay or other hindrance which may affect the confirmed dispatch date, we will contact you as soon as possible.'}</p>
                <p className={'content_other'}>{'Palletisation and furniture delivery'}</p>
                <p className={'content'}>{'When ordering furniture or larger products, they will be shipped with the transport company to the curbside of your delivery address. All customers will be contacted regarding the date of delivery. Please make sure to be home at the address upon delivery. If you happen not to be home at the agreed time of delivery, your item might be returned to the warehouse. In that case, please contact us for further assistance.'}</p>
                <p className={'content'}>{'For deliveries through a shipping agent, a legally competent recipient granted power of attorney by you must be present to sign for the receipt of the product(s). Upon receipt of your order, it is important that you check that you have received the correct product(s) and inspect them for potential damage. If everything is as anticipated, the shipping agent will ask you to sign for the delivery.'}</p>
                <p className={'content'}>{'If the packaging is damaged or shows other visible signs of defects, your signature on the bill of carriage shall be qualified (i.e. acceptance for receipt only) subject to reservation in respect of potential damage to the contents. You may refuse to accept the delivery and ask the driver to return it.'}</p>
                <p className={'content'}>{'If, after unpacking, a product is damaged or defective, please contact our sales support at request@vinteriors.store  to find a solution. Due to verification procedures, you should contact our sales support no later than five days after receipt. This does not affect your statutory rights under the EU Sale of Goods Act.'}</p>
                <p className={'content_other'}>{'Complaints'}</p>
                <p className={'content'}>{'If you purchase a product that is defective, e.g. faulty material or manufacturing defect, the EU Sale of Goods Act shall apply. You are entitled to complain about product defects within a period of 24 months from the date of delivery. You can do so by contacting us via e-mail at request@vinteriors.store. Please provide as much detail and documentation as possible.'}</p>
                <p className={'content'}>{'Normal wear and tear as well as wear and tear/defect caused by incorrect care or inappropriate use of the product (ii) wear and tear/defects caused by direct sunlight and heat from e.g. radiators that may fade or dry out the surface on woods, fabrics and leathers (iii) defects in or caused by material, leather or textiles. Any case of subsequent changes to the product will invalidate the extended warranty.'}</p>
                <p className={'content'}>{'A claim in relation to the Extended Warranty shall include a description of the defect and be accompanied by the necessary and relevant documentation, including information on the order number and photos documenting the defect. All claims shall be sent by e-mail to Vinteriors.store support team at: request@vinteriors.store.'}</p>
                <p className={'content_other'}>{'Reservations'}</p>
                <p className={'content'}>{'We can neither be held responsible for incorrectly stated prices, misprints, out-of-stock products and force majeure, nor for suppliers’ delivery failure. Despite taking great care to ensure our indications of colors and qualities are as accurate as possible in, we cannot guarantee that your screen will give a correct representation of colors, owing to differences in screen resolutions etc. '}</p>
                <p className={'content_other'}>{'Right of cancellation'}</p>
                <p className={'content'}>{'The right of cancellation period expires two weeks after the day on which you or another person selected by you (not the shipping agent) make an order. If the date of expiry is a non-business day (meaning a day on which the banks are generally closed for the public for ordinary banking business), the period of expiry shall be extended to the following business day. There is no right of cancellation for the purchase of products made to order. '}</p>
                <p className={'content'}>{'Prices on the website are not a public offer, the exact cost of the product depends on the configuration of the product and its size, delivery is not included in the price and is calculated separately. Delivery is carried out by a transport company at street level, lifting is not included in the price. Responsibility for the safety of cargo during transportation lies entirely with the transport company.'}</p>
                <p className={'content'}>{'Please ensure your made-to-order item fits into your home by measuring your space before placing your order.'}</p>
                <p className={'content'}>{'You cannot withdraw from the agreement by refusing receipt of the product or by failing to collect it without having first notified us of your wish to cancel.'}</p>
                <p className={'content_other'}>{'Returns'}</p>
                <p className={'content'}>{'If you would like to return a product, you must return the product to us within 30 days of receipt. The cost of returning your order will not be covered by Vinteriors.store. You will be held liable for any damage that may occur in transit.'}</p>
                <p className={'content'}>{'Products must be returned in their original packaging and in its original condition. If you do not have the original packaging, this may lead to a possible decrease in the value of the product.'}</p>
                <p className={'content_other'}>{'Refund of the purchase amount'}</p>
                <p className={'content'}>{'If you regret your purchase, you are entitled to a refund. Any used or damaged products that are not reported as a claim will not be refunded. No later than two weeks from the date on which we have received your return, we shall refund all payments received from you, including shipping costs (albeit excluding additional delivery costs for transport other than the cheapest standard delivery provided by us).'}</p>
                <p className={'content'}>{'Unless otherwise agreed, we shall refund the amount to the original payment method used. Return payments are not subject to fees. We are entitled to hold back payment until we have received the product unless you can provide us with documentation of having returned the product.'}</p>
                <p className={'content_other'}>{'Applicable law and venue'}</p>
                <p className={'content'}>{'Products purchased at Vinteriors.store are subject to the law of Poland. Any dispute shall be settled by a Polish court.'}</p>
                <p className={'content'}>{'Please read the below T&C carefully as they explain a number of important things and set out our respective rights and obligations. In these Booking T&C references to "you" and "your" include the first named person on the reservation and all persons on whose behalf a reservation is made.'}</p>
                <p className={'content'}>{'The Site is owned and operated by Vinteriors.store, Adress: Poland, Warsaw, ul. Ostrobramska 101 (“we”, “us”, “our”) and when you make an order, your order is with us.'}</p>
                <p className={'content'}>{'By making an order, the first named person on the reservation agrees on behalf of all persons detailed on the reservation that:'}</p>
                <p className={'content'}>{'— He/she has read these Booking T&C and has the authority to and does agree to be bound by them;'}</p>
                <p className={'content'}>{'— He/she consents to our use of information in accordance with our Privacy Policy;'}</p>
                <p className={'content'}>{'— He/she is over 18 years of age and where placing an order for services with age restrictions declares that he/she and all members of the party are of the appropriate age to purchase those services; and'}</p>
                <p className={'content'}>{'— He/she accepts financial responsibility for payment of the reservation on behalf of all persons detailed on the reservation.'}</p>
            </div>
        </div>
    )
}

import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Routers from "./Pages/Routers";
import {forceLogout} from "./API";
import helper from "./helper";

const PrivateRoute = ({component: Component, ...rest}) => {
    let responseComponent;
    const userToken = localStorage.getItem(helper.JWT_TOKEN);

    if (userToken) {
        responseComponent = props => (<Routers {...props} />);
    } else {
        responseComponent = props => (<Redirect to="/"/>);
        forceLogout()
    }

    return <Route {...rest} render={responseComponent}/>;
};

export default PrivateRoute;

import {
    CHANGE_ACTIVE_BRAND
} from "./actions";

const InitialState = {
    activeBrand: null,
};

export const shop = (state = InitialState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case CHANGE_ACTIVE_BRAND:
            return {...state, activeBrand: action.payload};
    }
    return state;
};

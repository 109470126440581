import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

const PreviewSectionCategory = ({className, wrapper_class_theme, image, categoryName, categoryDesc, link, hideButton, linkText, style, mobileImg}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500)

    useEffect(() => {
        window.addEventListener('resize', () => {
            const {innerWidth} = window

            if (innerWidth < 500) {
                setIsMobile(true)
            } else if (innerWidth > 500) {
                setIsMobile(false)
            }
        })
    }, [])

    return (
        <div className={wrapper_class_theme === 'light' ?
            `wrapper_preview_section_light wrapper_preview_section ${className ? className : ''}` :
            `wrapper_preview_section_dark wrapper_preview_section ${className ? className : ''}`}>
            <div className={'wrapper_preview_info'}>
                <div className={'wrapper_title'}>
                    <span className={'playfair_display_400'}>{categoryName}</span>
                </div>
                <div className={'wrapper_desc'}>
                    <span className={'open_sans_300'}>{categoryDesc}</span>
                </div>
                {hideButton ?
                    null :
                    <div className={'wrapper_submit_request preview_category_link'}>
                        <Link to={link || '/'} className={'link'} style={{display: 'inline'}}>
                            {linkText ? linkText : 'Go to catalog'}
                        </Link>
                    </div>}
            </div>
            <div className={'wrapper_preview_image'}>
                <img src={isMobile && mobileImg ? mobileImg : image} alt={categoryName} style={style}/>
            </div>
        </div>
    )
};
export default PreviewSectionCategory

import React, {useEffect} from "react"
import Sidenav from "../adminComponents/Sidenav"

import useMediaQuery from "./useMediaQuery"
import helper from "../helper";

const Layout = ({children}) => {

    useEffect(() => {
        if (!localStorage.getItem(helper.ADMIN_TOKEN)) {
            window.location.pathname = "/admin"
        }
    }, []);

    const isMediaSmall = useMediaQuery('(max-width: 1024px)');

    return (
        <>
            <main className="main">
                {isMediaSmall ?
                    <>
                        <p className="main__error"><span className="main__error-big">На этом устройстве админ панель не доступна.</span> Пожалуйста,
                            используйте ноутбук или планшет с разрешением экрана от 1024px!</p>
                    </>
                    :
                    <>
                        <Sidenav/>
                        {children}
                    </>
                }
            </main>
        </>
    )
}

export default Layout

import React, {useState, useEffect} from "react";
import Layout from "../adminComponents/Layout"
import API from "../adminAPI";

import "../adminComponents/style/languages.scss"

import '../adminComponents/style/promocodes.css'
import DeleteIcon from "../assets/g-icon-delete.svg"
import AddIcon from "../assets/g-icon-add.svg"

const LanguagesPage = () => {
    const [editMode, setEditMode] = useState(false);
    const [options, setOptions] = useState({
        in_archive: 0,
    });

    const [bannersData, setBannersData] = useState([]);

    const InitialItem = {
        id: null,
        name: "",
        number_of_uses: "",
        sale: "",
    };

    const [bannerItem, setBannerItem] = useState(InitialItem);

    useEffect(() => {
        API.get(`/admin/promo_codes`)
            .then(res => {
                setBannersData(res.data.promo_codes);
            })
    }, []);

    const handleInputChange = e => {
        const {name, value} = e.target;
        setBannerItem({...bannerItem, [name]: value});
    };


    const handleCancel = () => {
        setEditMode(false);
        setBannerItem(InitialItem);
    }

    const handleDelete = itemId => {
        API.delete(`/admin/promo_codes?promo_code_id=${itemId}`)
            .then(() => {
                API.get(`/admin/promo_codes`)
                    .then(res => {
                        setBannerItem(InitialItem);
                        setBannersData(res.data.promo_codes);
                    })

                if (editMode) {
                    setEditMode(false);
                }
            })
    }

    const submitAdd = e => {
        e.preventDefault();
        if (!bannerItem.name) return;

        API.post(`/admin/promo_codes`, {name: bannerItem.name, number_of_uses: bannerItem.number_of_uses, sale: bannerItem.sale })
            .then(() => {
                API.get(`/admin/promo_codes`)
                    .then(res => {
                        setBannerItem(InitialItem);
                        setBannersData(res.data.promo_codes);
                    })
            })
    }


    const handleTabNew = () => {
        setOptions({...options, processed: false});

        API.get(`/admin/promo_codes`)
            .then(res => {
                setBannersData(res.data.promo_codes);
            })
    };

    const handleTabOld = () => {
        setOptions({...options, processed: true});

        API.get(`/admin/promo_codes?in_archive=1`)
            .then(res => {
                setBannersData(res.data.promo_codes);
            })
    };

    return (
        <Layout>
            <section className="languages">
                <div className="container">
                    <div className="languages-info">
                        <h2 className="languages__title g-title">Генерация промокодов</h2>

                        <div className="languages-add">
                            <form className="languages-add__form"
                                  onSubmit={submitAdd}>

                                <p className="languages-add__text">Введите промокод</p>

                                <input className="g-input" placeholder="Промокод"
                                       type="text"
                                       name="name"
                                       value={bannerItem.name}
                                       onChange={handleInputChange}/>

                                <p className="languages-add__text">Количество возможных использований</p>

                                <input className="g-input" placeholder="100"
                                       type="text"
                                       name="number_of_uses"
                                       value={bannerItem.number_of_uses}
                                       onChange={handleInputChange}/>

                                <p className="languages-add__text">Скидка в %</p>

                                <input className="g-input" placeholder="5"
                                       type="text"
                                       name="sale"
                                       value={bannerItem.sale}
                                       onChange={handleInputChange}/>

                                <div className="languages-add__block" style={{justifyContent: "flex-start"}}>
                                    {editMode ?
                                        <button className="g-add g-add_margin" type="button" onClick={handleCancel}>
                                            Скасувати
                                        </button> : null}

                                    <button className="g-add" type="submit">
                                        <img className="g-add__icon" src={AddIcon} alt="Сгенерировать"/>
                                        Сгенерировать
                                    </button>
                                </div>


                            </form>
                            <p style={{margin: "24px 0 16px", color: "black", fontSize: "24px", fontWeight: "600",lineHeight: "33px"}} className="languages-info__text">Список промокодов</p>
                            <button style={{fontSize: "18px", padding: "6px 14px", lineHeight: "25px"}}
                                    className={`orders-view__new${options.processed ? '' : ' active'}`}
                                    onClick={handleTabNew}>Активные
                            </button>
                            <button style={{fontSize: "18px", padding: "6px 14px", lineHeight: "25px"}}
                                    className={`orders-view__old${options.processed ? ' active' : ''}`}
                                    onClick={handleTabOld}>Архив
                            </button>
                        </div>

                        <div>

                            <div className={"promocode_list_wrapper"} style={{}}>
                                <span>Промокод</span>
                                <span>Скидка, %</span>
                                <span>Количество возможных использований</span>
                                <span>Использовано</span>
                            </div>

                            <div>

                            {bannersData.length > 0 && bannersData.map(bannerElement =>
                                <div className={"promocode_list"}>
                                    <span>
                                        {bannerElement.name}
                                    </span>
                                    <span>
                                        {bannerElement.sale}
                                    </span>
                                    <span>
                                        {bannerElement.number_of_uses}
                                    </span>
                                    <span>
                                        {bannerElement.used_times}
                                    </span>
                                    <div onClick={() => handleDelete(bannerElement.id)} className={"delete_icon"}>
                                        <img src={DeleteIcon} alt=""/>
                                    </div>
                                </div>
                            )}

                            </div>
                            <ul className="languages-info-list">

                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default LanguagesPage

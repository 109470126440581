import axios from 'axios'
import helper from "./helper";

let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem(helper.JWT_TOKEN)
};

let API = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/api",
    timeout: 60000,
    headers: headers
});

API.interceptors.request.use(
    request => requestHandler(request));

API.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);

const errorHandler = error => {
    console.log(error.response)
    console.log(error.request)
    if (error.response === undefined || error.response.status === 403 || error.response.status === 401) {
        // forceLogout();
        return Promise.reject({...error})
    } else if (error.response.status === 404) {
        // window.location = "/404"
        return Promise.reject({...error})
    } else {
        return Promise.reject({...error})
    }
};

const successHandler = response => response;
const requestHandler = request => request;

export function forceLogout() {
    localStorage.removeItem(helper.JWT_TOKEN);
    localStorage.removeItem(helper.USER_DATA);
    window.location = '/';
}


export default API

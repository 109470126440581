import React, {useEffect, useState} from 'react';
import "./styles/conditions.css";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'
import B2BClientsRequest from "../Components/B2BClientsRequest";

export default function B2BClientsPage(props) {
    const [url, setUrl] = useState(null)

    useEffect(() => {
        setUrl(window.location.href)
    }, [])

    return (
        <div className={'main_page_conditions'}>
            <Helmet>
                <meta property="og:title" content={'For B2B Clients — Vinteriors store.'}/>
                <title>Cookies — Vinteriors store.</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description"
                      content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="og:description"
                      content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:title" content={'For B2B Clients — Vinteriors store.'}/>
                <meta property="twitter:description"
                      content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <span className={'playfair_display_400'}>
                <h2 className={'title'}>For B2B Clients</h2>
                <p className={'content_other'}>{'Implementation for interior designers, developers and real estate agencies.'}</p>
                <p className={'content'}>{'Our company has special conditions for B2B clients. We provide a comprehensive service of supplying the best European brands of furniture, lighting, fabrics, and we can also carry out turnkey interior design for your client with the subsequent supply of everything necessary for its implementation (furniture, lighting, fabrics, plumbing). Please send your request to request@vinterest.store so that we can tell you how we work and our terms of cooperation.'}</p>
                <B2BClientsRequest/>
            </span>
        </div>
    )
}

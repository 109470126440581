import React from "react";
import Slider from "react-slick";

export default function UniqueItemsPhotoSlider({setActiveImage, photos, activeImage}) {
    const settings = {
        dots: true,
        speed: 400,
        swipeToSlide: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToScroll: 1
    };

    const settings2 = {
        slidesToScroll: 1,
        vertical: true,
        infinite: false,
        slidesToShow: 5,
        swipeToSlide: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 768,
                slidesToShow: 1,
                slidesToScroll: 1,
                vertical: false,
            }
        ]
    };

    return (
        <>
            <div className={'vertical_slider'}>
                <Slider {...settings2}>
                    {photos.map((photo, index) => {
                        return (
                            <div key={photo}
                                 onClick={() => setActiveImage(photo)}>
                                <img src={process.env.REACT_APP_IMAGE_API_URL + "/" + photo} alt="item_proto"/>
                            </div>
                        )
                    })}
                </Slider>
                <div className={'second_slider'}>
                    <img src={process.env.REACT_APP_IMAGE_API_URL + "/" + activeImage} alt="item_photo"/>
                </div>
            </div>
            <div className={'horizontal_slider'}>
                <Slider {...settings}>
                    {photos.map((photo, index) => {
                        return (
                            <div key={photo}>
                                <img src={process.env.REACT_APP_IMAGE_API_URL + "/" + photo} alt="item_proto"/>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </>
    );
}

import React, {useEffect, useRef, useState} from "react";
import './styles/unique_goods_order_popup.css'
import useOutsideClick from "../Constants/useOutsideClick";
import {bodyOverflow} from "../Constants/bodyOverflow";
import CheckBox from "./CheckBox";
import TextInput from "./TextInput";
import fAPI from "../fAPI";
import close_icon from '../assets/vector_close.svg'

export default function UniqueGoodsOrderPopup(props) {
    const [isLogged, setIsLogged] = useState(false)
    const {
        setModalState, modalState, setModalPopup,
        handlePopupData, adminConfirmModal, handleDelete
    } = props

    let ref = useRef();

    useOutsideClick(ref, () => {
        setModalPopup(false)
        bodyOverflow(false)
    });

    useEffect(() => {
        fAPI().get(`/user/profile`)
            .then(res => {
                Object.entries(res.data).forEach(i => {
                    if (modalState.hasOwnProperty(i[0]) && i[1]) {
                        setModalState(prevState => ({
                            ...prevState,
                            [i[0]]: {
                                ...prevState[i[0]],
                                value: [i[1]],
                                error: false,
                            }
                        }))
                    }
                })
                setIsLogged(true)
            })
            .catch(() => {
                setIsLogged(false)
            })
        //eslint-disable-next-line
    }, [])

    return (

        <div className={'unique_goods_order_popup'}>
            <div className={'unique_goods_order_popup_content'}>
                <img src={close_icon} onClick={() => {
                    setModalPopup(false)
                    bodyOverflow(false)
                }} className={'close-icon'} alt=""/>
                {adminConfirmModal
                    ? <div>
                        <h2>Delete request</h2>
                        <p>Are you sure you want to delete request, it cannot be undone.</p>
                        <div className={'unique_goods_order_popup_content_admin_buttons'}>
                            <button onClick={() => handleDelete()}>Удалить</button>
                            <button onClick={() => setModalPopup(false)}>Отмена</button>
                        </div>
                    </div>
                    : <form onSubmit={(e) => handlePopupData(e)}>
                        <h2>Checkout price</h2>
                        <p>Leave a request and we will send you the information</p>
                        <TextInput error={modalState.name.error}
                                   errorModal={modalState.name.error}
                                   errTitle={modalState.name.errorMessage}
                                   type={'text'}
                                   margin={'0 0 20px'}
                                   placeholder={'Name'}
                                   goods
                                   value={modalState.name.value}
                                   onChange={({target}) => setModalState(prevState => ({
                                       ...prevState,
                                       name: {
                                           ...prevState.name,
                                           value: target.value,
                                           error: false,
                                       }
                                   }))}
                        />
                        <TextInput error={modalState.phone.error}
                                   errTitle={modalState.phone.errorMessage}
                                   errorModal={modalState.phone.error}
                                   type={'text'}
                                   goods
                                   margin={'0 0 20px'}
                                   placeholder={'Phone'}
                                   value={modalState.phone.value}
                                   onChange={({target}) => {
                                       const RegEx = /^\+?(?:[\d]*)$/;
                                       if (RegEx.test(target.value) && target.value.length < 14) {
                                           setModalState(prevState => ({
                                               ...prevState,
                                               phone: {
                                                   ...prevState.phone,
                                                   value: target.value,
                                                   error: false,
                                               }
                                           }))
                                       }
                                   }}
                        />
                        <TextInput error={modalState.email.error}
                                   errTitle={modalState.email.errorMessage}
                                   errorModal={modalState.email.error}
                                   type={'text'}
                                   goods
                                   margin={'0 0 20px'}
                                   placeholder={'Email'}
                                   value={modalState.email.value}
                                   onChange={({target}) => {
                                       setModalState(prevState => ({
                                           ...prevState,
                                           email: {
                                               ...prevState.email,
                                               value: target.value,
                                               error: false,
                                           }
                                       }))
                                   }}
                        />
                        {isLogged ||
                        <>
                            <TextInput error={modalState.password.error}
                                       errTitle={modalState.password.errorMessage}
                                       errorModal={modalState.password.error}
                                       type={'password'}
                                       goods
                                       margin={'0 0 20px'}
                                       placeholder={'Password'}
                                       value={modalState.password.value}
                                       onChange={({target}) => {
                                           setModalState(prevState => ({
                                               ...prevState,
                                               password: {
                                                   ...prevState.password,
                                                   value: target.value,
                                                   error: false,
                                               }
                                           }))
                                       }}
                            />
                            <TextInput error={modalState.confirmPassword.error}
                                       errTitle={modalState.confirmPassword.errorMessage}
                                       errorModal={modalState.confirmPassword.error}
                                       type={'password'}
                                       goods
                                       margin={'0 0 20px'}
                                       placeholder={'Confirm password'}
                                       value={modalState.confirmPassword.value}
                                       onChange={({target}) => {
                                           setModalState(prevState => ({
                                               ...prevState,
                                               confirmPassword: {
                                                   ...prevState.confirmPassword,
                                                   value: target.value,
                                                   error: false,
                                               }
                                           }))
                                       }}
                            />
                        </>}
                        <button type={'submit'}>Leave a request</button>

                        <CheckBox text={'I agree to the processing of my data'}
                                  handleChange={() => setModalState(prevState => ({
                                      ...prevState,
                                      agreeProcess: {
                                          value: !modalState.agreeProcess.value,
                                          error: false
                                      }
                                  }))}
                                  name={'agreeProcess'}
                                  error={modalState.agreeProcess.error}
                                  value={modalState.agreeProcess.value}/>
                    </form>}
            </div>
        </div>
    )
}
import React, {useState, useRef, useEffect} from 'react';
import {Link} from "react-router-dom";
import Layout from "../adminComponents/Layout"
import API from "../adminAPI";

import "../adminComponents/style/categories.scss"

import EditIcon from "../assets/g-icon-edit.svg"
import DeleteIcon from "../assets/g-icon-delete.svg"
import AddIcon from "../assets/g-icon-add.svg"
import AddIconLarge from "../assets/g-icon-add_large.svg"
import SearchIcon from "../assets/g-icon-search.svg";
import {connect} from 'react-redux'

import {changeActiveBrand} from '../main-store/brand/actions'

const CategoriesPage = (props) => {
    // eslint-disable-next-line
    const [requestList, setRequestList] = useState(false);
    const [categoriesData, setCategoriesData] = useState([]);

    const [addMode, setAddMode] = useState(true);
    const [editMode, setEditMode] = useState(false);

    const [searchValue, setSearchValue] = useState('');

    const InitialItem = {
        id: null,
        name: "",
        path: ""
    };

    const [subcategoryItem, setSubcategoryItem] = useState(InitialItem);

    const ref = useRef();
    // eslint-disable-next-line
    const handleRequestListHeader = id => {
        setRequestList(false);
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setRequestList(false);
        }
    };

    useEffect(() => {
        API.get(`/admin/brand`)
            .then(res => {
                setCategoriesData(res.data.brands);
            })

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleInputChange = e => {
        const {name, value} = e.target;
        setSubcategoryItem({...subcategoryItem, [name]: value});
    };

    const setImage = e => {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            let fileBase64 = reader.result;
            setSubcategoryItem({...subcategoryItem, path: `${fileBase64}`});
        };
    };

    const handleEdit = id => {

        API.get(`/admin/brand?brand_id=${id}`)
            .then(res => {
                let toDataURL = (url, callback) => {
                    let xhr = new XMLHttpRequest();
                    xhr.onload = () => {
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            callback(reader.result);
                        }
                        reader.readAsDataURL(xhr.response);
                    };
                    xhr.open('GET', url);
                    xhr.responseType = 'blob';
                    xhr.send();
                }

                let imageBase64 = [];

                toDataURL(`${process.env.REACT_APP_IMAGE_API_URL}/${res.data.image}`, (dataUrl) => {
                    imageBase64.push(dataUrl);

                    setSubcategoryItem({
                        id: res.data.id,
                        name: res.data.name,
                        path: `${imageBase64[0]}`
                    })
                })

                setAddMode(false);
                setEditMode(true);
            })
    }

    const handleDelete = id => {
        API.delete(`/admin/brand`, {params: {brand_id: id}})
            .then(() => {
                API.get(`/admin/brand`)
                    .then(res => {
                        setSubcategoryItem(InitialItem);
                        setCategoriesData(res.data.brands);
                    })
                if (editMode) {
                    setEditMode(false);
                }
            })
    }

    const handleHide = id => {
        API.post('/admin/hide_brand', {brand_id: id})
            .then(() => {
                API.get('/admin/brand')
                    .then(res => {
                        setCategoriesData(res.data.brands);
                    })
            })
    }

    const handleCancel = () => {
        setAddMode(true);
        setEditMode(false);
        setSubcategoryItem(InitialItem);
    }

    const submitAdd = e => {
        e.preventDefault();
        API.post(`/admin/brand`, {name: subcategoryItem.name, image: subcategoryItem.path})
            .then(() => {
                API.get(`/admin/brand`)
                    .then(res => {
                        setSubcategoryItem(InitialItem);
                        setCategoriesData(res.data.brands);
                    })
            })
    }

    const submitEdit = e => {
        e.preventDefault();
        if (!subcategoryItem.id || !subcategoryItem.name || !subcategoryItem.path) return;

        API.put(`/admin/brand`, {brand_id: subcategoryItem.id, name: subcategoryItem.name, image: subcategoryItem.path})
            .then(res => {


                API.get(`/admin/brand`)
                    .then(res => {


                        setSubcategoryItem(InitialItem);
                        setCategoriesData(res.data.brands);
                    })

                setEditMode(false);
                setAddMode(true);
            })
    }

    const handleSearch = e => {
        e.preventDefault();
        setSearchValue(e.target.value);

        if (e.target.value.length > 0) {

            API.get(`/admin/products_by_brand`, {params: {name: e.target.value}})
                .then(res => {
                    setCategoriesData(res.data.brands);
                })
        } else {
            API.get(`/admin/brand`)
                .then(res => {
                    setCategoriesData(res.data.brands);
                })
        }
    };


    return (
        <Layout>
            <section className="categories">
                <div className="container">
                    <div className="categories-info">

                        <div ref={ref} className={`g-select g-select__open`}>
                            <div className="g-select-header">
                                <h2 className="categories__title g-title">Brands</h2>
                            </div>

                            <form style={{width: '320px', margin: '0 0 24px 24px'}} className="g-search">
                                <input type="search" className="g-search__input" placeholder="Search"
                                       value={searchValue} onChange={handleSearch}/>

                                <button className="g-search__button">
                                    <img className="g-search__icon" src={SearchIcon} alt="Search"/>
                                </button>
                            </form>
                        </div>

                        <div className="categories-info__head">
                            <p className="categories-info__text">Название бренда</p>
                            <p className="categories-info__text">Фото</p>
                        </div>

                        <ul className="categories-info-list">
                            {categoriesData && categoriesData.length > 0 && categoriesData.map(subcategory => {
                                return (
                                    <li className="categories-info-list__item" key={subcategory.id}>
                                        <Link to={'/admin/goods/all'}>
                                            <p onClick={() => props.changeActiveBrand(subcategory.id)}
                                               className="categories-info-list__text">{subcategory.name}</p>
                                        </Link>
                                        <img className="categories-info-list__image"
                                             src={`${process.env.REACT_APP_IMAGE_API_URL}/${subcategory.image}`}
                                             alt="Banner"/>
                                        <button className={"g-button-hide"} onClick={() => handleHide(subcategory.id)}>
                                            {subcategory.hide ? "Show" : "Hide"}
                                        </button>
                                        <button className="g-button-edit" onClick={() => handleEdit(subcategory.id)}>
                                            <img className="g-button-edit__icon" src={EditIcon} alt="Edit"/>
                                        </button>
                                        <button className="g-button-delete"
                                                onClick={() => handleDelete(subcategory.id)}>
                                            <img className="g-button-delete__icon" src={DeleteIcon} alt="Delete"/>
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    <div className="categories-add">
                        <h3 className="categories-add__title">{editMode ? `Edit` : `Add`} brand</h3>

                        <form className="categories-add__form"
                              onSubmit={addMode ? submitAdd : editMode ? submitEdit : null}>
                            <div className="categories-add__block">
                                <p className="categories-add__text">Фото бренда</p>

                                <label className="categories-add__label">
                                    {subcategoryItem.path ?
                                        <img className="categories-add__image" src={subcategoryItem.path}
                                             alt="Upload"/> : null}

                                    <input className="categories-add__file" type="file" onChange={e => setImage(e)}
                                           onClick={e => e.currentTarget.value = null}/>

                                    <img src={AddIconLarge} alt=""/>
                                </label>
                            </div>

                            <div className="categories-add__block">
                                <p className="categories-add__text">Название бренда</p>

                                <input className="g-input" type="text" placeholder="Название" name="name"
                                       value={subcategoryItem.name} onChange={handleInputChange}/>

                                <div className="categories-add_flex">
                                    {editMode ?
                                        <button className="g-add g-add_margin" type="button" onClick={handleCancel}>
                                            Отменить
                                        </button> : null}

                                    <button className="g-add" type="submit">
                                        <img className="g-add__icon" src={AddIcon} alt="Додати"/>
                                        Добавить
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

const mapStateToProps = state => {
    return {
        activeBrand: state.shop.activeBrand
    }
};

const putStateToProps = {
    changeActiveBrand
}

export default connect(mapStateToProps, putStateToProps)(CategoriesPage);

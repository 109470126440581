import React, {useEffect, useRef, useState} from 'react'
import './styles/goods.css'
import {Link, useLocation} from 'react-router-dom';
import API from "../API";
import vector from '../assets/slider_vector_next.svg'
import vector_select from '../assets/vector.svg'
import shipping_icon from '../assets/shipping_icon.svg'
import positive_vote from '../assets/positive_vote.svg'
import terms_and_conditions from '../assets/terms_and_conditions.svg'
import LeaveARequest from "../Components/LeaveARequest";
import UniqueItemsPhotoSlider from "../Components/UniqueItemsPhotoSlider";
import UniqueGoodsOrderPopup from '../Components/UniqueGoodsOrderPopup'
import {ScrollToTop} from "../Constants/ScrollToTop";
import {
    addToCart,
    addToRecentlyViewed,
    addToFavorites,
    deleteFromFavorites
} from "../main-store/cart_and_favorite/actions";
import {connect} from "react-redux";
import PreviewCategorySectionSlider from "../Components/PreviewCategorySectionSlider";
import useOutsideClick from "../Constants/useOutsideClick";
import {bodyOverflow} from "../Constants/bodyOverflow";
import ChangedConfirmedPopup from "../Components/ChangedConfirmedPopup";
import {Helmet} from "react-helmet";
import fAPI from "../fAPI";
import Keys from "../helper";
import helper from "../helper";
import {putName} from "../main-store/header/actions";

const UniqueGoods = React.memo(({addToCart, addToRecentlyViewed, recentlyViewed, addToFavorites, favorite, putName}) => {

    const initialState = {
        name: {
            value: '',
            error: false,
            errorMessage: '',
        },
        phone: {
            value: '',
            error: false,
            errorMessage: '',
        },
        email: {
            value: '',
            error: false,
            errorMessage: '',
        },
        password: {
            value: '',
            error: false,
            errorMessage: '',
        },
        confirmPassword: {
            value: '',
            error: false,
            errorMessage: '',
        },
        agreeProcess: {
            value: false,
            error: false,
        }
    }

    const [changingConfirmed, setChangingConfirmed] = useState(false);
    const [successText, setSuccessText] = useState(null);
    const [uniqueItem, setUniqueItem] = useState(null);
    const [activeImage, setActiveImage] = useState(null);
    const [recentlyViewedFilter, setRecentlyViewedFilter] = useState(null);
    const [descriptionDetails, changeDescriptionDetails] = useState(false);
    const [addedToFavorite, setAddedToFavorite] = useState(false)
    const [showCategoryParams, setShowCategoryParams] = useState(false)
    const [modalPopup, setModalPopup] = useState(false)
    const [url, setUrl] = useState(null)
    const [modalState, setModalState] = useState(() => initialState)
    const location = useLocation();
    const ref = useRef();

    useEffect(() => {
        setUrl(window.location.href)
        // setSelectWidth(document.querySelector(".show_options")?.offsetWidth)
        //
        // window.addEventListener("resize", () => {
        //     setSelectWidth(document.querySelector(".show_options")?.offsetWidth)
        // })

        let newRecentlyViewed = recentlyViewed.filter(item => item.product_id !== Number(location.pathname.split('/').pop()));
        setRecentlyViewedFilter(newRecentlyViewed)
    }, [recentlyViewed, location.pathname]);

    useEffect(() => {
        setAddedToFavorite(false)
        let goodsId = location.pathname.split('/').pop();
        let addedToFavorite = favorite.find(i => i.product_id === Number(goodsId));
        if (addedToFavorite) {
            setAddedToFavorite(true)
        }
    }, [favorite, location.pathname])

    useEffect(() => {
        let uniqueGoods = location.pathname.split('/').pop();
        API.get(`/product?product_id=${uniqueGoods}`)
            .then(res => {
                ScrollToTop();
                let newObj = res.data;
                newObj.filters && newObj.filters.forEach(i => i.params[0].selected = true)
                newObj.properties && newObj.properties.forEach(i => i.params[0].selected = true)
                newObj.activeFilters = [];
                newObj.activeProperties = []
                setUniqueItem({...newObj});
                setActiveImage(res.data.photos[0]);
                addToRecentlyViewed(res.data);
            })
    }, [addToRecentlyViewed, location.pathname]);

    let addQuantity = () => {
        let protoItem = uniqueItem;
        protoItem.quantity = protoItem.quantity + 1;
        setUniqueItem({...protoItem});
    };

    let subtractQuantity = () => {
        let protoItem = uniqueItem;
        if (protoItem.quantity > 1) {
            protoItem.quantity = protoItem.quantity - 1;
            setUniqueItem({...protoItem});
        }
    };

    useOutsideClick(ref, () => {
        if (showCategoryParams) {
            setShowCategoryParams(null)
        }
    })

    let pushToArr = (newItem, arr, prop) => {
        if (newItem[prop]) {
            newItem[prop].forEach(i => {
                i.params.forEach(item => {
                    if (item.selected) {
                        arr.push(item.id)
                    }
                })
            });
            return arr
        }
    }

    let setChosenFilters = () => {
        let newItem = uniqueItem;
        let activeFilters = pushToArr(newItem, [], 'filters');
        let activeProperties = pushToArr(newItem, [], 'properties');
        newItem.activeFilters = activeFilters
        newItem.activeProperties = activeProperties
        return newItem
    }

    let changeContinuePopup = (text = "Changes saved successfully") => {
        setChangingConfirmed(state => !state)
        setSuccessText(text)
    }

    const handlePopupData = (e) => {
        e.preventDefault();
        // eslint-disable-next-line
        const RegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (modalState.name.value && modalState.phone.value && modalState.agreeProcess.value && RegEx.test(modalState.email.value)
        && modalState.confirmPassword.value === modalState.password.value) {
            let requestData = {
                phone: modalState.phone.value,
                name: modalState.name.value,
                email: modalState.email.value,
                product_id: uniqueItem.product_id,
            }

            if (modalState.password.value.length) {
                requestData.password = modalState.password.value
            }
            fAPI().post(`/find_out_price`, requestData)
                .then(res => {
                    if (res.status === 200) {
                        if (!localStorage.getItem(Keys.JWT_TOKEN)) {
                            let data = {name: res.data.name};
                            localStorage.setItem(helper.JWT_TOKEN, res.data.access_token);
                            localStorage.setItem(helper.USER_DATA, JSON.stringify(data))
                            putName(res.data.name);
                        }
                        bodyOverflow(false)
                        setModalPopup(false)
                        setModalState(initialState)
                        changeContinuePopup('Thank you. Our managers will contact you soon.')
                        setTimeout(() => changeContinuePopup('Thank you. Our managers will contact you soon.'), 3000);
                    }
                })
                .catch(err => {
                    console.log((err))
                    setModalState(prevState => ({
                        ...prevState,
                        email: {...prevState.email, error: true, errorMessage: 'Пользователь с такой почтой уже зарегистрирован'}
                    }))
                })
        } else {
            Object.entries(modalState).forEach(item => {
                if (!item[1].value) {
                    setModalState(prevState => ({
                        ...prevState,
                        [item[0]]: {...prevState[item[0]], error: true, errorMessage: 'Fill this label'}
                    }))
                } else if (item[0] === 'email' && !RegEx.test(modalState.email.value)) {
                    setModalState(prevState => ({
                        ...prevState,
                        [item[0]]: {...prevState[item[0]], error: true, errorMessage: 'Wrong email format'}
                    }))
                } else if (item[0] === 'confirmPassword') {
                    setModalState(prevState => ({
                        ...prevState,
                        [item[0]]: {...prevState[item[0]], error: true, errorMessage: 'Passwords do not match'}
                    }))
                }
            })
        }
    }

    let uniqueItemInfo = () => {
        return (
            <div className={'info_about_item'}>
                <div className={'wrapper_brand'}>
                    <span className={'open_sans_400'}>{uniqueItem.brand.name}</span>
                </div>
                <div className={'wrapper_item_name'}>
                    <span className={'playfair_display_400'}>{uniqueItem.name}</span>
                </div>
                <div className={'wrapper_item_description'}>
                <span className={'open_sans_300'}
                      dangerouslySetInnerHTML={{
                          __html: uniqueItem.description.length > 110 && !descriptionDetails ?
                              uniqueItem.description.substring(0, 110) + '...'
                              : uniqueItem.description
                      }}/>
                    {uniqueItem.description.length > 110 ?
                        <>
                            <br/>
                            <span className={'open_sans_300 more_details'}
                                  onClick={() => changeDescriptionDetails(!descriptionDetails)}>
                            {uniqueItem.description.length > 110 && !descriptionDetails ?
                                'More details'
                                : 'Hide details'}
                        </span>
                        </> : null}
                    {uniqueItem.filters?.length ?
                        productPropertiesMaterial('filters')
                        : null}
                    {uniqueItem.properties?.length ?
                        productPropertiesMaterial('properties')
                        : null}
                </div>
                <div className={'wrapper_item_price'}>
                    <span className={'price_title open_sans_400'}>Price</span>
                    {uniqueItem.price ?
                        <span className={'open_sans_600 price'}>
                            €{uniqueItem.price}
                        </span>
                        : null}
                    {uniqueItem.discount_price && (
                        <span className={uniqueItem.discount_price ? 'open_sans_400 old_price' : 'price'}>
                        €{uniqueItem.discount_price}
                    </span>
                    )}
                    <span className={'open_sans_400'}>The price on the site is for informational purposes only.</span>
                </div>

                <div className={'wrapper_submit_request wrapper_find_out_the_price'}
                     style={{display: 'flex'}}>
                    <button className={'open_sans_600'}
                            onClick={() => {
                                setModalPopup(true)
                                bodyOverflow(true)
                            }}
                            style={{width: "64%"}}>
                        Find out the exact price
                    </button>
                    <button className={addedToFavorite ? 'open_sans_600 active_favorite' : 'open_sans_600'}
                            style={{width: "34%", marginLeft: "2%"}}
                            onClick={() => addToFavorites(uniqueItem)}>
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.5313 0.875C13.2019 0.875 11.278 3.03857 10.5 4.0597C9.72197 3.03857 7.7981 0.875 5.46874 0.875C2.45327 0.875 0 3.64741 0 7.0547C0 8.91365 0.737871 10.6461 2.02941 11.8317C2.0482 11.8642 2.07129 11.8941 2.0982 11.921L10.1911 19.9973C10.2765 20.0823 10.3881 20.125 10.5 20.125C10.6119 20.125 10.7239 20.0823 10.8093 19.9969L19.1714 11.6378L19.2577 11.554C19.3261 11.4895 19.3936 11.4241 19.4692 11.3404C19.5008 11.3092 19.5268 11.2742 19.5469 11.2362C20.4848 10.0877 21 8.60644 21 7.0547C21 3.64741 18.5468 0.875 15.5313 0.875ZM18.8189 10.744C18.8069 10.7581 18.7958 10.773 18.786 10.7884C18.745 10.8354 18.7001 10.8768 18.6557 10.9192L10.4996 19.0693L2.81642 11.4015C2.79165 11.3528 2.75703 11.3083 2.71474 11.2712C1.54535 10.2501 0.874986 8.71325 0.874986 7.0547C0.874986 4.12975 2.93561 1.75003 5.46874 1.75003C7.97327 1.75003 10.1108 5.01676 10.1321 5.04965C10.2937 5.29915 10.7063 5.29915 10.8679 5.04965C10.8892 5.01676 13.0267 1.75003 15.5313 1.75003C18.0644 1.75003 20.125 4.12979 20.125 7.0547C20.125 8.43299 19.661 9.74336 18.8189 10.744Z"
                                fill="#C69C6D"/>
                        </svg>
                    </button>
                </div>
                <div className={'wrapper_adding_to_cart'}>
                    <div className="adding_to_cart" style={{width: "100%", maxWidth: "none"}}>
                        <div className={'adding_to_cart_btn'}
                             onClick={() => {
                                 let newItem = setChosenFilters();
                                 addToCart(newItem)
                             }}>
                            <span className={'open_sans_400'}>
                                Add to cart
                            </span>
                        </div>
                        <div className={'item_quantity'}>
                            <span className={'quantity_changer'} onClick={subtractQuantity}>-</span>
                            <span>{uniqueItem.quantity}</span>
                            <span className={'quantity_changer'} onClick={addQuantity}>+</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    let info_about_us = () => {
        return (
            <div className={'wrapper_about_us_info'}>
                <div className={'every_about_us_info'}>
                    <Link to={"/terms/delivery"}>
                        <img src={shipping_icon} className={'main_icon'} alt="shipping_icon"/>
                        <div className={'wrapper_info'}>
                            <div className={'wrapper_title'}>
                            <span className={'playfair_display_400'}>
                                We deliver all over the world
                            </span>
                                <img src={vector} alt="vector"/>
                            </div>
                            <div className={'wrapper_desc'}>
                            <span className={'open_sans_300'}>
                                We deliver all over the world
                            </span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'every_about_us_info'}>
                    <img src={positive_vote} className={'main_icon border_block'} alt="positive_vote"/>
                    <div className={'wrapper_info'}>
                        <div className={'wrapper_title'}>
                            <span className={'playfair_display_400'}>
                                100% original
                            </span>
                        </div>
                        <div className={'wrapper_desc'}>
                            <span className={'open_sans_300'}>
                                All products are original and in accordance with specifications.
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'every_about_us_info'}>
                    <Link to={'/terms/terms_and_conditions'}>
                        <img src={terms_and_conditions} className={'main_icon border_block'}
                             alt="terms_and_conditions"/>
                        <div className={'wrapper_info'}>
                            <div className={'wrapper_title'}>
                            <span className={'playfair_display_400'}>
                                Terms and Conditions
                            </span>
                                <img src={vector} alt="vector"/>
                            </div>
                            <div className={'wrapper_desc'}>
                            <span className={'open_sans_300'}>
                                Exchange and return of good quality goods
                            </span>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        )
    };

    let info_about_product = () => {
        return (
            <div className={'wrapper_info_about_product'}>
                <div className={'info_about_product'}>
                    <div className={'wrapper_title'}>
                        <span className={'open_sans_300'}>Information</span>
                        <span className={'main_title playfair_display_400'}>
                            {uniqueItem.name}
                        </span>
                    </div>
                    <div className={'wrapper_description'}>
                        <span className={'open_sans_300'}
                              dangerouslySetInnerHTML={{__html: uniqueItem.description}}/>
                    </div>
                    <div className={'wrapper_main_info'}>
                        <div className={'every_main_info'}>
                            <span className={'open_sans_300'}>Brand</span>
                            <span className={'open_sans_400'}>
                                {uniqueItem.brand instanceof String ? uniqueItem.brand : uniqueItem.brand.name}
                            </span>
                        </div>
                        {uniqueItem.designer ?
                            <div className={'every_main_info'}>
                                <span className={'open_sans_300'}>Designer</span>
                                <span className={'open_sans_400'}>{uniqueItem.designer}</span>
                            </div>
                            : null}
                    </div>
                </div>
                <div className={'wrapper_static_info'}>
                    <div className={'wrapper_title'}>
                        <span className={'open_sans_300'}>Delivery and return</span>
                        <span className={'main_title playfair_display_400'}>
                            We deliver all over the world
                        </span>
                    </div>
                    <div className={'wrapper_description'}>
                        <span className={'open_sans_300'}>
                            Standard shipping is street level and the order is always covered by insurance. <br/>
                            You can receive the goods delivered to any address by providing full information when placing an order.
                        </span>
                    </div>
                    <div className={'wrapper_shipping_info'}>
                        <Link to={'/terms/terms_and_conditions'}>
                            More about shipping and returns
                        </Link>
                    </div>
                </div>
            </div>
        )
    };

    const handlePropertiesValue = (prop, id, params_id) => {
        let newArr = uniqueItem;
        newArr[prop].forEach(item => {
            if (item.id === id) {
                item.params.forEach(items => {
                    items.selected = items.id === params_id;
                })
            }
        })
        setUniqueItem({...newArr})
    }

    const productPropertiesMaterial = prop => {
        return uniqueItem[prop]?.map(item => {
            return (
                <div className={"show_options"} key={item.id}>
                    <div className={'wrapper_select_input'}>
                        <div className={'wrapper_category_name'}>
                            {item.name}
                        </div>
                        <div className={'wrapper_preview_category'}
                             onClick={() => {
                                 if (showCategoryParams === item.name) {
                                     setShowCategoryParams(null)
                                 } else {
                                     setShowCategoryParams(item.name)
                                 }
                             }}>
                            {item.params.map(i => {
                                return i.selected && i.icon ?
                                    <img src={process.env.REACT_APP_IMAGE_API_URL + "/" + i.icon}
                                         key={i.param}
                                         alt={item.param}/>
                                    : null
                            })}
                            <span>
                                {item.params.map(i => (
                                    i.selected
                                        ? i.name ? i.name
                                        : i.param
                                        : null
                                ))}
                            </span>
                            <img src={vector_select} alt="vector"/>
                        </div>
                        {showCategoryParams === item.name ?
                            <div className={'wrapper_category_params overflow_scroll'}
                                 ref={ref}>
                                {item.params.map(param => {
                                    return (
                                        <div
                                            className={param.selected ? 'wrapper_every_params active' : 'wrapper_every_params'}
                                            key={param.id}
                                            onClick={() => {
                                                handlePropertiesValue(prop, item.id, param.id)
                                                setShowCategoryParams(null)
                                            }}>
                                            {param.icon ?
                                                <img src={process.env.REACT_APP_IMAGE_API_URL + "/" + param.icon}
                                                     alt={param.param ?? param.name}/>
                                                : null}
                                            <span>{param.name ?? param.param}</span>
                                        </div>
                                    )
                                })}
                            </div>
                            : null}
                    </div>
                </div>
            )
        })
    }

    return uniqueItem && activeImage ? (
        <div className={'wrapper_main_page unique_items_page'}>
            <Helmet>
                <meta property="og:title" content={`${uniqueItem?.name}${uniqueItem?.brand?.name ? ' - ' + uniqueItem?.brand?.name : ''} - buy on Vinterior`}/>
                <title>{`${uniqueItem?.name}${uniqueItem?.brand?.name ? ' - ' + uniqueItem?.brand?.name : ''} - buy on Vinterior`}</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={process.env.REACT_APP_IMAGE_API_URL + "/" + uniqueItem.photos[0]}/>
                <meta property="description"
                      content={`${uniqueItem?.brand?.name ? uniqueItem?.brand?.name + ' - ' : ''}${uniqueItem?.name}. Worldwide shipping. Vinteriors store \n A luxurious piece by ${uniqueItem?.brand?.name ? uniqueItem?.brand?.name + ' - ' : ''}${uniqueItem?.name}, that will bring an inner piece and comfort to your everyday endeavors. We ship it worldwide. Right to your door. \n ${uniqueItem?.brand?.name ? uniqueItem?.brand?.name + ' - ' : ''}${uniqueItem?.name}. Vinteriors store`}/>
                <meta property="og:description"
                      content={`${uniqueItem?.brand?.name ? uniqueItem?.brand?.name + ' - ' : ''}${uniqueItem?.name}. Worldwide shipping. Vinteriors store \nA luxurious piece by ${uniqueItem?.brand?.name ? uniqueItem?.brand?.name + ' - ' : ''}${uniqueItem?.name}, that will bring an inner piece and comfort to your everyday endeavors. We ship it worldwide. Right to your door. \n${uniqueItem?.brand?.name ? uniqueItem?.brand?.name + ' - ' : ''}${uniqueItem?.name}. Vinteriors store`}/>
                <meta property="twitter:title" content={`${uniqueItem?.name}${uniqueItem?.brand?.name ? ' - ' + uniqueItem?.brand?.name : ''} - buy on Vinterior`}/>
                <meta property="twitter:description"
                      content={`${uniqueItem?.brand?.name ? uniqueItem?.brand?.name + ' - ' : ''}${uniqueItem?.name}. Worldwide shipping. Vinteriors store \n A luxurious piece by ${uniqueItem?.brand?.name ? uniqueItem?.brand?.name + ' - ' : ''}${uniqueItem?.name}, that will bring an inner piece and comfort to your everyday endeavors. We ship it worldwide. Right to your door. \n ${uniqueItem?.brand?.name ? uniqueItem?.brand?.name + ' - ' : ''}${uniqueItem?.name}. Vinteriors store`}/>
                <meta property="twitter:image" content={process.env.REACT_APP_IMAGE_API_URL + "/" + uniqueItem.photos[0]}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
            <div className={'wrapper_info_about_item'}>
                <UniqueItemsPhotoSlider photos={uniqueItem.photos}
                                        setActiveImage={setActiveImage}
                                        activeImage={activeImage}/>
                {uniqueItemInfo()}
            </div>
            {info_about_us()}
            {info_about_product()}
            <div className={'wrapper_preview_liked_goods_section'}>
                <div className={'wrapper_title'}>
                    <span className={'playfair_display_400'}>
                        You may also like ...
                    </span>
                </div>
                <PreviewCategorySectionSlider goods={uniqueItem.oder_products}/>
            </div>
            {recentlyViewedFilter.length ?
                <div className={'wrapper_preview_liked_goods_section'}>
                    <div className={'wrapper_title'}>
                    <span className={'playfair_display_400'}>
                       You recently viewed
                    </span>
                    </div>
                    <PreviewCategorySectionSlider
                        goods={recentlyViewedFilter}/>
                </div>
                : null}
            {modalPopup ?
                <UniqueGoodsOrderPopup setModalPopup={val => setModalPopup(val)}
                                       modalState={modalState}
                                       setModalState={val => setModalState(val)}
                                       handlePopupData={handlePopupData}/>
                : null}
            <LeaveARequest goodsId={''}/>
        </div>
    ) : null
});

const mapPropsToState = ({cart_favorite}) => {
    return {
        cart: cart_favorite.cart,
        recentlyViewed: cart_favorite.recentlyViewed,
        favorite: cart_favorite.favorite,
    }
};

const putStateToProps = {
    addToCart,
    addToRecentlyViewed,
    addToFavorites,
    deleteFromFavorites,
    putName
};

export default connect(mapPropsToState, putStateToProps)(UniqueGoods)

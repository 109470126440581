import React, {PureComponent} from 'react';
import API from "../API";
import helper from "../helper";

const DeliveryAddressHOC = (WrappedComponent) => {
    return class NewComponent extends PureComponent {
        state = {
            loading: false,
            data: null,
            changingConfirmed: false,
            successText: null,
        };

        changeContinuePopup = (text = "Changes saved successfully") => {
            this.setState({
                successText: text,
                changingConfirmed: !this.state.changingConfirmed
            })
        }

        componentDidMount() {
            API.get(`/user/delivery_address`)
                .then(res => {
                    this.setState({
                        data: {
                            city: {
                                value: res.data.city ?? '',
                                error: false,
                                errorMessage: '',
                            },
                            country: {
                                value: res.data.country ?? '',
                                error: false,
                                errorMessage: '',
                            },
                            region: {
                                value: res.data.region ?? '',
                                error: false,
                                errorMessage: '',
                            },
                            street_address: {
                                value: res.data.street_address ?? '',
                                error: false,
                                errorMessage: '',
                            },
                            zip_code: {
                                value: res.data.zip_code ?? '',
                                error: false,
                                errorMessage: '',
                            },
                        },
                        loading: true
                    })
                })
        }

        pushToArr = newItem => {
            newItem.forEach(item => {
                if (item.filters?.length) {
                    item.activeFilters = [];
                    item.filters.forEach(i => {
                        i.params.forEach(params => {
                            if (params.selected) {
                                item.activeFilters = [...item.activeFilters, params.id]
                            }
                        })
                    });
                }
                if (item.properties?.length) {
                    item.activeProperties = [];
                    item.properties.forEach(i => {
                        i.params.forEach(params => {
                            if (params.selected) {
                                item.activeProperties = [...item.activeProperties, params.id]
                            }
                        })
                    });
                }

            })
            return newItem
        }

        setChosenFilters = () => {
            let newItem = this.props.cart;
            return this.pushToArr(newItem)
        }

        changeDeliveryAddress = (e, cartOrder) => {
            e.preventDefault();
            let {data} = this.state;
            if (data.city.value && data.country.value && data.zip_code.value && data.region.value && data.street_address.value) {
                if (data.zip_code.value.toString().length > 4) {
                    if (cartOrder && this.props.cart?.length) {
                        let newItem = this.setChosenFilters();
                        let newProducts = [...newItem.map(i => ({
                            id: i.product_id,
                            quantity: i.quantity,
                            filters: i.activeFilters ?? [],
                            properties: i.activeProperties ?? []
                        }))]
                        API.post(`/user/buy`, {products: newProducts, promo_code: this.props.sale})
                            .then(res => {
                                if (res.status === 200) {
                                    localStorage.removeItem(helper.USER_CART);
                                    this.props.deleteCart();
                                    this.props.setSuccessfulOrder(true)
                                }
                            })
                    } else {
                        API.patch(`/user/delivery_address`, {
                            country: data.country.value,
                            region: data.region.value,
                            city: data.city.value,
                            zip_code: data.zip_code.value,
                            street_address: data.street_address.value
                        })
                            .then(() => {
                                let {cartOrder, setSuccessfulOrder} = this.props;
                                if (cartOrder) {
                                    setSuccessfulOrder(true)
                                } else {
                                    this.changeContinuePopup()
                                    setTimeout(() => this.changeContinuePopup(), 500);
                                }
                            })
                            .catch(err => console.log(err))
                    }
                } else {
                    this.setState(state => ({
                        data: {
                            ...state.data, zip_code: {
                                ...state.data.zip_code,
                                error: true,
                                errorMessage: 'Zip code must contain 5 numbers'
                            }
                        }
                    }))
                }
            } else {
                Object.entries(data).forEach(item => {
                    if (!item[1].value) {
                        this.setState(state => ({
                            data: {
                                ...state.data,
                                [item[0]]: {
                                    ...state.data[item[0]],
                                    error: true,
                                    errorMessage: 'Fill this label'
                                }
                            }
                        }))
                    }
                })
            }
        }

        setInputValue = (name, value) => {
            this.setState(state => ({
                data: {
                    ...state.data,
                    [name]: {
                        value: value,
                        error: null,
                        errorMessage: null
                    }
                }
            }))
        }

        handleInputChange = event => {
            let {name, value} = event.target;
            const RegEx = (/^[0-9]*$/g);
            if (name !== 'zip_code') {
                this.setInputValue(name, value)
            } else if (name === 'zip_code' && RegEx.test(value)) {
                this.setInputValue(name, value)
            }
        }

        render() {
            let {state, props} = this
            return state.loading ? (
                <WrappedComponent {...props}
                                  changeDeliveryAddress={this.changeDeliveryAddress}
                                  handleInputChange={this.handleInputChange}
                                  {...state}/>
            ) : null
        }
    }
}

export default DeliveryAddressHOC;

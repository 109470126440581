import React, {useEffect, useState} from 'react';
import "./styles/conditions.css";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'
export default function CookiesPage(props) {
    const [url, setUrl] = useState(null)

    useEffect(() => {
        setUrl(window.location.href)
    }, [])

    return (
        <div className={'main_page_conditions'}>
            <Helmet>
                <meta property="og:title" content={'Cookies — Vinteriors store.'}/>
                <title>Cookies — Vinteriors store.</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="og:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:title" content={'Cookies — Vinteriors store.'}/>
                <meta property="twitter:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <span className={'playfair_display_400'}>
                <h2 className={'title'}>Cookies use and storage policy</h2>
            </span>
        </div>
    )
}

import {TEST} from "./action";

const InitialState = {
    test: null,
};

export const delivery = (state = InitialState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case TEST:
            return {...state, test: action.payload};
    }
    return state;
};

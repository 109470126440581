import React, { Component } from 'react';

export class GoogleMap extends Component {
    render() {
        return (
            <div className={'map-wrapper'} style={{ width: '100%', height: '285px', position: 'relative' }}>
                <iframe
                    title="Google Map of Warsaw"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4886.875514221284!2d21.082188576603635!3d52.23543197198808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecd978c232411%3A0x10c33e30338088dd!2zT3N0cm9icmFtc2thIDEwMSwgMDQtMDQxIFdhcnN6YXdhLCDQn9C-0LvRjNGI0LA!5e0!3m2!1sru!2sde!4v1727192979658!5m2!1sru!2sde"
                    style={{ width: '100%', height: '100%', border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        );
    }
}

export default GoogleMap;

import React from 'react'
import './styles/preview_category_section.css'
import PreviewGoodsSlider from './PreviewGoodsSlider';
import {Link} from "react-router-dom";

export default function PreviewCategorySectionSlider({title, goods, catalogLink}) {

    const settings = {
        speed: 400,
        slidesToShow: 4,
        infinite: false,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                }
            },{
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            }
        ]
    };

    return (
        <div className={'wrapper_preview_category_section'}
             style={!title ? {gridTemplateColumns: '1fr'} : null}>
            {title ?
                <div className={'preview_category_title'}>
                    <div className={'wrapper_title'}>
                        <span className={'playfair_display_400'}>{title}</span>
                    </div>
                    <div className={'wrapper_description'}>
                    <span className={'open_sans_300'}>
                        Improve timeless design and make it exclusive.
                    </span>
                    </div>
                    <div className={'wrapper_submit_request preview_category_link'}>
                        <Link className={'link'} to={catalogLink}>Go to catalog</Link>
                    </div>
                </div>
                : null}
            <PreviewGoodsSlider settings={settings}
                                goods={goods}/>
            {title ?
                <div className={'wrapper_submit_request preview_category_link preview_category_link_mobile'}>
                    <button>Go to catalog</button>
                </div>
                : null}
        </div>
    )
}

export const MovaCat = '⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚫⚫⚫⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫⚫⚫⚫⚪⚪⚪\n' +
    '⚪⚪⚪⚫💭👕⚫⚪🌚⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫⚪⚪👕🌕💙⚫⚪⚪\n' +
    '⚪⚪⚪⚫💭💭⚫⚫⚫🌚🌕⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫🌕👕⚪⚫⚫💙💭⚫⚪⚪\n' +
    '⚪⚪⚪⚫💭💭💭💭⚫⚫⚪🌚⚫⚪⚪⚪⚪⚫⚫⚫⚫⚫⚫⚫⚪⚪⚫⚫🐘⚪⚪⚪⚫💭💭💭💭⚫⚪⚪\n' +
    '⚪⚪⚪⚫💭💭💭💭💭⚫⚫⚪🐘⚫⚫⚫🌕🐘🐘🌕🌕🌕🌕👕⚫⚫🌕🌕⚪⚪⚪⚫🐙💭💭💭💭⚫⚪⚪\n' +
    '⚪⚪⚪⚫💭💭💭💭💭💭⚫🌚⚪👕🐘🌚⚪⚪⚪⚪⚪⚪⚪⚪🌚🌚⚪⚪⚪⚪⚫🌚💭💭💭💭💭⚫⚪⚪\n' +
    '⚪⚪⚪⚫💭💭💭💭💭💭⚫🌕⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪🌚⚫💭💭💭💭💭💭⚫⚪⚪\n' +
    '⚪⚪⚪⚫👕💭💭💭⚫🌚🌚⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪🌚⚫💭💭💭💭👕⚫⚪⚪\n' +
    '⚪⚪⚪⚪⚫💭💭⚫🐘⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪🌕🌚💭💭💭⚫⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚫💭⚫🌕⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪🌚🌚🌚💭⚫⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚫⚫🌕⚪⚪⚪⚪⚪⚫⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫⚫⚫⚪⚪⚪🌚⚫💭⚫⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚫👕⚪⚪⚪⚪⚪⚫⚫👕⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫🌕🌚⚫⚫⚪⚪⚪🌕⚫⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚫⚪⚪⚪⚪⚪⚫⚫🐘⚪🌚⚫⚫⚪⚪⚪⚪⚪⚪⚪⚫⚫🌚⚪🌚🌕🌚⚪⚪⚪⚪🐘⚫⚪⚪⚪\n' +
    '⚪⚪⚪⚫🌕⚪⚪⚪⚪⚪👕🌕⚪⚪⚪⚫⚫⚪⚪⚪⚪⚪⚪⚪⚫⚫⚪⚪⚪⚪🌚⚪⚪⚪⚪⚪⚫⚪⚪⚪\n' +
    '⚪⚪⚪⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪🐘🐘⚪⚪⚪⚪⚪⚪⚪🌕👕⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚪⚪⚪\n' +
    '⚪⚪⚪⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚪⚪\n' +
    '⚪⚪⚫⚪⚪⚫⚫⚫⚫💙🌚⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪💙⚫⚫⚫⚫⚪⚪⚫⚪⚪\n' +
    '⚪⚪⚫🌚⚪👕🌚🐙🐙💙💙⚪⚪⚪⚪🌕⚫⚪⚪🐘⚫🌚⚪⚪⚫⚪⚪⚪⚪💙💙💙💙💙⚪⚪⚪⚫⚪⚪\n' +
    '⚪⚪⚫⚪⚪⚫💙💙💙💙💙⚪⚪⚪⚪🌚⚫⚪⚪⚫🌚⚫⚪⚪⚫⚪⚪⚪⚪💙💙💙💙💙⚫⚪⚪⚫⚪⚪\n' +
    '⚪⚪⚫🌕⚪⚫⚫⚫⚫💙💙⚪⚪⚪⚪⚪🌚⚫⚫🌕⚪⚫⚫⚫🌚⚪⚪⚪⚪💙💙🐙🐙🐙🌚⚪⚪⚫⚪⚪\n' +
    '⚪⚪⚪⚫⚪🐘🐘💙💙💙💭⚪⚪⚪⚪⚪⚪👕🌚⚪⚪🌚🌚🌚⚪⚪⚪⚪⚪💙💙💙💙💭⚪⚪🐘⚫⚪⚪\n' +
    '⚪⚪⚪⚫⚪⚪⚪🌕💭🌕⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪💙⚪💭⚪⚪⚪⚫⚪⚪⚪\n' +
    '⚪⚪⚪⚫🌚⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪🌚⚫⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚫🌚⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚫⚫🌚⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚪⚫⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪🌚⚫⚫⚪⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚪⚪⚫⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫⚫⚪⚪⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫⚫⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫⚫⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫⚫⚫⚫⚫⚫⚫⚫⚫⚫⚫⚫⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚫⚫⚫⚫⚫⚫⚫⚫⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪\n' +
    '⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪⚪'
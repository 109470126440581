import React, {useState, useRef, useEffect} from 'react';
import API from "../adminAPI";


const UserItem = ({match}) => {

    const [userData, setUserData] = useState([]);

    const [modal, setModal] = useState(false);

    const ref = useRef();

    const handleModalOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setModal(false);
        }
    };

    useEffect(() => {
        const urlArr = match.url.split("/");
        let id = urlArr[3];
        API.get(`/admin/user?user_id=${id}`)
            .then(res => {
                setUserData(res.data);
            });

        if (modal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        document.addEventListener("mousedown", handleModalOutside);
        return () => document.removeEventListener("mousedown", handleModalOutside);
    }, [modal, match.url]);

    return (
        <>
            <div className="user__headline">
                <h2 className="user__title">{userData.name} {userData.surname}</h2>
                <h4 className="user__date">{userData.registration_date}</h4>
            </div>

            <div className="user-info">
                <div className="user-info__block">
                    <p className="user-info__key">Имя:</p>
                    <p className="user-info__value">{userData.name}</p>

                    <p className="user-info__key">Фамилия:</p>
                    <p className="user-info__value">{userData.surname}</p>

                    <p className="user-info__key">E-mail:</p>
                    <p className="user-info__value">{userData.email}</p>

                    <p className="user-info__key">Телефон</p>
                    <p className="user-info__value">{userData.phone}</p>

                </div>

                <div style={{gridTemplateRows: '22px 22px'}} className="user-info__block">
                    <p className="user-info__key">Пол:</p>
                    <p className="user-info__value">{userData.gender}</p>

                    <p className="user-info__key">Дата рождения:</p>
                    <p className="user-info__value">{userData.date_of_birth}</p>

                </div>
            </div>
        </>
    );
}

export default UserItem;

export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_RECENTLY_VIEWED = 'ADD_TO_RECENTLY_VIEWED';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const SUBTRACT_QUANTITY = 'SUBTRACT_QUANTITY';
export const DELETE_CART_ITEM = 'DELETE_CART_ITEM';
export const ADD_TO_FAVORITES = 'ADD_TO_FAVORITES';
export const DELETE_FROM_FAVORITES = 'DELETE_FROM_FAVORITES'
export const DELETE_CART = 'DELETE_CART'

export const addQuantity = id => ({
    type: ADD_QUANTITY,
    payload: id,
});

export const subtractQuantity = id => ({
    type: SUBTRACT_QUANTITY,
    payload: id,
});

export const deleteItemCart = id => ({
    type: DELETE_CART_ITEM,
    payload: id,
});

export const addToCart = text => ({
    type: ADD_TO_CART,
    payload: text,
});

export const addToRecentlyViewed = item => ({
    type: ADD_TO_RECENTLY_VIEWED,
    payload: item,
});

export const addToFavorites = item => ({
    type: ADD_TO_FAVORITES,
    payload: item,
})

export const deleteFromFavorites = id => ({
    type: DELETE_FROM_FAVORITES,
    payload: id,
})
export const deleteCart = () => ({
    type: DELETE_CART
})
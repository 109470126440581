import React, {useEffect, useState} from "react";
import Layout from "../adminComponents/Layout"
import API from "../adminAPI";
import deleteIcon from '../assets/delete-bin-2-line.svg'

import '../adminComponents/style/reviews.css'
import UniqueGoodsOrderPopup from "../Components/UniqueGoodsOrderPopup";

const ReviewsPage = () => {

        const [data, setData] = useState(null)
        const [loading, setLoading] = useState(false)
        const [modalPopup, setModalPopup] = useState(null)

        useEffect(() => {
            API.get(`/admin/find_out_price`)
                .then(res => {
                    setData(res.data)
                    setLoading(true)
                })
                .catch(err => {
                    console.log(err)
                })
        }, [])

        const handleDelete = () => {
            API.delete(`/admin/find_out_price?item_id=${modalPopup}`)
                .then(() => {
                    const filterData = data.filter(item => item.id !== modalPopup)
                    setData(filterData)
                    setModalPopup(false)
                })
                .catch(err => console.log(err))
        }

        return (
            <Layout>
                <section className="languages">
                    <div className="container">
                        <div className={'reviews_page_wrapper'}>
                            <h2>Отзывы</h2>
                            <h3>Запросы на обратную связь</h3>
                            <div className={'reviews_page_wrapper_card_container'}>
                                {loading && data.map(item => (
                                    <div key={item.id} className={'reviews_page_wrapper_card'}>
                                        {console.log(item)}
                                        <img onClick={() => setModalPopup(item.id)} src={deleteIcon} alt="delete"/>
                                        <div className={'reviews_page_wrapper_card_content'}>
                                            <span>Имя:</span>
                                            <p>{item.user.name}</p>
                                        </div>
                                        <div className={'reviews_page_wrapper_card_content'}>
                                            <span>Номер:</span>
                                            <p>{item.user.phone}</p>
                                        </div>
                                        {item.user.email &&
                                        <div className={'reviews_page_wrapper_card_content'}>
                                            <span>Email:</span>
                                            <a href={`mailto:${item.user.email}`}>{item.user.email}</a>
                                        </div>}
                                        {item.product ?
                                            <div className={'reviews_page_wrapper_card_content'}>
                                                <span>Товар:</span>
                                                <p><a
                                                    href={`/goods/${item.product.id ? item.product.id : null}`}>{item.product.name ? item.product.name : null}</a>
                                                </p>
                                            </div> : null}
                                        <small>{item.time}</small>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
                {modalPopup ?
                    <UniqueGoodsOrderPopup adminConfirmModal
                                           setModalPopup={val => setModalPopup(val)}
                                           handleDelete={handleDelete}/> : null}
            </Layout>
        );
    }
;

export default ReviewsPage

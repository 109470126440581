import React from 'react'
import './styles/every_goods.css'
import {Link} from "react-router-dom";
import {addToCart} from "../main-store/cart_and_favorite/actions";
import {connect} from "react-redux";

function EveryGoods({item, addToCart, changeContinuePopup}) {
    const addingToCart = e => {
        e.preventDefault()
        addToCart(item);
        changeContinuePopup('Successfully added to the cart')
        setTimeout(() => changeContinuePopup('Successfully added to the cart'), 500)
    };

    const imgPreview = () => {
        return (
            <div className={'wrapper_img'}>
                {item.photo ?
                    <img src={process.env.REACT_APP_IMAGE_API_URL + '/' + item.photo} alt={item.name}/>
                    : <div className={'wrapper_img__image_not_found'}>
                        <span>no image</span>
                    </div>}
                <div className="add_to_cart_wrapper"
                     onClick={e => addingToCart(e)}>
                    <span className={'open_sans_400'}>
                        to the shopping cart
                    </span>
                </div>
            </div>
        )
    };

    const goodsInfo = () => {
        return (
            <div className={'wrapper_goods_info'}>
                <div className={'wrapper_name'}>
                    <span className={'open_sans_400'}>
                        {item.name?.length > 16 ?
                            item.name?.substring(0, 16) + '...'
                            : item.name}
                    </span>
                </div>
                <div className={'wrapper_brand'}>
                    <span className={'playfair_display_400'}>
                        {item.brand instanceof String
                            ? item.brand?.length > 16
                                ? item.brand?.substring(0, 16) + '...'
                                : item.brand
                            : item.brand?.name?.length > 20
                                ? item.brand?.name?.substring(0, 16) + '...'
                                : item.brand?.name}
                    </span>
                </div>
                <div className={'wrapper_price'}>
                    {item.price ?
                        <span className={'open_sans_400 price'}>
                            €{item.price}
                        </span>
                        : null}
                    {item.discount_price && (
                        <span className={item.discount_price ? 'open_sans_400 old_price' : 'price'}>
                        €{item.discount_price}
                    </span>
                    )}
                </div>

            </div>
        )
    };
    return (
        <Link to={`/goods/${item.product_id}`}
              className={'wrapper_every_goods'}>
            {imgPreview()}
            {goodsInfo()}
        </Link>
    )
}

const mapPropsToState = ({cart_favorite}) => {
    return {
        cart: cart_favorite.cart
    }
};

const putStateToProps = {
    addToCart,
};

export default connect(mapPropsToState, putStateToProps)(EveryGoods)

import React, {useState, useEffect} from 'react';
import API from "../API";


export const BrandsSlider = (props) => {
    //eslint-disable-next-line
    const [brandy, setBrandy] = useState([])

    useEffect(() => {
        API.get('/all_brands')
            .then(res => {
                setBrandy(res.data)
            })
    }, [])

    return (
        <div className={`wrapper_brands_slider`}>
            {/*<Slider {...props.settings}>*/}
            {/*    {Object.entries(brandy).slice(1, 6).map(letters => {*/}
            {/*        return letters[1].map(item => {*/}
            {/*            return (*/}
            {/*                <Link to={`/catalogue/brand=${item.id}`}*/}
            {/*                      className={'wrapper_every_brand'}*/}
            {/*                      key={item.id}>*/}
            {/*                    <p className={"brands_text"}>{item.name}</p>*/}
            {/*                </Link>*/}
            {/*            )*/}
            {/*        })*/}
            {/*    })}*/}
            {/*</Slider>*/}
        </div>
    )
};
import React, {useEffect, useState} from 'react';
import './styles/MainButton.css'
// import uploadIcon from '../assets/img/button-icon-upload.svg';
// import uploadIconDisabled from '../assets/img/button-icon-upload_disabled.svg';
// import uploadIconBlue from '../assets/img/blue-main-button_icon.svg';
// import uploadIconLighter from '../assets/img/blue-lighter-button_icon.svg';
// import uploadIconDarker from '../assets/img/blue-darker-button_icon.svg';


function MainButton(props) {
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        if (props.disabled !== undefined && props.disabled !== null) {
            setDisabled(props.disabled)
        }
    }, [props.disabled])

    return (
        <div style={props.wrapperStyle ? props.wrapperStyle : null}>
            <button
                id={props.id ? props.id : null}
                onClick={props.onClick ? () => {
                    props.onClick()
                } : null}
                type={props.type ? props.type : 'button'}
                style={props.style ? props.style : null}
                className={props.button === 'main' ? 'main_button button_default' :
                    props.button === 'main_border' ? 'main_button main_border button_default' :
                        props.button === 'accent' ? 'button_accent button_default' :
                            props.button === 'accent_big' ? 'button_accent button_default big_button' :
                                props.button === 'warning' ? 'warning_button button_default' :
                                    props.button === 'danger' ? 'danger_button button_default' :
                                        props.button === 'cancel' ? 'cancel_button button_default' :
                                            props.button === 'download' ? 'download_button button_default' :
                                                props.button === 'delete' ? 'delete_button button_default' :
                                                    props.button === 'add' ? 'add_button button_default' :
                                                        'main_button button_default'}
                disabled={disabled}>
                {/*{props.loader ? <i style={{marginRight: '8px', color: 'white'}} className="fa fa-refresh fa-spin"/> :*/}
                {/*    props.img ? <img style={{marginRight: '4px'}} src={props.img} alt=""/> :*/}
                {/*        props.button === 'download' ?*/}
                {/*            disabled ? <img src={uploadIconDisabled} alt=""/> : <img src={uploadIcon} alt="icon"/> :*/}
                {/*            props.button === 'delete' ?*/}
                {/*                disabled ? <img src={uploadIconDisabled} style={props.imgStyle ? props.imgStyle : null}*/}
                {/*                                alt="icon"/> :*/}
                {/*                    <img src={uploadIconBlue} style={props.imgStyle ? props.imgStyle : null}*/}
                {/*                         alt="icon"/> :*/}
                {/*                props.button === 'add' ? <img src={uploadIconLighter} alt="icon"/> :*/}
                {/*                    null}*/}
                {props.loader ? 'Payment processing' : props.text ? props.text : ''}
            </button>
        </div>
    );
}

export default MainButton;

import React from 'react'
import './styles/change_confirmed_popup.css'
import changing_success from '../assets/user_confirm_data.svg';

const ChangedConfirmedPopup = ({changingConfirmed, successText}) => {
    return changingConfirmed ? (
        <div className={'changed_confirmed_popup'}>
            <div className={'changing_confirmed'}>
                <img src={changing_success} alt="changing_success"/>
                <span>{successText}</span>
            </div>
        </div>
    ) : <div className={'changed_confirmed_popup_un_active'}>
        <div className={'changing_confirmed'}>
            <img src={changing_success} alt="changing_success"/>
            <span>{successText}</span>
        </div>
    </div>
}
export default ChangedConfirmedPopup
import React, {useEffect, useState} from 'react';
import "./styles/conditions.css";
import {Helmet} from "react-helmet";
import logo from '../assets/Interiors.png'
export default function DeliveryPage() {
    const [url, setUrl] = useState(null)

    useEffect(() => {
        setUrl(window.location.href)
    }, [])

    return (
        <div className={'main_page_conditions'}>
            <Helmet>
                <meta property="og:title" content={'Delivery — Vinteriors store.'}/>
                <title>Delivery — Vinteriors store.</title>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={logo}/>
                <meta property="description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="og:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:title" content={'Delivery — Vinteriors store.'}/>
                <meta property="twitter:description" content={'What can be better than a comfy sofa to lighten up your evening after a hard day’s work? And if you choose just the right one for you, it might get even better. Lie back and enjoy the perfection with your loved one or bask in the setting sun’s shadows all alone.'}/>
                <meta property="twitter:image" content={logo}/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>

            <div className={'playfair_display_400'}>
                <h2 className={'title'}>Shipping Policy</h2>
                <p className={'content'}>{''}</p>
                <p className={'content'}>{'Our preferred partners for shipments are GLS and Dachser.'}</p>
                <p className={'content'}>{'GLS for package deliveries and Dacher for furniture and larger items.'}</p>
                <p className={'content'}>{'The freight prices you see are indicative and not for specific products. The freight rates can vary according to the number of products and the volume of these in your basket. For the specific price for your order, please go to Leave a request on the website in the "Calculate price" field under the product, or send us an email to request@vinteriors.store.'}</p>
                <p className={'content'}>{'We process your order as quickly as possible. Orders on stocked items are fulfilled at our warehouse in Italy within 1-3 business days. Made-to-order will ship according to the estimated delivery date stated in the letter you receive after leaving a request to calculate the price of the item and delivery.'}</p>
                <p className={'content'}>{'We accept only bank transfer payments.'}</p>
                <p className={'content'}>{'We do not deliver to PO boxes.'}</p>
                <p className={'content'}>{'In case the delivery appears damaged, we ask you to receive with reservation. If the contents are damaged, please contact customer service at request@vinteriors.store'}</p>
                <p className={'content_other'}>{'Delivery of furniture and larger products'}</p>
                <p className={'content'}>{'When ordering furniture or larger products, they will be shipped with Dachser to the curbside of your delivery address. All customers will be contacted regarding the date of delivery. Please make sure to be home at the address upon delivery. If you happen not to be home at the agreed time of delivery, your item might be returned to the warehouse. In that case, please contact us for further assistance.'}</p>
            </div>
        </div>
    )
}

import {
    ADD_TO_RECENTLY_VIEWED,
    ADD_TO_CART,
    SUBTRACT_QUANTITY,
    ADD_QUANTITY,
    DELETE_CART_ITEM,
    DELETE_CART,
    ADD_TO_FAVORITES, DELETE_FROM_FAVORITES
} from "./actions";
import helper from "../../helper";

const InitialState = {
    cart: filteredCart(JSON.parse(localStorage.getItem(helper.USER_CART)) ?? []),
    favorite: filteredCart(JSON.parse(localStorage.getItem(helper.USER_FAVORITE)) ?? []),
    recentlyViewed: filteredCart(JSON.parse(localStorage.getItem(helper.USER_RECENTLY)) ?? []),
};

function filterRecentlyViewed(recentlyViewed) {
    let newRecentlyViewed = [];
    recentlyViewed.forEach(item => {
        if (newRecentlyViewed.filter(newItem => (
            newItem.product_id === item.product_id
        )).length === 0) {
            newRecentlyViewed.push(item);
        }
    });
    if (newRecentlyViewed.length >= 20) {
        newRecentlyViewed.splice(newRecentlyViewed.length - 1, 1)
    }
    return newRecentlyViewed
}

function filteredFavorites(favorite) {
    let newFavorites = [];
    favorite.forEach(item => {
        if (newFavorites.filter(newItem => (
            newItem.product_id === item.product_id
        )).length === 0) {
            newFavorites.push(item);
        } else {
            newFavorites = newFavorites.filter(newItem => {
                if (newItem.product_id !== item.product_id) {
                    return newItem
                }
                return null
            });
        }

    });
    return newFavorites
}

function filteredCart(cart) {
    let newCart = [];
    cart.forEach(item => {
        if (newCart.filter(newItem => (
            newItem.product_id === item.product_id
        )).length === 0) {
            newCart.push(item);
        } else {
            newCart = newCart.filter(newItem => {
                if (newItem.product_id === item.product_id) {
                    if (item.quantity > 1) {
                        console.log(item, newItem)
                        if (item.brand?.brand_id || item.description || item.photos){
                            newItem['quantity'] += item.quantity;
                        }else {
                            newItem['quantity'] += 1;
                        }
                    } else {
                        newItem['quantity'] = newItem['quantity'] + 1;
                    }
                    return newItem
                } else {
                    return newItem
                }
            });
        }
    });
    // console.table(newCart)
    return newCart
}

export const cart_favorite = (state = InitialState, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            let mutatedItemAction = action.payload;
            if (mutatedItemAction.photos?.length) {
                mutatedItemAction.photo = mutatedItemAction.photos[0];
            }
            if (state.cart?.length) {
                let cart = filteredCart([...state.cart, mutatedItemAction]);
                localStorage.setItem(helper.USER_CART, JSON.stringify(cart));
                return {...state, cart: cart}
            } else {
                let cartToArray = [mutatedItemAction];
                localStorage.setItem(helper.USER_CART, JSON.stringify(cartToArray));
                return {...state, cart: [mutatedItemAction]};
            }
        case ADD_TO_FAVORITES:
            let mutatedItem = action.payload;
            if (mutatedItem.photos?.length) {
                mutatedItem.photo = mutatedItem.photos[0];
            }
            if (state.favorite?.length) {
                let favorite = filteredFavorites([...state.favorite, mutatedItem]);
                localStorage.setItem(helper.USER_FAVORITE, JSON.stringify(favorite));
                return {...state, favorite: favorite}
            } else {
                let cartToArray = [mutatedItem];
                localStorage.setItem(helper.USER_FAVORITE, JSON.stringify(cartToArray));
                return {...state, favorite: [mutatedItem]};
            }
        case ADD_QUANTITY:
            let newCart = state.cart;
            newCart.forEach((item) => {
                if (item.product_id === action.payload) {
                    item.quantity++
                }
            });
            localStorage.setItem(helper.USER_CART, JSON.stringify(newCart));
            return {...state, cart: [...newCart]};
        case ADD_TO_RECENTLY_VIEWED:
            let mutatedRecentlyAction = action.payload;
            if (mutatedRecentlyAction.photos?.length) {
                mutatedRecentlyAction.photo = mutatedRecentlyAction.photos[0];
            }
            if (mutatedRecentlyAction.brand?.name) {
                mutatedRecentlyAction.brand = mutatedRecentlyAction.brand.name;
            }
            if (state.recentlyViewed?.length) {
                let recently = filterRecentlyViewed([...state.recentlyViewed, mutatedRecentlyAction]);
                localStorage.setItem(helper.USER_RECENTLY, JSON.stringify(recently));
                return {...state, recentlyViewed: recently}
            } else {
                let recentlyToArray = [mutatedRecentlyAction];
                localStorage.setItem(helper.USER_RECENTLY, JSON.stringify(recentlyToArray));
                return {...state, recentlyViewed: recentlyToArray};
            }
        case SUBTRACT_QUANTITY:
            let cart = state.cart;
            cart.forEach((item) => {
                if (item.product_id === action.payload && item.quantity > 1) {
                    item.quantity--
                }
            });
            localStorage.setItem(helper.USER_CART, JSON.stringify(cart));
            return {...state, cart: [...cart]};
        case DELETE_CART_ITEM:
            let protoCart = state.cart.filter((item) => {
                return item.product_id !== action.payload
            });
            localStorage.setItem(helper.USER_CART, JSON.stringify(protoCart));
            return {...state, cart: [...protoCart]};
        case DELETE_CART:
            return {...state, cart: []};
        case DELETE_FROM_FAVORITES:
            localStorage.removeItem(helper.USER_FAVORITE);
            return {...state, favorite: []};
        default: {
            return state
        }
    }
};

import React, {useEffect, useRef, useState} from 'react'
import LeaveARequest from "../Components/LeaveARequest";
import PreviewGoodsSlider from "../Components/PreviewGoodsSlider";
import "./styles/cart.css";
import {connect} from "react-redux";
import {addQuantity, subtractQuantity, deleteItemCart} from "../main-store/cart_and_favorite/actions";
import OrderPreview from '../Components/OrderPreview'
import vector_close from '../assets/vector_close.svg'
import close_gray from '../assets/close_gray.svg'
import polygon_gold from '../assets/polygon_gold.svg'
import PageTitle from "../Components/PageTitle";
import API from "../API";
import helper from "../helper";
import {Link} from "react-router-dom";
import vector_select from "../assets/vector.svg";
import useOutsideClick from "../Constants/useOutsideClick";
import {ScrollToTop} from "../Constants/ScrollToTop";

const UserCart = (props) => {
        const [activeOptions, changeOptions] = useState(null);
        const [cart, setCart] = useState(null);
        const [showCategoryParams, setShowCategoryParams] = useState(null);
        const ref = useRef();
        const settings = {
            speed: 400,
            slidesToShow: 4,
            infinite: false,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                }, {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        centerMode: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },

            ]
        };

        useOutsideClick(ref, () => {
            if (showCategoryParams) {
                setShowCategoryParams(null)
            }
        })

        useEffect(() => {
            ScrollToTop()
            let products = JSON.parse(localStorage.getItem(helper.USER_CART));
            if (products?.length) {
                let newProducts = [...products.map(i => ({
                    id: i.product_id,
                    quantity: i.quantity,
                    filters: i.activeFilters ?? [],
                    properties: i.activeProperties ?? []
                }))]
                API.post(`/user/basket`, {'products': newProducts})
                    .then(res => {
                        let newArr = res.data.products;
                        newArr.forEach(elem => {
                            elem.filters && elem.filters.forEach(i => {
                                i.params[0].selected = true
                            })
                            elem.properties && elem.properties.forEach(i => {
                                i.params[0].selected = true
                            })
                        })
                        setCart([...newArr])
                    })
            }
        }, [])

        let itemQuantityWrapper = item => {
            return (
                <div className={'item_quantity'}>
                    <button disabled={item.quantity <= 1 ? 'disabled' : ''}
                            className={'subtract_quantity align_block cart_quantity_buttons'}
                            onClick={() => {
                                props.subtractQuantity(item.product_id)
                                let index = cart.findIndex(a => a.product_id === item.product_id)
                                item.quantity -= 1
                                setCart(prevState => ([
                                    ...prevState.slice(0, index),
                                    item,
                                    ...prevState.slice(index + 1),
                                ]))
                            }}>
                        <span className={'roboto_400'}>-</span>
                    </button>
                    <span className={'roboto_400'}>{item.quantity}</span>
                    <button className={'add_quantity align_block cart_quantity_buttons'}
                            onClick={() => {
                                props.addQuantity(item.product_id)
                                let index = cart.findIndex(a => a.product_id === item.product_id)
                                item.quantity += 1
                                setCart(prevState => ([
                                    ...prevState.slice(0, index),
                                    item,
                                    ...prevState.slice(index + 1),
                                ]))
                            }}>
                        <span className={'roboto_400'}>+</span>
                    </button>
                </div>
            )
        };

        let cartItem = () => {
            return cart?.length ? cart.map((item, index) => {
                return (
                    <div className={'wrapper_main_info_product'} key={item.product_id}>
                        <div className={'titles_preview wrapper_every_items'}>
                            <Link to={`/goods/${item.product_id}`} className={'wrapper_preview_item'}>
                                <div className={'wrapper_item_image'}>
                                    <img src={process.env.REACT_APP_IMAGE_API_URL + '/' + item.photo} alt={item.name}/>
                                </div>
                                <div className={'wrapper_info align_block'}>
                                    <div className={'wrapper_item_desc open_sans_400'}>
                                        {item.brand instanceof String
                                            ? item.brand?.length > 20
                                                ? item.brand?.substring(0, 20) + '...'
                                                : item.brand
                                            : item.brand?.name?.length > 20
                                                ? item.brand?.name.substring(0, 20) + '...'
                                                : item.brand?.name}
                                    </div>
                                    <div className={'wrapper_item_title playfair_display_400'}>
                                        {item.name.length > 20 ? item.name.substring(0, 14) + '...' : item.name}
                                    </div>
                                    <div className={'wrapper_item_quantity'}>
                                        {itemQuantityWrapper(item)}
                                    </div>
                                    <div className={'wrapper_item_price align_block'}>
                                <span
                                    className={'open_sans_400'}>€{item.discount_price ? item.discount_price : item.price}</span>
                                        {item.discount_price ?
                                            <span className={'open_sans_400 discount_price'}>
                                            €{item.price}
                                        </span>
                                            : null}
                                    </div>
                                </div>
                            </Link>
                            <div className={'wrapper_item_quantity align_block'}>
                                {itemQuantityWrapper(item)}
                            </div>
                            <div className={'wrapper_item_price align_block'}>
                        <span
                            className={'open_sans_400'}>€{item.discount_price ? item.discount_price : item.price}</span>
                                {item.discount_price ?
                                    <span className={'open_sans_400 discount_price'}>
                                €{item.price}
                            </span>
                                    : null}
                            </div>
                            <div className={'wrapper_delete_item_btn align_block'}
                                 onClick={() => {
                                     props.deleteItemCart(item.product_id)
                                     let filterArray = cart.filter(a => a.product_id !== item.product_id)
                                     setCart(filterArray)
                                 }}>
                                <span className={'open_sans_400'}>
                                    Delete
                                </span>
                            </div>
                            <div className={'wrapper_item_options align_block'}
                                 onClick={() => changeOptions(activeOptions !== item.product_id ? item.product_id : null)}>
                                <div className={'item_options'}>
                                    <span className={'open_sans_600'}>Options</span>
                                    <img src={activeOptions === item.product_id ? vector_close : polygon_gold}
                                         alt="vector_close"/>
                                </div>
                            </div>
                            <div className={'wrapper_delete_item_btn_mobili'}>
                                <img src={close_gray}
                                     onClick={() => props.deleteItemCart(item.product_id)}
                                     alt="close_gray"/>
                            </div>
                        </div>
                        <div className={activeOptions === item.product_id ?
                            'active_options_wrapper options_wrapper' :
                            'un_active_options_wrapper options_wrapper'}>
                            {setFilters('filters', item, index)}
                            {setFilters('properties', item, index)}
                        </div>
                    </div>
                )
            }) : null
        };

        let setFilters = (prop, item, index) => {
            return (
                <div className={'wrapper_every_filters'}>
                    {item[prop]?.map(items => {
                        return (
                            <div className={'wrapper_select_input'} key={items.name}>
                                <div className={'wrapper_category_name'}>
                                    <span>{items.name}</span>
                                </div>
                                <div className={'wrapper_preview_category'}
                                     onClick={() => {
                                         if (showCategoryParams === items.name) {
                                             setShowCategoryParams(null)
                                         } else {
                                             setShowCategoryParams(items.name)
                                         }
                                     }}>
                                    {items.params.map(i => {
                                        return i.selected && i.icon ?
                                            <img src={process.env.REACT_APP_IMAGE_API_URL + "/" + i.icon}
                                                 key={i.param}
                                                 alt={items.param}/>
                                            : null
                                    })}
                                    <span>{items.params.map(i => i.selected ? i.name ? i.name : i.param : null)}</span>
                                    <img src={vector_select} alt="vector"/>
                                </div>
                                {showCategoryParams === items.name ?
                                    <div className={'wrapper_category_params overflow_scroll'}
                                         ref={ref}>
                                        {items.params.map(param => {
                                            return (
                                                <div
                                                    className={param.selected ? 'wrapper_every_params active' : 'wrapper_every_params'}
                                                    key={param.id}
                                                    onClick={() => {
                                                        handlePropertiesValue(prop, index, items.id, param.id)
                                                        setShowCategoryParams(null)
                                                    }}>
                                                    {param.icon ?
                                                        <img src={process.env.REACT_APP_IMAGE_API_URL + "/" + param.icon}
                                                             alt={param.param ?? param.name}/>
                                                        : null}
                                                    <span>{param.name ?? param.param}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    : null}
                            </div>
                        )
                    })}
                </div>
            )
        }

        const handlePropertiesValue = (prop, index, id, params_id) => {
            let newArr = cart;
            newArr[index][prop].forEach(item => {
                if (item.id === id) {
                    item.params.forEach(items => {
                        items.selected = items.id === params_id;
                    })
                }
            })
            setCart([...newArr])
        }

        return (
            <div className={'wrapper_main_page'}>
                <PageTitle title={'Basket'}/>
                <div className={'wrapper_cart_items'}>
                    <div className={'titles_preview'}>
                        <span className={'title open_sans_400 hidden_item'}>Product</span>
                        <span className={'title open_sans_400'}>Amount</span>
                        <span className={'title open_sans_400'}>Price</span>
                        <span className={'title open_sans_400 delete_item'}>Delete</span>
                        <span className={'title open_sans_400'}>Options</span>
                    </div>
                    {cartItem()}
                </div>
                {cart?.length && (
                    <OrderPreview goods={cart}/>
                )}
                {JSON.parse(localStorage.getItem(helper.USER_RECENTLY))?.length ?
                    <div className={'wrapper_preview_category_section'}
                         style={{gridTemplateColumns: '1fr'}}>
                        <PreviewGoodsSlider settings={settings}
                                            title={'Recently viewed'}
                                            goods={JSON.parse(localStorage.getItem(helper.USER_RECENTLY))}/>
                    </div>
                    : null}
                <LeaveARequest/>
            </div>
        );
    }
;

const mapPropsToState = () => {
    return {}
};

const putStateToProps = {
    addQuantity,
    subtractQuantity,
    deleteItemCart
};

export default connect(mapPropsToState, putStateToProps)(UserCart)

import React, {useState, useRef, useEffect} from 'react';
import {Link, Route} from "react-router-dom";
import Layout from "../adminComponents/Layout"
import API from "../adminAPI";

import "../adminComponents/style/categories.scss"


import EditIcon from "../assets/g-icon-edit.svg"
import DeleteIcon from "../assets/g-icon-delete.svg"
import AddIcon from "../assets/g-icon-add.svg"
import AddIconBlack from "../assets/g-icon-add-black.svg"
import IconArrowDown from "../assets/g-icon-arrow_down.svg"
import CloseIcon from '../assets/g-modal-close.svg'

const CategoriesPage = ({match}) => {
    const [paramsArr, setParamsArr] = useState([{price: "", img: "", name: "", id: Math.random()}]);

    const [requestList, setRequestList] = useState(false);
    const [categoriesData, setCategoriesData] = useState([]);

    const [addMode, setAddMode] = useState(true);
    const [editMode, setEditMode] = useState(false);

    const [filtersData, setFiltersData] = useState([]);

    const [filterChangeId, setFilterChangeId] = useState(null)

    const InitialItem = {
        id: null,
        name: "",
        path: ""
    };

    const [subcategoryItem, setSubcategoryItem] = useState({id: null,
        name: "",
        path: ""});
    const [categoryId, setCategoryId] = useState(null);

    const ref = useRef();

    const handleRequestListHeader = id => {
        setRequestList(false);
        setCategoryId(id);
    };

    const handleRequestList = () => {
        setRequestList(!requestList);
    };

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setRequestList(false);
        }
    };

    useEffect(() => {
        if (categoriesData.length === 0 ) {
            API.get(`/admin/categories`)
                .then(res => {
                    setCategoriesData(res.data.categories);
                    setCategoryId(match.params.id);
                    API.get(`/admin/filter?category_id=${match.params.id}`)
                        .then(res => {
                            setFiltersData(res.data.filters)
                        })
                });
        }   else    {
            API.get(`/admin/filter?category_id=${match.params.id}`)
                .then(res => {
                    setFiltersData(res.data.filters)
                })
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [categoryId, categoriesData.length, match.params.id]);

    const HandleTitle = ({match, data}) => {
        const {params: {categoryId}} = match;
        const category = data.find(({id}) => id === +categoryId);

        if (category) {
            return (
                <h2 className="categories__title g-title">{category.name}</h2>
            );
        } else {
            return (
                <h2 className="categories__title g-title"> </h2>
            );
        }
    };

    const UserPage = ({match, data}) => {
        const {
            params: {categoryId}
        } = match;

        const category = data.find(({id}) => id === +categoryId);

        if (category) {
            return (
                <ul className="categories-info-list">
                    {data.map(subcategory => {
                        return (
                            <li className="categories-info-list__item_new" key={subcategory.id}>
                                <p className="categories-info-list__text">{subcategory.name}</p>

                                <button className="g-button-edit" onClick={() => handleEdit(subcategory.id)}>
                                    <img className="g-button-edit__icon" src={EditIcon} alt="Edit"/>
                                </button>
                                <button className="g-button-delete" onClick={() => handleDelete(subcategory.id)}>
                                    <img className="g-button-delete__icon" src={DeleteIcon} alt="Delete"/>
                                </button>
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            return (
                <ul className="categories-info-list"></ul>
            )
        }
    };

    const handleInputChange = e => {
        const {name, value} = e.target;
        setSubcategoryItem({...subcategoryItem, [name]: value});
    };


    const handleEdit = id => {
        API.get(`/admin/filter`, {params: {filter_id: id}})
            .then(res => {
                setFilterChangeId(id)
                setSubcategoryItem({
                    id: res.data.id,
                    name: res.data.name,
                })

                setParamsArr(res.data.params)

                setAddMode(false);
                setEditMode(true);
            })
    };

    const handleDelete = id => {
        API.delete(`/admin/filter?filter_id=${id}`)
            .then(res => {
                API.get(`/admin/filter?category_id=${match.params.id}`)
                    .then(res => {
                        setFiltersData(res.data.filters)
                    })

                if(editMode) {
                    setEditMode(false);
                }
            })
    }

    const handleCancel = () => {
        setAddMode(true);
        setEditMode(false);
        setSubcategoryItem(InitialItem);
    };

    const submitAdd = e => {
        e.preventDefault();
        let params = []
        paramsArr.forEach(item => {
            params.push(item.name)
        })
        API.post(`/admin/filter`, {'name': subcategoryItem.name, 'params': params, 'category_id': subcategoryItem.id})
            .then(res=>{
                setParamsArr([{price: "", img: "", name: "", id: Math.random()}])
                setSubcategoryItem({id: null,
                    name: "",
                    path: ""})
                API.get(`/admin/filter?category_id=${categoryId}`)
                    .then(res => {
                        setFiltersData(res.data.filters)
                    })
            })
        };

    const submitEdit = e => {
        e.preventDefault();
        let params = []
        paramsArr.forEach(item => {
            params.push({"name": item.name , "id": item.id >= 1 ? item.id : null})
        })
        API.put(`/admin/filter`, {'name': subcategoryItem.name, 'params': params, 'filter_id': filterChangeId})
            .then(res => {
                setParamsArr([{price: "", img: "", name: "", id: Math.random()}])
                setSubcategoryItem({id: null, name: "", path: ""})

                API.get(`/admin/filter?category_id=${categoryId}`)
                    .then(res => {
                        setFiltersData(res.data.filters)
                    })

                setEditMode(false);
                setAddMode(true);
            })
    };

    const changeFilterInputs = (e, key, value) => {
        paramsArr[key][value] = e.target.value;
        setParamsArr([...paramsArr])
    };

    return (
        <Layout>
            <section className="categories">
                <div className="container">
                    <div className="categories-info">

                        <div ref={ref} className={`g-select${requestList ? ` g-select__open` : ''}`}>
                            <div className="g-select-header" onClick={handleRequestList}>

                                <Route path="/admin/variations/:categoryId"
                                       render={props => <HandleTitle data={categoriesData} {...props} />}/>

                                <img className="g-select-header__arrow" src={IconArrowDown} alt="Категория"/>
                            </div>

                            {requestList ? <ul className="g-select-list">
                                {categoriesData.map((category) => {
                                    return (
                                        <li className="g-select-list__item" key={category.id}>
                                            <Link className="g-select-list__link"
                                                  to={`/admin/variations/${category.id}`} onClick={() => {
                                                handleRequestListHeader(category.id);
                                                handleCancel()
                                            }}>{category.name}</Link>
                                        </li>
                                    );
                                })}
                            </ul> : null}
                        </div>

                        <div className="categories-info__head">
                            <p className="categories-info__text">Свойство</p>
                        </div>

                        <Route path="/admin/variations/:categoryId"
                               render={props => <UserPage data={filtersData} {...props} />}/>
                    </div>

                    <div className="categories-add">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <h3 className="categories-add__title">{editMode ? `Редактировать` : `Добавить`} фильтр</h3>
                            <button onClick={() => {
                                setParamsArr([...paramsArr, {
                                        name: "", price: "", id: Math.random()
                                    }]
                                )
                            }} className="g-add" style={{
                                height: "max-content",
                                marginLeft: "auto",
                                backgroundColor: "white",
                                border: '1px black solid',
                                color: "black",
                                borderRadius: "5px"
                            }}>
                                <img className="g-add__icon" src={AddIconBlack} alt="Додати"/>
                                Добавить параметр
                            </button>
                        </div>
                        <form className="categories-add__form"
                              onSubmit={addMode ? submitAdd : editMode ? submitEdit : null}>

                            <div className="categories-add__block">
                                <p className="categories-add__text">Название</p>

                                <input className="g-input" type="text" placeholder="Название" name="name"
                                       value={subcategoryItem.name} onChange={handleInputChange}/>

                                {paramsArr.length  ?
                                    <>
                                        <table className="orders-table">
                                            <thead className="orders-table__head">
                                            <tr className="orders-table__tr">
                                                <th style={{width: "40%", textAlign: "start", paddingLeft: "0 !important"}} className="orders-table__thv">Параметры</th>
                                                {/*<th style={{width: "20%", textAlign: "start"}} className="orders-table__th">Иконка</th>*/}
                                                {/*<th style={{width: "40%", textAlign: "start"}} className="orders-table__th">Добавленная стоимость, €</th>*/}
                                            </tr>
                                            </thead>
                                            <tbody className="orders-table__body">

                                            {paramsArr.map((i, key) => {
                                                return (
                                                    <tr className="orders-table__tr" key={key}>
                                                        <td style={{paddingLeft: "0 !important", paddingBottom: "0 "}} className="orders-table__tdv">
                                                            <input className={"g-input"} type="text" onChange={(e)=> {changeFilterInputs(e, key, "name")}} placeholder={"Параметр"} value={paramsArr[key].name}/>
                                                        </td>
                                                        {/*<td className="orders-table__td">*/}
                                                        {/*    <div style={{display:"flex"}} className="categories-add__block">*/}
                                                        {/*        <label style={{width: "45px", height: "45px", marginBottom: "16px"}} className="categories-add__label">*/}
                                                        {/*            {i.img ? <img style={{cursor: "default"}} className="categories-add__image" src={i.img} alt="Upload"/> : null}*/}
                                                        {/*            {i.img ? null :*/}
                                                        {/*                < input className="categories-add__file-new" type="file" onChange={e => setImage(e, key)} onClick={e => e.currentTarget.value = null}/>*/}
                                                        {/*            }*/}
                                                        {/*            <img style={{width: "20px"}} src={AddIconLarge} alt=""/>*/}

                                                        {/*        </label>*/}
                                                        {/*        <div style={{marginLeft: "8px", marginBottom: "14px", position: "relative"}}>*/}
                                                        {/*        <img style={{height: "100%", width: "100%"}} src={EditIconRed} alt=""/>*/}
                                                        {/*            {i.img ?*/}
                                                        {/*            < input className="categories-add__file-new" type="file" onChange={e => setImage(e, key)} onClick={e => e.currentTarget.value = null}/>*/}
                                                        {/*            :null}*/}
                                                        {/*        </div>*/}
                                                        {/*        <div style={{marginLeft: "8px", marginBottom: "14px", position: "relative"}}>*/}
                                                        {/*        <img style={{height: "100%", width: "100%"}} src={DeleteIconRed} onClick={() => {*/}
                                                        {/*            paramsArr[key].img = "";*/}
                                                        {/*            setParamsArr([...paramsArr])*/}
                                                        {/*        }} alt=""/>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</td>*/}
                                                        {/*<td className="orders-table__td">*/}
                                                        {/*    <input className={"g-input"} type="text" onChange={(e)=> {changeFilterInputs(e, key, "price")}} placeholder={"0"}  value={paramsArr[key].price}/>*/}
                                                        {/*</td>*/}
                                                            <div onClick={editMode ? ()=> {
                                                                API.delete(`admin/filter?param_id=${i.id}`)
                                                                    .then(res => {
                                                                        paramsArr.forEach((element, index) => {
                                                                            if (element.id === i.id) {
                                                                                paramsArr.splice(index, 1);

                                                                            }

                                                                        });
                                                                        setParamsArr([...paramsArr]);
                                                                    })
                                                                }
                                                            :
                                                                () => {
                                                                paramsArr.forEach((element, index) => {
                                                                    if (element.id === i.id) {
                                                                        paramsArr.splice(index, 1);

                                                                    }

                                                                });
                                                                setParamsArr([...paramsArr]);

                                                            }}
                                                                 style={{backgroundColor: "rgba(51, 51, 58, 0.2)", display: "grid", marginTop: "14px", width: "fit-content"}}>
                                                                <img src={CloseIcon} style={{padding: "12px", display: "grid"}} alt=""/>
                                                            </div>
                                                    </tr>
                                                )
                                            })
                                            }
                                            </tbody>
                                        </table>
                                    </>
                                    : null
                                }
                                <div className="categories-add_flex">
                                    {editMode ?
                                        <button className="g-add g-add_margin" type="button" onClick={handleCancel}>
                                            Отменить
                                        </button> : null}

                                    <button className="g-add" type="submit">
                                        <img className="g-add__icon" src={AddIcon} alt="Додати"/>
                                        Сохранить
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default CategoriesPage
